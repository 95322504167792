import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ChargeCatErrorStateModel } from './error-state.model';
import * as Actions from '../app/app.actions';

@State<ChargeCatErrorStateModel>({
  name: 'ChargeCatErrorState',
  defaults: ChargeCatErrorStateModel.getDefaults()
})
@Injectable()
export class ChargeCatErrorState {
  constructor() {}

  @Action(Actions.SetCategoryDeltaError)
  setCategoryDeltaError({ setState }: StateContext<ChargeCatErrorStateModel>, error: string) {
    setState(store => ({
      ...store,
      categoryDeltaError: error
    }));
  }
}
