import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { CategoryDeltaRequest, CategoryNameWithRuleTitlesList, CdmPageFilter, Tenant } from '../shared/models';
import { Observable } from 'rxjs';
import { UserProfileReduced } from 'src/app/_shared/models/user-profile-reduced';
import { Category, ChargeDescription } from 'src/app/_shared/models';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {
  api = `${environment.config.dataApiUrl}api/${environment.config.dataApiVersion}`;
  constructor(private http: HttpClient) {}

  getUserByUserId(userId: string): Observable<UserProfileReduced> {
    let euid = encodeURIComponent(userId);
    return this.http.get<UserProfileReduced>(`${this.api}/users/${euid}`);
  }

  getCategoryNameWithRuleTitlesList(): Observable<CategoryNameWithRuleTitlesList[]> {
    return this.http.get<CategoryNameWithRuleTitlesList[]>(`${this.api}/rules/categoryRulesV2`);
  }

  fetchUserInfo(): Observable<any> {
    return this.http.get<any>(`${this.api}/users/me`);
  }

  fetchHcpcsStandard(): Observable<any> {
    return this.http.get<any>(`${this.api}/charges/hcpcsStandard`);
  }

  fetchAllTenants(): Observable<Tenant[]> {
    return this.http.get<Tenant[]>(`${this.api}/tenant/allTenants`);
  }

  fetchRevCodes(): Observable<any> {
    return this.http.get<any>(`${this.api}/rules/revCodes`);
  }

  fetchCategories(): Promise<Category[]> {
    return this.http.get<any>(`${this.api}/charges/category`).toPromise();
  }

  getChargeCategoryById(id: string): Promise<Category> {
    return this.http.get<any>(`${this.api}/charges/category/${id}`).toPromise();
  }

  fetchCategoryVersions(id: string): Observable<any> {
    return this.http.get<any>(`${this.api}/charges/category/${id}/versions`);
  }

  fetchActiveRunningTenants(): Observable<String[]> {
    return this.http.get<String[]>(`${this.api}/charges/activeRunningTenants`);
  }

  fetchChargeDescriptionsByIds(docIds: String[]): Observable<any> {
    return this.http.post<any>(`${this.api}/charges/descriptions-by-ids`, docIds);
  }

  fetchChargeCategoryDelta(request: CategoryDeltaRequest): Promise<any> {
    return this.http.post<any>(`${this.api}/charges/category-delta`, request).toPromise();
  }

  getElasticTaskStatus(ids: string[]): Observable<any> {
    return this.http.get<any>(`${this.api}/elastic-tasks/${ids.join(',')}`);
  }

  cancelElasticTask(id: string): Observable<any> {
    return this.http.post<any>(`${this.api}/elastic-tasks/${id}/cancel`, null);
  }

  fetchCdmDataPaged(request: CdmPageFilter): Observable<any> {
    return this.http.post<any>(`${this.api}/charges/cdm-table`, request);
  }

  fetchCdmDataPagedExport(request: CdmPageFilter): Observable<any> {
    return this.http.post<any>(`${this.api}/charges/cdm-table/export`, request);
  }

  fetchCdmUniqueColumnData(request: CdmPageFilter, columnField: string, cbFilterField: string): Observable<any> {
    const params = new HttpParams().append('columnField', columnField).append('cbFilterField', cbFilterField);
    return this.http.post<any>(`${this.api}/charges/cdm-table/filter-values`, request, { params: params });
  }
}
