import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DeleteCategoryModalComponent } from '../delete-category-modal/delete-category-modal.component';

@Component({
  selector: 'cm-revert-confirmation-modal',
  templateUrl: './revert-confirmation-modal.component.html',
  styleUrls: ['./revert-confirmation-modal.component.scss']
})
export class RevertConfirmationModalComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteCategoryModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {}

  cancel() {
    this.dialogRef.close();
  }

  revert() {
    this.dialogRef.close(true);
  }
}
