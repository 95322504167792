<h1 mat-dialog-title>
  Preview N1QL changes to category: <span class="category-name">{{ selectedCategory.name }}</span>
</h1>

<div
  *ngIf="(activeRunningTenants | async)?.length > 0"
  class="text-break text-wrap alert alert-warning d-flex justify-content-between nochange-alert"
  role="alert"
>
  <span>
    Tenants with active N1QL refresh web jobs: <strong>{{ (activeRunningTenants | async).join(', ') }}</strong></span
  >
</div>

<div class="alert alert-danger" role="alert" *ngIf="(queryError$ | async)?.error">
  <div *ngIf="(queryError$ | async)?.error?.message !== ''">
    {{ (queryError$ | async).error }}
  </div>
</div>

<div mat-dialog-content [ngClass]="{ 'preview-modal': isLoadingCategoryDelta$ | async }">
  <div
    class="preview-spinner"
    *ngIf="
      ((isLoadingCategoryDelta$ | async) && !(queryError$ | async)?.error) || (isSavingCategoryDeltaPreview$ | async)
    "
  >
    <ngx-spinner
      [fullScreen]="false"
      size="large"
      color="#ddd"
      bdColor="rgba(240,240,240,0.3)"
      type="line-scale"
      class="spinner"
      ><div class="loading-message preview-spinner-message">Loading...</div>
    </ngx-spinner>
  </div>

  <!-- Descriptions To Add -->
  <cm-category-delta-grid
    *ngIf="gridData?.descriptionsToAdd?.length"
    [data]="gridData?.descriptionsToAdd"
    [dataCount]="gridData?.descriptionsToAddCount"
    [gridId]="'previewToAddGrid'"
    [toolbarTitle]="'Descriptions that will have this category added'"
    [selectedCategory]="selectedCategory"
  >
  </cm-category-delta-grid>

  <div
    *ngIf="(isLoadingCategoryDelta$ | async) == false && gridData?.noChangeCount >= 0"
    class="alert alert-primary d-flex justify-content-between nochange-alert mt-2 mb-2"
    role="alert"
  >
    <span>Descriptions that are already part of this category that will remain unchanged.</span>
    <span class="nochange-text">Total: {{ gridData ? (gridData?.noChangeCount | number) : 0 }}</span>
  </div>

  <!-- Descriptions To Remove -->
  <cm-category-delta-grid
    *ngIf="gridData?.descriptionsToRemove?.length"
    [data]="gridData?.descriptionsToRemove"
    [dataCount]="gridData?.descriptionsToRemoveCount"
    [gridId]="'previewToRemoveGrid'"
    [toolbarTitle]="'Descriptions that will be removed from this category'"
    [selectedCategory]="selectedCategory"
  >
  </cm-category-delta-grid>
  <div class="mt-2" *ngIf="(isLoadingCategoryDelta$ | async) == false">
    <h5>Version Notes</h5>
    <textarea [(ngModel)]="versionEditLog" class="version-notes form-control" pInputTextarea></textarea>
  </div>
</div>

<div mat-dialog-actions class="float-right">
  <div
    class="modal-footer-elapsed-time"
    *ngIf="(isLoadingCategoryDelta$ | async) || (isSavingCategoryDeltaPreview$ | async)"
  >
    {{ elapsedTime }}
  </div>
  <button class="btn btn-sm btn-secondary mr-2" (click)="cancel()" [disabled]="isSavingCategoryDeltaPreview$ | async">
    Cancel
  </button>
  <button
    class="btn btn-sm btn-primary"
    (click)="save()"
    [disabled]="(queryError$ | async)?.error || (isSavingCategoryDeltaPreview$ | async) || translateN1QLError"
  >
    Save
  </button>
</div>
