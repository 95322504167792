<div class="row">
  <section class="category-query-section col mt-2">
    <div class="category-query-header">
      <div class="row mt-n3">
        <div class="col">
          <div class="btn-toolbar float-left mt-n2 align-baseline">
            <button
              class="refresh-btn"
              pTooltip="Refresh Category"
              type="button"
              tooltipPosition="right"
              [disabled]="!selectedVersion?.isLatest"
              (click)="refreshCategory()"
            >
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </button>
            <label for="selectStatement" class="pt-2 mr-3 f18"><strong>N1QL Select Statement:</strong></label>
            <label
              class="mr-3 mt-2 selected-category-name f18"
              [ngClass]="{ 'help-cursor': selectedCategory?.description }"
              *ngIf="selectedCategory?.docId !== ''"
              (mouseenter)="showHoverDescription = true"
              (mouseleave)="showHoverDescription = false"
              >{{ selectedCategory?.name }}</label
            >
            <div *ngIf="showHoverDescription && selectedCategory?.description" class="description-popup">
              {{ selectedCategory?.description }}
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-n2">
        <div class="col">
          <cm-user-avatar [user]="modifiedByUser" [size]="'small'" [showUserInfo]="true" class="pr-2"></cm-user-avatar>
          <span class="f16 modified-date">{{ selectedCategory?.lastModified | date: 'MM/dd/yyyy @ h:mma' }}</span>
        </div>
        <div class="col">
          <div class="btn-toolbar float-right mb-2 mt-n3">
            <div>
              <label style="padding-right: 6px">Version</label>
              <p-dropdown
                appendTo="body"
                [options]="versions"
                [(ngModel)]="selectedVersion"
                (onChange)="setSelectedVersion()"
              >
                <ng-template let-item pTemplate="selectedItem">
                  <span style="vertical-align: middle">{{ item.label }}</span>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>
                    <div class="ver-dropdown-number">
                      <strong>{{ item.label }}</strong> -
                    </div>
                    <cm-user-avatar
                      [user]="versionsModifiedByDictionary[item.value?.category?.modifiedBy]"
                      [size]="'small'"
                      [showUserInfo]="true"
                      [showUserTitle]="false"
                      class="pr-2"
                    >
                    </cm-user-avatar>
                    <span *ngIf="!versionsModifiedByDictionary[item.value?.category?.modifiedBy]">No User Found </span>-
                    {{ item.value?.category?.lastModified | date: 'MM/dd/yyyy @ h:mma' }}
                    <div class="version-notes">
                      <span>{{ item.value?.category?.versionEditLog }}</span>
                    </div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>

            <button
              mat-icon-button
              (click)="formatN1QL()"
              pTooltip="Format N1QL"
              tooltipPosition="bottom"
              class="btn btn-light"
              [disabled]="!hasSelectedCategory()"
            >
              <i
                class="fa fa-lg fa-code"
                aria-hidden="true"
                [ngClass]="{ 'disabled-icon': !hasSelectedCategory() }"
              ></i>
            </button>

            <button
              (click)="toggleTopSlider()"
              pTooltip="Popout Editor"
              tooltipPosition="bottom"
              class="btn btn-light"
              [disabled]="!hasSelectedCategory()"
            >
              <i
                class="fa fa-lg fa-external-link pt-2"
                aria-hidden="true"
                [ngClass]="{ 'disabled-icon': !hasSelectedCategory() }"
              ></i>
            </button>

            <button
              (click)="sortN1QLCodes()"
              pTooltip="Sort and remove duplicate values from selection"
              tooltipPosition="bottom"
              class="btn btn-light"
              [disabled]="!hasSelectedCategory()"
            >
              <i
                class="fa fa-lg fa-sort-alpha-asc"
                aria-hidden="true"
                [ngClass]="{ 'disabled-icon': !hasSelectedCategory() }"
              ></i>
            </button>

            <button
              pTooltip="Revert"
              tooltipPosition="bottom"
              class="btn btn-light"
              (click)="openRevertConfirmationModal()"
              [disabled]="!hasSelectedCategory()"
            >
              <i
                class="fa fa-lg fa-undo"
                aria-hidden="true"
                [ngClass]="{ 'disabled-icon': !hasSelectedCategory() }"
              ></i>
            </button>
            <button
              pTooltip="Map CPT list (keyword = $CptList)"
              tooltipPosition="bottom"
              class="btn btn-light"
              (click)="openCptMappingModal()"
              [disabled]="!hasSelectedCategory()"
            >
              <i class="fa fa-lg fa-map" aria-hidden="true" [ngClass]="{ 'disabled-icon': !hasSelectedCategory() }"></i>
            </button>

            <button
              pButton
              pRipple
              type="button"
              icon="pi pi-search"
              class="p-button-rounded preview-btn"
              (click)="formatN1QL(); openPreviewModal()"
              pTooltip="Preview"
              tooltipPosition="bottom"
              [disabled]="!hasSelectedCategory()"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div class="clearfix"></div>
    <div
      [ngClass]="{
        'n1ql-editor-with-exceptions': hasIncludes() || hasExcludes()
      }"
    >
      <cm-n1ql-editor [selectedCategory]="selectedCategory"></cm-n1ql-editor>
    </div>
  </section>
</div>

<div class="row" *ngIf="hasIncludes() || hasExcludes()">
  <section class="manual-exceptions-section col mt-3">
    <cm-manual-exceptions
      [hasIncludes]="hasIncludes()"
      [hasExcludes]="hasExcludes()"
      (removeRow)="removeRow($event)"
    ></cm-manual-exceptions>
  </section>
</div>
