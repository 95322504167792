export const messages = {
  error: {
    addCategory: 'Error adding the category',
    deleteCategory: 'Error deleting the category',
    deleteManualExclude: 'Error deleting manual exclude',
    deleteManualInclude: 'Error deleting manual include',
    fetchCategories: 'Error fetching categories',
    fetchCdmData: 'Error fetching CDM data',
    fetchCdmDataCount: 'Error fetching CDM data count',
    fetchCdmPagedData: 'Error fetching CDM paged data',
    fetchManualExcludes: 'Error getting manual excludes',
    fetchManualIncludes: 'Error getting manual includes',
    fetchSelectedCategory: 'Error getting the selected category',
    saveN1QLStatement: 'Error saving the N1QL statement',
    updateCDMElastic: 'Error updating CDM Data',
    editCategory: 'Error editing the category',
    exportExcelFailure: 'Error exporting to Excel',
    fetchUserProfile: 'Error getting user profile',
    fetchUserByUserId:
      'Error getting user info for the Category Query N1QL component used for the (last modified by) field avatar',
    fetchTenantList: 'Error getting tenant list',
    fetchHcpcsList: 'Error getting HCPCS list',
    fetchCptList: 'Error getting CPT list',
    fetchRevenueCodeList: 'Error getting Revenue Code list',
    fetchUniqueColFilterList: 'Error getting column filter list',
    applyCdmTableCategoryUpdate: 'Error updating categories in the CDM Table',
    translateN1QLToElastic: 'Error translating N1QL for delta.',
    maxTermsExceeded: 'Error max filter term count exceeded.'
  },
  success: {
    addCategory: 'New category has been added',
    deleteCategory: 'Category has been deleted',
    saveN1QLStatement: 'Category N1QL statement successfully updated',
    editCategory: 'Category successfully updated',
    deleteManualException: 'Manual Exception deleted'
  },
  info: {
    exportOverLimit: 'Total count must be 100,000 records or less to export.'
  },
  warning: {
    pendingN1QLChanges:
      'Changes pending. Please [Revert] or [Apply] changes to the current category before selecting another one.',
    noCdmTableResults: 'There were no results for the values entered below.'
  }
};
