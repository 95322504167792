<p-overlayPanel styleClass="grid-menu-overlay" #manualIncludeMenu appendTo="body">
  <ng-template pTemplate>
    <ng-template [ngTemplateOutlet]="manualGridMenu"></ng-template>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel styleClass="grid-menu-overlay" #manualExcludeMenu appendTo="body">
  <ng-template pTemplate>
    <ng-template [ngTemplateOutlet]="manualGridMenu"></ng-template>
  </ng-template>
</p-overlayPanel>
<div
  [ngClass]="toolbarTitle === 'Included CDMs' ? 'alert-success' : 'alert-danger'"
  class="alert alert-success mb-0 header-border-radius"
  role="alert"
>
  <div class="d-flex justify-content-between">
    <div>{{ toolbarTitle }}</div>
    <i
      *ngIf="toolbarTitle === 'Included CDMs'"
      class="fa fa-cog fa-2x table-gear-icon"
      aria-hidden="true"
      (click)="manualIncludeMenu.show($event)"
    ></i>
    <i
      *ngIf="toolbarTitle !== 'Included CDMs'"
      class="fa fa-cog fa-2x table-gear-icon"
      aria-hidden="true"
      (click)="manualExcludeMenu.show($event)"
    ></i>
  </div>
</div>
<p-table
  #manualgrid
  [columns]="selectedColumns"
  [value]="data"
  [scrollable]="true"
  scrollDirection="both"
  scrollHeight="300px"
  [virtualScroll]="true"
  [virtualRowHeight]="30"
  styleClass="p-datatable-sm"
  [rows]="10000"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="row-col">Row</th>
      <th
        [pSortableColumn]="col.field"
        *ngFor="let col of columns"
        [ngStyle]="{ width: col.width }"
        class="d-flex justify-content-between"
      >
        {{ col.header }} <p-sortIcon field="{{ col.field }}"></p-sortIcon>
      </th>
      <th class="delete-col">Remove</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
    <tr class="charge-desc-list-row">
      <td class="row-col">{{ i + 1 }}</td>
      <td *ngFor="let col of columns" [ngStyle]="{ width: col.width }">
        <span *ngIf="col.field !== 'lastModified'" [ngClass]="'text-' + col.cellAlignment" class="w-100">{{
          rowData[col.field]
        }}</span>
        <span *ngIf="col.field === 'lastModified'" [ngClass]="'text-' + col.cellAlignment" class="w-100">{{
          rowData[col.field] | date
        }}</span>
      </td>
      <td class="delete-col">
        <button
          pButton
          type="button"
          icon="pi pi-trash"
          class="p-button-rounded p-button-text p-button-sm delete-manual-cd text-center"
          (click)="removeSelectedRow(rowData)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
<ng-template #manualGridMenu>
  <p-listbox
    [options]="cols"
    [metaKeySelection]="false"
    [checkbox]="true"
    [filter]="true"
    [multiple]="true"
    optionLabel="header"
    [listStyle]="{ 'max-height': '250px' }"
    [style]="{ width: '15rem' }"
    [(ngModel)]="selectedColumns"
    showToggleAll="true"
    styleClass="manualTableGearOverlay"
  >
  </p-listbox>
</ng-template>
