export const EDITOR_OPTIONS = {
  automaticLayout: true, // adjusts to the size of parent container
  autoClosingBrackets: false,
  autoClosingQuotes: false,
  fontFamily: 'consolas',
  fontSize: 18,
  formatOnType: true,
  formatOnPaste: true,
  language: 'mysql',
  lineNumbersMinChars: 4, // width of line numbers
  minimap: {
    enabled: false // toggles scrollable mini map on right side
  },
  readOnly: false,
  renderLineHighlight: 'none', // shows border around current line
  renderIndentGuides: true,
  scrollBeyondLastLine: false,
  contextmenu: false,
  theme: 'vs' // options: 'vs' | 'vs-dark' | 'hc-black'
};
