// Angular
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

// 3rd party
import { NgxSpinnerModule } from 'ngx-spinner';

// App
import { CdmTableComponent } from './cdm-table.component';
import { ChargeCatRoutingModule } from '../charge-cat-routing.module';
import { AppMaterialModule } from 'src/app/app-material.module';
import { PrimeNgModule } from 'src/app/primeng.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CdmTableComponent],
  imports: [
    AppMaterialModule,
    BrowserAnimationsModule,
    ChargeCatRoutingModule,
    CommonModule,
    FormsModule,
    NgxSpinnerModule,
    PrimeNgModule
  ],
  exports: [CdmTableComponent]
})
export class CdmTableModule {}
