import { Selector } from '@ngxs/store';

import { Category } from 'src/app/_shared/models/category';
import { ChargeDescription } from 'src/app/_shared/models/charge-description';
import { ChargeCatErrorStateModel } from '../errors/error-state.model';
import { ChargeCatAppState } from './app.state';
import { ActiveRunningTenantsModel, ChargeCatStateModel, CptHcpcsCodeDescription, RevenueCodeDescription } from './app-state.model';

export class ChargeCatSelectors {
  /**
   * @Selector Gets all categories from the store.
   */
  @Selector([ChargeCatAppState])
  static allCategories(state: ChargeCatStateModel): Category[] {
    return state.categories;
  }

  /**
   * @Selector Gets all categories with rulesfrom the store.
   */
  @Selector([ChargeCatAppState])
  static categoriesWithRules(state: ChargeCatStateModel): Category[] {
    return state.categoriesWithRules;
  }

  /**
   * @Selector Gets selected category
   */
  @Selector([ChargeCatAppState])
  static selectedCategory(state: ChargeCatStateModel): Category {
    return state.selectedCategory;
  }

  /**
   * @Selector Gets the manual includes associated with the selected category.
   */
  @Selector([ChargeCatAppState])
  static manualIncludes(state: ChargeCatStateModel): ChargeDescription[] {
    return state.selectedCategoryIncludes;
  }

  /**
   * @Selector Gets the manual excludes associated with the selected category.
   */
  @Selector([ChargeCatAppState])
  static manualExcludes(state: ChargeCatStateModel): ChargeDescription[] {
    return state.selectedCategoryExcludes;
  }

  /**
   * @Selector Gets the tempCategory used for editing the N1QL statement
   */
  @Selector([ChargeCatAppState])
  static getTempCategory(state: ChargeCatStateModel): Category {
    return state.tempCategory;
  }

  /**
   * Gets the boolean flag indicating if there are pending
   * changes in the N1QL editor.
   */
  @Selector([ChargeCatAppState])
  static isN1QLEditorDirty(state: ChargeCatStateModel): boolean {
    return state.isN1QLEditorDirty;
  }

  /**
   * Gests the list of all unique tenants to be displayed in the CDM table
   */
  @Selector([ChargeCatAppState])
  static tenantList(state: ChargeCatStateModel) {
    return state.tenantList;
  }

  /**
   * Gests the list of all unique HCPCS codes
   */
  @Selector([ChargeCatAppState])
  static hcpcsList(state: ChargeCatStateModel): CptHcpcsCodeDescription[] {
    return state.hcpcsList;
  }

  /**
   * Gests the list of all unique CPT codes
   */
  @Selector([ChargeCatAppState])
  static cptList(state: ChargeCatStateModel): CptHcpcsCodeDescription[] {
    return state.cptList;
  }

  /**
   * Gests the list of all unique revenue codes
   */
  @Selector([ChargeCatAppState])
  static revenueCodeList(state: ChargeCatStateModel): RevenueCodeDescription[] {
    return state.revenueCodeList;
  }

  /**
   * Gests the list of all unique revenue codes
   */
  @Selector([ChargeCatAppState])
  static revenueCodeDictionary(state: ChargeCatStateModel): any {
    return state.revenueCodeDictionary;
  }

  /**
   * Gests the list of all unique categories for hb
   */
  @Selector([ChargeCatAppState])
  static categoryDictionaryHB(state: ChargeCatStateModel): any {
    return state.categoryDictionaryHB;
  }
  /**
   * Gests the list of all unique categories for pb
   */
  @Selector([ChargeCatAppState])
  static categoryDictionaryPB(state: ChargeCatStateModel): any {
    return state.categoryDictionaryPB;
  }
  /**
   * Gests the list of all unique categories for drgv
   */
  @Selector([ChargeCatAppState])
  static categoryDictionaryDRGV(state: ChargeCatStateModel): any {
    return state.categoryDictionaryDRGV;
  }
  /**
   * Gests the list of all unique categories for cc
   */
  @Selector([ChargeCatAppState])
  static categoryDictionaryCC(state: ChargeCatStateModel): any {
    return state.categoryDictionaryCC;
  }
  /**
   * Gests the list of all unique categories
   */
  @Selector([ChargeCatAppState])
  static categoryDictionary(state: ChargeCatStateModel): any {
    return state.categoryDictionary;
  }

  /**
   * @Selector Gets CDM charge descriptions for the CDM table
   */
  @Selector([ChargeCatAppState])
  static cdmChargeDescriptions(state: ChargeCatStateModel) {
    return state.cdmChargeDescriptions;
  }
  /**
   * @Selector Gets CDM data for the CDM table
   */
  @Selector([ChargeCatAppState])
  static cdmTableData(state: ChargeCatStateModel) {
    return state.cdmTableData;
  }
  /**
   * @Selector Gets the page count for the CDM table
   */
  @Selector([ChargeCatAppState])
  static cdmTablePageCount(state: ChargeCatStateModel) {
    return state.cdmDataPageCount;
  }

  /**
   * @Selector Gets the CPT dictionary for mapping CDM Table
   * SI and Payment Rate column values.
   */
  @Selector([ChargeCatAppState])
  static getCptDictionary(state: ChargeCatStateModel) {
    return state.cptDictionary;
  }

  /**
   * @Selector Gets the HCPCS dictionary for mapping CDM Table
   * SI and Payment Rate column values.
   */
  @Selector([ChargeCatAppState])
  static getHcpcsDictionary(state: ChargeCatStateModel) {
    return state.hcpcsDictionary;
  }

  /**
   * @Selector Gets the SI dictionary
   */
  @Selector([ChargeCatAppState])
  static getSiDictionary(state: ChargeCatStateModel) {
    return state.siDictionary;
  }

  /**
   * @Selector Gets the AMA Description dictionary
   */
  @Selector([ChargeCatAppState])
  static getAmaDescrDictionary(state: ChargeCatStateModel) {
    return state.amaDescrDictionary;
  }

  /**
   * @Selector Gets the APC dictionary
   */
  @Selector([ChargeCatAppState])
  static getApcDictionary(state: ChargeCatStateModel) {
    return state.apcDictionary;
  }


  /**
   * @Selector Gets the tenants with active job  
   */
   @Selector([ChargeCatAppState])
   static getActiveRunningTenants(state: ActiveRunningTenantsModel) {
     return state.tenants == undefined ? new Array():state.tenants;
   }
}
