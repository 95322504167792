import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

const modules = [MatButtonModule, MatDialogModule, MatIconModule, MatSidenavModule];
@NgModule({
  declarations: [],
  imports: modules,
  exports: modules
})
export class AppMaterialModule {}
