// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// 3rd Party
import { NgxsModule } from '@ngxs/store';
import { NgxsSelectSnapshotModule } from '@ngxs-labs/select-snapshot';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';

// App Modules
import { AppMaterialModule } from '../app-material.module';
import { HeaderComponent } from './components/header/header.component';
import { HeaderModule } from './components/header/header.module';

// App
import { LoginComponent } from './components/login/login.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { IdleTimeoutModalComponent } from './services/session-idle/idle-timeout-modal/idle-timeout-modal.component';
import { ChargeCatState } from './store/charge-cat-states';
import { UserState } from './store/user/user.state';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [LoginComponent, NotFoundComponent, IdleTimeoutModalComponent],
    imports: [
        AppMaterialModule,
        CommonModule,
        HeaderModule,
        FormsModule,
        NgxsModule.forRoot([UserState, ...ChargeCatState], {
            developmentMode: !environment.production
        }),
        NgxsReduxDevtoolsPluginModule.forRoot({
            disabled: environment.production,
            name: 'Ngxs State Management'
        }),
        NgxsSelectSnapshotModule.forRoot(),
        NgxsDispatchPluginModule.forRoot()
    ],
    exports: [HeaderComponent]
})
export class CoreModule {}
