<nav class="navbar-expand-sm navbar-expand-md navbar-expand-lg navbar navbar-light bg-light">
  <a class="navbar-brand header-logo" [routerLink]="'/charge-cat'">
    <img src="../../../../assets/images/cloudmed-logo.svg" height="25" class="d-inline-block align-top" alt="" />
  </a>
  <span class="navbar-brand navbar-app-title ml-4 mt-1">Charge Cat</span>
  <!-- <ul class="navbar-nav mr-auto main-nav">
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="'/charge-cat'"><i class="fa fa-list" aria-hidden="true"></i> Charge Cat</a>
    </li>
  </ul> -->
  <ul class="navbar-nav ml-auto">
    <div class="btn-group overlay-toggle" (click)="op.toggle($event)">
      <cm-user-avatar [user]="userInfo$ | async" [size]="'large'"></cm-user-avatar>
    </div>
  </ul>
</nav>

<p-overlayPanel styleClass="profile-overlay" #op [style]="{ width: '400px' }">
  <ng-template pTemplate>
    <div class="d-flex align-items-center justify-content-between overlay-header">
      <div class="overlay-company">Cloudmed</div>
      <div class="overlay-build-number">Build# {{ buildNumber }}</div>
    </div>
    <div class="d-flex align-items-center">
      <cm-user-avatar [user]="userInfo$ | async" [size]="'x-large'"></cm-user-avatar>
      <div class="d-flex flex-column w-100">
        <div class="overlay-avatar-name">{{ (userInfo$ | async).name }}</div>
        <div class="mt-1 overlay-title">
          {{ (userInfo$ | async).title }}
        </div>
        <!-- <div class="mt-1 overlay-email">
          lasercobra@revintsolutions.com
        </div> -->
        <div class="overlay-links">
          <a [href]="settingsUrl" target="_blank">Settings</a>
        </div>
      </div>
    </div>
    <div class="overlay-footer">
      <button class="btn btn-sm float-right btn-light" (click)="logout()">Sign Out</button>
    </div>
  </ng-template>
</p-overlayPanel>
