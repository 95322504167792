import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'cm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loaded = false;

  constructor(private readonly authService: AuthService, private readonly router: Router) {
  }

  ngOnInit(): void {
    this.authService.isAuthenticated$.subscribe(value=> {
      this.loaded = true;
      if (value){
        this.router.navigate(['/charge-cat']);
      }
    });
  }

  login() {
    this.authService.loginWithRedirect();
  }
}
