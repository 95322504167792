// Angular
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

// 3rd party
import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

// App modules
import { AppMaterialModule } from './app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './_core/core.module';
import { ChargeCatModule } from './charge-cat/charge-cat.module';
import { SharedModule } from './_shared/shared.module';

// Components
import { AppComponent } from './app.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    ChargeCatModule,
    CoreModule,
    FormsModule,
    FormsModule,
    SharedModule,
    NgxSpinnerModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true
    }),
    AuthModule.forRoot({
      domain: environment.config.auth0.domain,
      clientId: environment.config.auth0.clientId,
      redirectUri: window.location.origin,
      audience: environment.config.auth0.audience,
      httpInterceptor: {
        allowedList: [`${environment.config.dataApiUrl}*`]
      }
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
