<div class="container-fluid">
  <div class="cc-tab-wrapper">
    <p-tabView (onChange)="onChangeTab()">
      <p-tabPanel header="Category Definition" [headerStyle]="{ 'padding-right': '5px' }">
        <cm-top-slider [visible]="displayTopSlider" (toggleTopSlider)="toggleTopSlider()">
          <cm-n1ql-editor [selectedCategory]="selectedCategory$ | async"></cm-n1ql-editor>
        </cm-top-slider>

        <cm-side-collapse>
          <cm-category-list class="sidebar-list"></cm-category-list>
          <cm-category-query class="main-content"></cm-category-query>
        </cm-side-collapse>
      </p-tabPanel>

      <p-tabPanel header="CDM Table" [disabled]="isLoadingCategories$ | async">
        <cm-cdm-table *ngIf="!(isLoadingCategories$ | async)"></cm-cdm-table>
      </p-tabPanel>
    </p-tabView>
    <div *ngIf="(jobs$ | async)?.length > 0" class="jobs-running-count">
      <!-- loading spinner do not remove divs as this will break this lds ring spinner -->
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <!-- loading spinner end -->
      <span class="ml-2 pr-2"
        >{{ (jobs$ | async)?.length }} job<span *ngIf="(jobs$ | async)?.length > 1">s</span> running</span
      >
    </div>
  </div>
</div>