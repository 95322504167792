export interface ColumnFilter {
  field: string;
  header: string;
  width: string;
  filterType: string;
  listBoxKey?: string;
  listBoxDisplay?: string;
  selectedItems?: string[];
  uniqueData?: any[];
  uniqueDataNonLazyFilter?: any[];
  cbFilterField?: string;
  cbFilterType?: string;
  cellAlignment?: CellAlignments;
  tempSelections?: any[];
  dateRange?: any[];
  cbFilterField2?: string;
}

export enum CellAlignments {
  Right = 'right',
  Left = 'left',
  Center = 'center'
}
