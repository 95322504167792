<div class="wrapper cdm-table-wrapper">
  <div class="table-responsive charge-cat-grid-page cdm-table">
    <p-overlayPanel styleClass="grid-menu-overlay" #tableConfigOverlay appendTo="body">
      <ng-template pTemplate>
        <p-listbox
          [options]="cols"
          [metaKeySelection]="false"
          [checkbox]="true"
          [filter]="true"
          [multiple]="true"
          optionLabel="header"
          [listStyle]="{ 'max-height': '250px' }"
          [style]="{ width: '15rem' }"
          [(ngModel)]="selectedColumns"
          (onChange)="setColumnState($event.value)"
          showToggleAll="true"
          styleClass="cdmTableGearOverlay"
        >
        </p-listbox>
      </ng-template>
    </p-overlayPanel>

    <p-table
      #cdmTable
      [value]="virtualCdmData"
      [columns]="selectedColumns"
      [scrollable]="true"
      scrollHeight="flex"
      scrollDirection="both"
      [rows]="cdmTablePageSizeViewValue"
      [virtualScroll]="true"
      [virtualRowHeight]="34"
      [lazy]="true"
      [lazyLoadOnInit]="false"
      [customSort]="true"
      (onLazyLoad)="onLazyScroll($event)"
      styleClass="p-datatable-gridlines p-datatable-sm"
      [reorderableColumns]="true"
      stateStorage="local"
      stateKey="cdmtable"
      rowHover="true"
      [loading]="loading"
      [loadingIcon]="'pi pi-spinner'"
      [(selection)]="selectedRows"
      (onRowSelect)="onToggleRowSelect()"
      (onRowUnselect)="onToggleRowSelect()"
      (onColReorder)="hasLocalState = true"
      dataKey="docId"
    >
      <ng-template pTemplate="caption">
        <div class="d-flex justify-content-end">
          <div
            *ngIf="loadingUpdates"
            pTooltip="Applying updates"
            tooltipPosition="bottom"
            class="update-loading-spinner"
          >
            <!-- loading spinner do not remove divs as this will break this lds ring spinner -->
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <!-- loading spinner end -->
          </div>
          <p-radioButton
            name="groupname"
            value="all"
            inputId="all"
            pTooltip="Change service line (all) and clear all filters"
            tooltipPosition="top"
            (click)="changeServiceLine()"
            [(ngModel)]="selectedServiceLine"
          ></p-radioButton>
          <label class="category-radio-labels" for="all">All</label>
          <p-radioButton
            name="groupname"
            value="hb"
            inputId="hb"
            pTooltip="Change service line (hb) and clear all filters"
            tooltipPosition="top"
            (click)="changeServiceLine()"
            [(ngModel)]="selectedServiceLine"
          ></p-radioButton>
          <label class="category-radio-labels" for="hb">HB</label>
          <p-radioButton
            name="groupname"
            value="pb"
            inputId="pb"
            pTooltip="Change service line (pb) and clear all filters"
            tooltipPosition="top"
            (click)="changeServiceLine()"
            [(ngModel)]="selectedServiceLine"
          ></p-radioButton>
          <label class="category-radio-labels" for="pb">PB</label>
          <p-radioButton
            name="groupname"
            value="cc"
            inputId="cc"
            pTooltip="Change service line (hb and pb) and clear all filters"
            tooltipPosition="top"
            (click)="changeServiceLine()"
            [(ngModel)]="selectedServiceLine"
          ></p-radioButton>
          <label class="category-radio-labels" for="cc">CC</label>
          <p-radioButton
            name="groupname"
            value="drgv"
            inputId="drgv"
            pTooltip="Change service line (drgv) and clear all filters"
            tooltipPosition="top"
            (click)="changeServiceLine()"
            [(ngModel)]="selectedServiceLine"
          ></p-radioButton>
          <label class="category-radio-labels-last" for="drgv">DRGV</label>
          <button class="btn cm-btn-clear-state mr-2" *ngIf="hasLocalState" (click)="removeState()">Clear State</button>
          <button
            class="btn btn-success mr-2"
            *ngIf="cdmPageFilter.paging.orderByField !== '' && hasFiltersApplied"
            (click)="clearSortAndFilters()"
          >
            Clear All
          </button>
          <button
            class="btn btn-primary mr-2"
            pTooltip="Remove Sorting"
            tooltipPosition="bottom"
            *ngIf="cdmPageFilter.paging.orderByField !== ''"
            (click)="resetSort()"
          >
            <img src="../../../../assets/images/remove_sort_icon_5.svg" class="clear-sort-icon" />
          </button>
          <button
            class="btn cm-btn-warning mr-2"
            pTooltip="Clear All Filters"
            tooltipPosition="bottom"
            (click)="clearAllFilters()"
            *ngIf="hasFiltersApplied"
          >
            <i class="pi pi-filter-slash"></i>
          </button>
          <button
            class="btn btn-info mr-2 edit-categories-btn"
            pTooltip="Edit selected category descriptions"
            tooltipPosition="left"
            [disabled]="!enableEditButton"
            (click)="showEditModal = true; getCategoriesForModal(); clearAddAndDeleteList()"
          >
            <i class="pi pi-window-maximize"></i>
          </button>
          <button
            class="btn btn-success mr-3 edit-categories-btn"
            pTooltip="Export to Excel"
            tooltipPosition="bottom"
            (click)="exportToExcel()"
          >
            <i class="pi pi-file-excel"></i>
          </button>
          <div>
            <i class="fa fa-cog fa-2x table-gear-icon" aria-hidden="true" (click)="tableConfigOverlay.show($event)"></i>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 75px">&nbsp;</th>
          <ng-container *ngFor="let col of columns">
            <th
              *ngIf="
                isLazy &&
                col &&
                col.field !== 'si' &&
                col.field !== 'paymentRate' &&
                col.field !== 'amaDescription' &&
                col.field !== 'tokenCommaList'
              "
              [ngStyle]="{ width: col.width }"
              pReorderableColumn
              [pSortableColumn]="col.field"
            >
              <div
                [ngClass]="{ lazyFiltered: this.isLazySortCol(col) }"
                class="d-flex justify-content-between align-items-center w-100"
              >
                {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
              </div>
            </th>

            <th
              *ngIf="
                col &&
                (col.field === 'si' ||
                  col.field === 'paymentRate' ||
                  col.field === 'amaDescription' ||
                  col.field === 'tokenCommaList')
              "
              [ngStyle]="{ width: col.width }"
              pReorderableColumn
            >
              <div
                [ngClass]="{ lazyFiltered: this.isLazySortCol(col) }"
                class="d-flex justify-content-between align-items-center"
              >
                {{ col.header }}
              </div>
            </th>
          </ng-container>
        </tr>
        <tr>
          <th class="select-all-cdm-table">
            <p-checkbox
              (onChange)="selectAll()"
              *ngIf="virtualCdmData.length <= 100"
              [(ngModel)]="selectAllChecked"
              binary="true"
            ></p-checkbox>
          </th>
          <ng-container *ngFor="let col of columns; let i = index">
            <th *ngIf="col" pReorderableColumn [ngStyle]="{ width: col.width }">
              <p-overlayPanel #op appendTo="body">
                <ng-template pTemplate>
                  <div style="width: 200px">
                    <p-selectButton [options]="textFilterOptions" [(ngModel)]="col.cbFilterType" class="op-filter-ops">
                      <ng-template let-item>
                        <div style="width: 100%">
                          <div *ngIf="item.value === col.cbFilterType">
                            <img class="filter-option-icon" [src]="item.selectedIconSrc" /> {{ item.label }}
                          </div>
                          <div *ngIf="item.value !== col.cbFilterType">
                            <img class="filter-option-icon" [src]="item.iconSrc" /> {{ item.label }}
                          </div>
                        </div>
                      </ng-template>
                    </p-selectButton>
                  </div>
                </ng-template>
              </p-overlayPanel>

              <p-overlayPanel #opToken appendTo="body">
                <ng-template pTemplate>
                  <div style="width: 200px">
                    <p-selectButton
                      [options]="textTokenFilterOptions"
                      [(ngModel)]="col.cbFilterType"
                      class="op-filter-ops"
                    >
                      <ng-template let-item>
                        <div style="width: 100%">
                          <div *ngIf="item.value === col.cbFilterType">
                            <img class="filter-option-icon" [src]="item.selectedIconSrc" /> {{ item.label }}
                          </div>
                          <div *ngIf="item.value !== col.cbFilterType">
                            <img class="filter-option-icon" [src]="item.iconSrc" /> {{ item.label }}
                          </div>
                        </div>
                      </ng-template>
                    </p-selectButton>
                  </div>
                </ng-template>
              </p-overlayPanel>

              <div class="input-group" *ngIf="col.filterType === 'text-extra'">
                <div class="input-group-prepend" *ngIf="col.field !== 'tokenCommaList'">
                  <button
                    class="btn btn-sm radio-filter-btn-outline"
                    style="
                      padding: 0px;
                      border-bottom-right-radius: 0px;
                      border-top-right-radius: 0px;
                      border-right: none;
                    "
                    (click)="op.toggle($event)"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    pTooltip="Filter options"
                    tooltipPosition="bottom"
                  >
                    <img
                      *ngIf="col.cbFilterType == 'LIKE'"
                      class="filter-option-icon"
                      src="../../../assets/images/contains.svg"
                    />
                    <img
                      *ngIf="col.cbFilterType == 'NOT LIKE'"
                      class="filter-option-icon"
                      src="../../../assets/images/does-not-contain.svg"
                    />
                    <img
                      *ngIf="col.cbFilterType == 'BEGINS WITH'"
                      class="filter-option-icon"
                      src="../../../assets/images/starts-with.svg"
                    />
                  </button>
                </div>
                <div class="input-group-prepend" *ngIf="col.field === 'tokenCommaList'">
                  <!-- <button class="btn btn-sm radio-filter-btn-outline" (click)="op.toggle($event)" type="button" aria-haspopup="true" aria-expanded="false">+</button> -->
                  <button
                    class="btn btn-sm radio-filter-btn-outline"
                    style="
                      padding: 0px;
                      border-bottom-right-radius: 0px;
                      border-top-right-radius: 0px;
                      border-right: none;
                    "
                    (click)="opToken.toggle($event)"
                    type="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                    pTooltip="Filter options"
                    tooltipPosition="bottom"
                  >
                    <img
                      *ngIf="col.cbFilterType == 'SATISFIESLIKE'"
                      class="filter-option-icon"
                      src="../../../assets/images/contains.svg"
                    />
                    <img
                      *ngIf="col.cbFilterType == 'SATISFIES NOT LIKE'"
                      class="filter-option-icon"
                      src="../../../assets/images/does-not-contain.svg"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  style="border-right: none"
                  [(ngModel)]="col.tempSelections"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-sm btn-default"
                    style="border-top: #ced4da solid 0.5px; border-bottom: #ced4da solid 0.5px; background-color: white"
                    pTooltip="Remove Filter"
                    tooltipPosition="bottom"
                    (click)="col.tempSelections = ''; onDescriptionTextFilter(col)"
                    *ngIf="col.selectedItems.length > 0 && !loading"
                  >
                    X
                  </button>
                  <button class="btn btn-sm btn-success" (click)="onDescriptionTextFilter(col)">Apply</button>
                </div>
              </div>

              <input
                *ngIf="col.filterType === 'text'"
                type="search"
                class="form-control form-control-sm"
                (input)="onTextFilter(col)"
                [(ngModel)]="col.tempSelections"
              />

              <div *ngIf="col.filterType == 'list'" style="display: flex; justify-content: space-evenly" class="w-100">
                <button
                  *ngIf="
                    col.field !== 'chargeCategoryCommaList' &&
                    col.field !== 'si' &&
                    col.field !== 'paymentRate' &&
                    col.field !== 'amaDescription'
                  "
                  class="btn btn-sm radio-filter-btn-outline"
                  style="
                    padding: 0px;
                    border-bottom-right-radius: 0px;
                    border-top-right-radius: 0px;
                    border-right: none;
                  "
                  (click)="opFL.toggle($event)"
                  type="button"
                  pTooltip="Filter options"
                  tooltipPosition="bottom"
                >
                  <img
                    *ngIf="col.cbFilterType == 'IN'"
                    class="filter-option-icon"
                    src="../../../assets/images/contains.svg"
                  />
                  <img
                    *ngIf="col.cbFilterType == 'NOT IN'"
                    class="filter-option-icon"
                    src="../../../assets/images/does-not-contain.svg"
                  />
                </button>
                <div
                  [ngClass]="
                    col.field === 'chargeCategoryCommaList' ||
                    col.field === 'si' ||
                    col.field === 'paymentRate' ||
                    col.field === 'amaDescription'
                      ? 'w-100'
                      : 'multi-select-in'
                  "
                >
                  <p-multiSelect
                    #multiSelectFilter
                    *ngIf="col.filterType === 'list'"
                    [options]="col.uniqueDataNonLazyFilter"
                    [virtualScroll]="true"
                    [itemSize]="34"
                    placeholder=""
                    optionLabel="displayName"
                    name="{{ col.field }}"
                    [(ngModel)]="col.tempSelections"
                    [styleClass]="
                      col.field === 'chargeCategoryCommaList' ||
                      col.field === 'si' ||
                      col.field === 'paymentRate' ||
                      col.field === 'amaDescription'
                        ? 'table-filter-multiselect'
                        : 'table-filter-multiselect-with-options'
                    "
                    appendTo="body"
                    [resetFilterOnHide]="true"
                    (onPanelHide)="onMultiSelectHide(col)"
                    (onPanelShow)="onMultiSelectShow(col)"
                  >
                    <p-header>
                      <button
                        *ngIf="col.header == 'Category'"
                        #myBtn
                        pButton
                        pRipple
                        pTooltip="Search Options"
                        tooltipPosition="bottom"
                        type="button"
                        icon="pi pi-ellipsis-v"
                        iconPos="left"
                        class="p-button-secondary p-button-text filter-option-btn"
                        style="margin-left: -14px; width: 21px"
                        (click)="opf.toggle($event)"
                      ></button>
                      <p-overlayPanel #opf [appendTo]="'myBtn'" [dismissable]="true">
                        <ng-template pTemplate>
                          <div class="radio-filter-btn-wrap">
                            <div class="p-field-radiobutton mb-1">
                              <p-radioButton
                                value="contains"
                                name="groupname"
                                label="Contains"
                                (click)="opf.hide($event)"
                                [(ngModel)]="multiSelectFilter.filterMatchMode"
                              ></p-radioButton>
                            </div>
                            <div class="p-field-radiobutton mb-1">
                              <p-radioButton
                                value="doesNotContain"
                                name="groupname"
                                label="Doesn't contain"
                                (click)="opf.hide($event)"
                                [(ngModel)]="multiSelectFilter.filterMatchMode"
                              ></p-radioButton>
                            </div>
                          </div>
                        </ng-template>
                      </p-overlayPanel>
                    </p-header>
                    <ng-template let-item pTemplate="item">
                      <div>
                        <div>{{ item.displayName }}</div>
                      </div>
                    </ng-template>
                    <p-footer class="overlay-footer justify-content-between">
                      <ng-container *ngIf="!multiSelectLoading">
                        <button
                          class="btn btn-sm btn-success"
                          (click)="applyFilter(col.field); closeMultiSelect(multiSelectFilter, $event)"
                        >
                          Apply
                        </button>
                        <button
                          class="btn btn-sm btn-light"
                          (click)="removeFilter(col); closeMultiSelect(multiSelectFilter, $event)"
                        >
                          Remove <i class="fa fa-filter" aria-hidden="true"></i>
                        </button>
                      </ng-container>
                      <ng-container *ngIf="multiSelectLoading">
                        <!-- loading spinner do not remove divs as this will break this lds ring spinner -->
                        <div class="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                        <!-- loading spinner end -->
                      </ng-container>
                    </p-footer>
                  </p-multiSelect>
                </div>
                <p-overlayPanel #opFL appendTo="body">
                  <ng-template pTemplate>
                    <div style="width: 200px">
                      <p-selectButton [options]="inFilterOptions2" [(ngModel)]="col.cbFilterType" class="op-filter-ops">
                        <ng-template let-item>
                          <div style="width: 100%">
                            <div *ngIf="item.value === col.cbFilterType">
                              <img class="filter-option-icon" [src]="item.selectedIconSrc" /> {{ item.label }}
                            </div>
                            <div *ngIf="item.value !== col.cbFilterType">
                              <img class="filter-option-icon" [src]="item.iconSrc" /> {{ item.label }}
                            </div>
                          </div>
                        </ng-template>
                      </p-selectButton>
                    </div>
                  </ng-template>
                </p-overlayPanel>
              </div>

              <p-calendar
                #datePicker
                *ngIf="col.filterType === 'date'"
                [numberOfMonths]="2"
                selectionMode="range"
                appendTo="body"
                inputId="range"
                [(ngModel)]="col.tempSelections"
                [readonlyInput]="true"
                [inputStyle]="{ padding: '.25rem .5rem' }"
              >
                <p-footer class="overlay-footer justify-content-between">
                  <button
                    class="btn btn-sm btn-success"
                    [disabled]="checkDateSelection(col)"
                    (click)="applyFilter(col.field); closeDatePicker(datePicker)"
                  >
                    Apply
                  </button>
                  <button class="btn btn-sm btn-light" (click)="removeFilter(col); closeDatePicker(datePicker)">
                    Remove <i class="fa fa-filter" aria-hidden="true"></i>
                  </button>
                </p-footer>
              </p-calendar>
            </th>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-index="rowIndex" let-columns="columns">
        <tr>
          <ng-container *ngIf="rowData">
            <td style="width: 75px">
              <div class="d-flex justify-content-between align-items-center w-100">
                <span class="row-selector-index d-inline-block">{{ index + 1 }}</span>
                <p-tableCheckbox [value]="rowData" class="text-right"></p-tableCheckbox>
              </div>
            </td>
            <ng-container *ngFor="let col of columns">
              <ng-container *ngIf="col">
                <td
                  class="overflow-wrap"
                  [ngStyle]="{ width: col.width }"
                  *ngIf="col.field === 'chargeCategoryCommaList'"
                >
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'tenantDisplayName'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'revenueCode'">
                  <span
                    [ngClass]="'text-' + col.cellAlignment"
                    class="w-100"
                    tooltipStyleClass="grid-tooltip"
                    pTooltip="{{ revCodeDictionary[rowData.revenueCode]?.displayName }}"
                    >{{ rowData[col.field] }}</span
                  >
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'hcpcs'">
                  <span
                    [ngClass]="'text-' + col.cellAlignment"
                    class="w-100"
                    tooltipStyleClass="grid-tooltip"
                    pTooltip="{{ rowData.hcpcs }}  - {{ rowData.description }}"
                    >{{ rowData[col.field] }}</span
                  >
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'cpt'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'si'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData.si }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'paymentRate'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData.paymentRate }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'chargeCode'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'description'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'tokenCommaList'">
                  <span
                    [ngClass]="'text-' + col.cellAlignment"
                    class="w-100"
                    [pTooltip]="rowData[col.field]"
                    tooltipStyleClass="grid-tooltip"
                  >
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'homegrownDescription'">
                  <span
                    [ngClass]="'text-' + col.cellAlignment"
                    class="w-100"
                    [pTooltip]="rowData[col.field]"
                    tooltipStyleClass="grid-tooltip"
                  >
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'amaDescription'">
                  <span
                    [ngClass]="'text-' + col.cellAlignment"
                    class="w-100"
                    [pTooltip]="rowData[col.field]"
                    tooltipStyleClass="grid-tooltip"
                  >
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'descriptionType'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'totalDollar'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] | currency }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'chargeCount'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100"> {{ rowData[col.field] | number }}</span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'avgDollar'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] | currency }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'avgQuant'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] | number }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'totalQuant'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] | number }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'createdDate'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] | date }}
                  </span>
                </td>
                <td class="text-ellipsis" [ngStyle]="{ width: col.width }" *ngIf="col.field === 'lastModified'">
                  <span [ngClass]="'text-' + col.cellAlignment" class="w-100">
                    {{ rowData[col.field] | date }}
                  </span>
                </td>
              </ng-container>
            </ng-container>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody" let-columns="columns">
        <tr style="height: 34px">
          <td class="select-all-cdm-table">&nbsp;</td>
          <td *ngFor="let col of cols" class="pl-0 pr-0" [ngStyle]="{ width: col.width }">
            <div class="loading-text"></div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <div class="text-center no-records-found">No records found</div>
      </ng-template>
    </p-table>
    <div class="mt-1 d-flex justify-content-end">
      <span [ngClass]="{ 'over-10k-records': pageCount > 10000 }">Total Records: {{ pageCount | number }}</span>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="showEditModal"
  [modal]="true"
  [style]="{ width: '80vw' }"
  [contentStyle]="{ minHeight: '325px', 'padding-bottom': '0px' }"
  [baseZIndex]="10000"
  [resizable]="false"
>
  <ng-template pTemplate="header">
    <div class="p-dialog-title">Edit Description Categories</div>
    <div class="d-flex justify-content-between edit-radio-btn-container">
      <div class="edit-radio-btn">
        <p-radioButton name="singleUpdate" [value]="singleUpdate" [(ngModel)]="editMode"></p-radioButton>
        <label for="singleUpdate">Single Update</label>
      </div>
      <div class="edit-radio-btn">
        <p-radioButton name="bulkApply" [value]="bulkApply" [(ngModel)]="editMode"></p-radioButton>
        <label for="bulkApply">Bulk Apply</label>
      </div>
    </div>
  </ng-template>
  <form #editCategoryForm="ngForm" novalidate>
    <div class="alert alert-info" role="alert" *ngIf="categoriesForDescriptions?.length === 0">
      There are no categories associated with the selected Charge Description(s). Categories can be added by searching
      for them below.
    </div>
    <label class="sel-cats-label" for="category" *ngIf="categoriesForDescriptions?.length > 0"
      >Selected Categories <small>(Enter a minimum of 3 characters)</small></label
    >
    <div class="cat-auto-comp-container">
      <p-autoComplete
        [suggestions]="filteredResults"
        [(ngModel)]="selectedSlCategoriesForDescriptions"
        name="categories"
        id="categories"
        [minLength]="3"
        field="name"
        [multiple]="true"
        (onSelect)="addOrRemoveCategory($event, 'add')"
        (onUnselect)="addOrRemoveCategory($event, 'remove')"
        (completeMethod)="filterCategories($event)"
        [style]="{ 'max-height': '115px' }"
      >
      </p-autoComplete>
    </div>
  </form>
  <div
    *ngIf="editMode === singleUpdate && (categoriesToDelete.length || categoriesToAdd.length)"
    class="d-flex justify-content-around w-100 mt-3"
  >
    <div *ngIf="categoriesToAdd && categoriesToAdd.length" class="add-categories single-update-cats">
      <h6 class="add-remove-header">Add</h6>
      <ul>
        <li class="d-flex justify-content-between" *ngFor="let add of categoriesToAdd">
          <div>{{ add.displayName }}</div>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-secondary p-button-text cat-remove-btn"
            (click)="removeFromAddOrDeletes(add, 'add')"
          ></button>
        </li>
      </ul>
    </div>
    <div *ngIf="categoriesToDelete && categoriesToDelete.length" class="remove-categories single-update-cats">
      <h6 class="add-remove-header">Remove</h6>
      <ul>
        <li class="d-flex justify-content-between" *ngFor="let remove of categoriesToDelete">
          <div>
            {{ remove.displayName }}
          </div>
          <button
            pButton
            pRipple
            type="button"
            icon="pi pi-times"
            class="p-button-rounded p-button-secondary p-button-text cat-remove-btn"
            (click)="removeFromAddOrDeletes(remove, 'remove')"
          ></button>
        </li>
      </ul>
    </div>
  </div>
  <div>
    <label class="sel-cdms-label">Selected Charge Descriptions</label>
    <p-table
      [value]="selectedRows"
      [scrollable]="true"
      scrollDirection="both"
      scrollHeight="300px"
      styleClass="p-datatable-gridlines p-datatable-sm"
    >
      <ng-template pTemplate="header">
        <tr class="header-row-edit-desc">
          <th class="row-width-edit-desc">Row</th>
          <th *ngFor="let col of cols" [ngStyle]="{ width: col.width }">{{ col.header }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-cdm let-i="rowIndex">
        <tr>
          <td class="row-width-edit-desc">{{ i + 1 }}</td>
          <td *ngFor="let col of cols" [ngStyle]="{ width: col.width }" [ngClass]="'text-' + col.cellAlignment">
            <span [ngClass]="'text-' + col.cellAlignment" class="w-100" *ngIf="col.filterType !== 'date'">{{
              cdm[col.field]
            }}</span>
            <span [ngClass]="'text-' + col.cellAlignment" class="w-100" *ngIf="col.filterType === 'date'">
              {{ cdm[col.field] | date }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <ng-template pTemplate="footer">
    <div class="mt-1 d-flex justify-content-start">
      <span>Total Selected: {{ selectedRows.length | number }}</span>
    </div>
    <button class="btn btn-sm btn-default mr-2" (click)="showEditModal = false">Cancel</button>
    <button class="btn btn-sm btn-success mr-2" (click)="showEditModal = false; applyCdmTableCategoryUpdate()">
      Update
    </button>
  </ng-template>
</p-dialog>
