import { NgModule } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToastModule } from 'primeng/toast';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { CardModule } from 'primeng/card';
import { ListboxModule } from 'primeng/listbox';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TabViewModule } from 'primeng/tabview';
import { ProgressBarModule } from 'primeng/progressbar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuModule } from 'primeng/menu';

const modules = [
  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  DialogModule,
  ListboxModule,
  MultiSelectModule,
  OverlayPanelModule,
  SidebarModule,
  TableModule,
  ToastModule,
  TooltipModule,
  RadioButtonModule,
  TabViewModule,
  ProgressBarModule,
  SelectButtonModule,
  CheckboxModule,
  MenuModule
];

@NgModule({
  declarations: [],
  imports: modules,
  exports: modules
})
export class PrimeNgModule {}
