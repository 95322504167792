import { Injectable } from '@angular/core';
import { Category, ChargeDescription } from 'src/app/_shared/models';
import { UserProfileReduced } from 'src/app/_shared/models/user-profile-reduced';
import { CatVersionDict, CdmDict } from '../store/charge-cat-store.facade';

export interface CategoryExt extends Category {
  foundIn?: 'includes' | 'excludes';
}
@Injectable({
  providedIn: 'root'
})
export class ManualInExDeltaService {
  getDelta(
    modifiedByDictionary: Record<string, UserProfileReduced>,
    cdmItem: ChargeDescription,
    includesDict: CdmDict,
    excludesDict: CdmDict,
    versionsDict: CatVersionDict
  ) {
    let modifiedBy = null;
    let lastModified = null;
    let lastFoundIn = null;
    let lfi = null; //used to check previous array a charge description was found in (includes or excludes)

    const includedVersionDocIds = includesDict[cdmItem.docId];
    if (includedVersionDocIds) {
      includedVersionDocIds.forEach(modified => {
        modifiedBy = modified.modifiedBy;
        lastModified = modified.lastModified;
      });
    }

    const excludedVersionDocIds = excludesDict[cdmItem.docId];
    if (excludedVersionDocIds) {
      excludedVersionDocIds.forEach(modified => {
        modifiedBy = modified.modifiedBy;
        lastModified = modified.lastModified;
      });
    }

    return { modifiedBy, lastModified };
  }

  private isCdmInVersion(version: CategoryExt, cdmItem: ChargeDescription): 'includes' | 'excludes' {
    if (version.chargeDescriptions.includes(cdmItem.docId)) {
      version.foundIn = 'includes';
      return 'includes';
    }
    if (version.chargeDescriptionsExclusions.includes(cdmItem.docId)) {
      version.foundIn = 'excludes';
      return 'excludes';
    }
    return null;
  }

  private setCdmModifiedAndDate(
    modifiedBy: string,
    lastModified: string,
    version: CategoryExt,
    modifiedByDictionary: Record<string, UserProfileReduced>,
    lastFoundIn: 'includes' | 'excludes'
  ) {
    let lfi;
    if (lastFoundIn) {
      lfi = lastFoundIn;
    }
    //if never set set it and do not set it again unless found in different array than originally found
    // includes and exlcudes
    if (!modifiedBy && !lastModified) {
      ({ modifiedBy, lfi, lastModified } = this.setUserAndDate(
        version,
        modifiedByDictionary,
        modifiedBy,
        lastModified
      ));
    } else if (lastFoundIn !== version.foundIn) {
      ({ modifiedBy, lfi, lastModified } = this.setUserAndDate(
        version,
        modifiedByDictionary,
        modifiedBy,
        lastModified
      ));
    }
    return { modifiedBy, lastModified, lfi };
  }

  private setUserAndDate(
    version: CategoryExt,
    modifiedByDictionary: Record<string, UserProfileReduced>,
    modifiedBy: string,
    lastModified: string
  ) {
    if (version.modifiedBy && modifiedByDictionary[version.modifiedBy]) {
      modifiedBy = modifiedByDictionary[version.modifiedBy].name;
    }
    lastModified = version.lastModified;
    return { modifiedBy, lfi: version.foundIn, lastModified };
  }
}
