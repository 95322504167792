import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import clonedeep from 'lodash.clonedeep';

import { ChargeDescription } from 'src/app/_shared/models/charge-description';
import { ClipboardOptions } from '../../shared/charge-description-grid/grid-clipboard-options';
import { ChargeCatStoreFacade } from '../../store/charge-cat-store.facade';
import { CellAlignments } from 'src/app/_shared/models/column-filter';

@Component({
  selector: 'cm-manual-exceptions-grid',
  templateUrl: './manual-exceptions-grid.component.html',
  styleUrls: ['./manual-exceptions-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualExceptionsGridComponent {
  @Input() data: ChargeDescription[] = [];
  @Input() toolbarTitle = '';
  @Input() gridId = '';
  @Output() removeRow = new EventEmitter<{ docId: string; gridId: string }>();
  clipboardOptions = ClipboardOptions;
  _selectedColumns: any[];

  cols = [
    {
      field: 'tenantDisplayName',
      header: 'Tenant',
      width: '240px',
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'revenueCode',
      header: 'Rev Code',
      width: '130px',
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'hcpcs',
      header: 'HCPCS',
      width: '130px',
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'cpt',
      header: 'CPT',
      width: '100px',
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'si',
      header: 'SI',
      width: '100px',
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'paymentRate',
      header: 'APC',
      width: '100px',
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'chargeCode',
      header: 'Charge Code',
      width: '150px',
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'description',
      header: 'Description',
      width: '425px',
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'amaDescription',
      header: 'AMA Description',
      width: '275px',
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'homegrownDescription',
      header: 'Homegrown Description',
      width: '275px',
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'descriptionType',
      header: 'Type',
      width: '135px',
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'modifiedBy',
      header: 'Modified By',
      width: '180px',
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'lastModified',
      header: 'Last Modified',
      width: '145px',
      cellAlignment: CellAlignments.Left
    }
  ];
  constructor(private facade: ChargeCatStoreFacade) {}
  ngOnInit() {
    this.selectedColumns = [...this.cols];
  }
  ngOnChanges() {
    this.data = clonedeep(this.data);
  }
  removeSelectedRow(chargeDescription: ChargeDescription) {
    this.removeRow.emit({ docId: chargeDescription.docId, gridId: this.gridId });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }
}
