import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { ClipboardOptions } from '../../shared/charge-description-grid/grid-clipboard-options';
import { ChargeCatSelectors } from '../../store/app/app.selectors';
import { ChargeCatStoreFacade } from '../../store/charge-cat-store.facade';
import { NotificationService } from 'src/app/_core/services/notification.service';
import { Category } from 'src/app/_shared/models/category';
import { Utils } from 'src/app/_shared/utils';
import { NotificationType } from 'src/app/charge-cat/shared/enums';
import { messages } from 'src/app/charge-cat/shared/messages';
import { ChargeCatLoadingSelectors } from '../../store/loading/loading.selectors';
import { AddCategoryModalComponent } from '../add-category-modal/add-category-modal.component';
import { DeleteCategoryModalComponent } from '../delete-category-modal/delete-category-modal.component';
import { EditCategoryModalComponent } from '../edit-category-modal/edit-category-modal.component';
import * as Actions from '../../store/app/app.actions';
import { LoadingStateService } from '../../store/loading-state.service';
import { Table } from 'primeng/table';

export enum FilterTypes {
  CategorySearch,
  N1QLSearch
}

@Component({
  selector: 'cm-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit, OnDestroy {
  @ViewChild('categoryGrid', { static: false }) public categoryGrid;
  @ViewChild('categoryFilter', { static: true }) categoryFilter: ElementRef;
  @ViewChild('n1qlFilter', { static: true }) n1qlFilter: ElementRef;
  @ViewChild('catListGrid') table: Table;

  // Store selectors
  @Select(ChargeCatSelectors.allCategories) categories$: Observable<Category[]>;
  @Select(ChargeCatSelectors.categoriesWithRules) categoriesWithRules$: Observable<Category[]>;
  @Select(ChargeCatSelectors.selectedCategory) selectedCategory$: Observable<Category>;
  @SelectSnapshot(ChargeCatSelectors.selectedCategory) selectedCategorySnapshot: Category;
  @SelectSnapshot(ChargeCatSelectors.isN1QLEditorDirty) isN1QLEditorDirtySnapshot: boolean;

  isLoadingCategories$: Observable<boolean> = this.loadingStateService.getLoadingCategories$();

  // Filter type choices
  filterTypes: Array<{ value: FilterTypes; label: string; command: any }> = [
    {
      value: FilterTypes.CategorySearch,
      label: 'Search Categories',
      command: () => {
        this.onFilterTypeSelect(FilterTypes.CategorySearch);
      }
    },
    {
      value: FilterTypes.N1QLSearch,
      label: 'Search N1QL',
      command: () => {
        this.onFilterTypeSelect(FilterTypes.N1QLSearch);
      }
    }
  ];

  categoryToDelete: Category;
  clipboardOptions = ClipboardOptions;
  isFiltered: boolean;
  filterText = '';
  filterBy = FilterTypes.CategorySearch;
  subs = new SubSink();

  // This allows the enum to be referenced in the template
  FilterTypes = FilterTypes;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    private facade: ChargeCatStoreFacade,
    private loadingStateService: LoadingStateService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.facade.fetchCategories();
    this.selectCategoryAfterAdding();
  }

  /**
   * Clears out all categories and selections the re-fetches the list.
   */
  refreshCategoryList() {
    const refresh = true;
    this.clearCategories();
    this.clearSelectedCategory();
    this.facade.fetchCategories(refresh);
  }

  /**
   * Clears out the list of categories from the store.
   */
  @Dispatch()
  clearCategories = () => new Actions.ClearCategories();

  /**
   * Clears the selected category from the store.
   */
  @Dispatch()
  clearSelectedCategory = () => new Actions.ClearSelectedCategory();

  /**
   * Adds a new category.
   * @param category Category to add
   */
  add(category: Category) {
    this.facade.addCategory(category);
  }

  /**
   * Deletes an existing category.
   * @description When the delete button for a category is clicked, that category
   * is then flagged for deletion, but not yet deleted. Once the user confirms the
   * deletion in the confirmation modal, the flagged category is then sent to be deleted.
   */
  delete() {
    this.facade.deleteCategory(this.categoryToDelete.chargeCatId);
  }

  /**
   * Handles selecting a category in the list.
   *
   * @description If the N1QL editor has a pending change then
   * prevent the selection of another category in the list and
   * show a notification indicating this.  Otherwise, allow the selection
   * of another category.
   *
   * @param event The rowSelectionEventArgs of the selected row
   */
  onRowSelectionChange(event: any) {
    if (this.isN1QLEditorDirtySnapshot) {
      event.cancel = true; // prevents selection in the grid
      this.notificationService.notify(messages.warning.pendingN1QLChanges, NotificationType.Warning);
    } else {
      this.select(event.data.docId);
    }
  }

  /**
   * Handles the delete button for individual categories and
   * triggers the confirmation modal when deleting a category.
   *
   * @param event Used to prevent selecting the category when clicking the delete button
   * @param rowData Used to gather rowData
   */
  deleteConfirm(event: MouseEvent, rowData: Category | any) {
    event.stopPropagation();
    this.categoryToDelete = rowData;
    this.openDeleteModal();
  }

  /**
   * Updates the selected category's name and servicelines.
   *
   * @description Gets the latest snaphot of the selected category, updates
   * some properties then performs and update on it.
   */
  editCategory(data: any) {
    const selectedCategory: Category = { ...this.selectedCategorySnapshot };
    selectedCategory.name = data.name;
    selectedCategory.description = data.description;
    selectedCategory.isDrgV = data.isDrgV || false;
    selectedCategory.isChargeCapture = data.isChargeCapture || false;
    selectedCategory.resolverDays = data.resolverDays;
    selectedCategory.versionEditLog = data.versionEditLog;
    this.facade.editCategory(selectedCategory);
  }

  /**
   * Handler for the dropdown used for choosing what type of search filter to use.
   * @param selection The selected filter choice
   */
  onFilterTypeSelect(selection: FilterTypes) {
    this.filterBy = selection;

    // Set focus to the input once the selection has been made
    setTimeout(() => this.setFilterInputFocus());
  }

  /**
   * Sets the cursor focus to the search filter input of the type that was chosen.
   * @description There are two filter inputs in the template, but only one is shown at a time.
   * @example If option one is selected, then the input for option one will display and the focus will be set.
   */
  setFilterInputFocus() {
    this.filterBy === FilterTypes.CategorySearch
      ? document.getElementById('categoryFilter').focus()
      : document.getElementById('n1qlFilter').focus();
  }

  /**
   * Filters the list by category name using the user entered text.
   *
   * @description Performs the filter using 'contains'.
   * @param term User entered search term
   */
  filterByCategory(term: string) {
    this.isFiltered = term !== '';
    this.table.filter(term, 'name', 'contains');
  }

  /**
   * Filters the list by N1QL statement using the user entered text.
   * @description Performs the filter using 'contains'.
   * @param term User entered search term
   */
  filterByN1QL(term: string) {
    this.isFiltered = term !== '';
    this.table.filter(term, 'chargeDescriptionSelectorN1QL', 'contains');
  }

  /**
   * Resets the filter by restoring the full list of categories and clearing
   * the search textbox. It also hides the clear filter icon.
   */
  clearFilter() {
    this.categoryGrid.clearFilter();
    this.isFiltered = false;
    this.filterText = '';
  }

  /**
   * Handler for the add category modal.
   *
   * @description A blank object is passed in which is used by the modal to
   * hold the new data. Once the user clicks 'add', that data is returned
   * and the call to add the new category is made.
   */
  openAddModal() {
    const dialogRef = this.dialog.open(AddCategoryModalComponent, {
      width: '50%',
      autoFocus: true,
      data: {
        name: '',
        isDrgV: false,
        isChargeCapture: false
      }
    });
    // Handle the user submitting info in the modal
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.add(data);
      }
    });
  }

  /**
   * Handler for the edit category modal.
   *
   * @descrption When clicking the edit button, it opens the modal and selects
   * the category. Data used in the modal is passed in and the updated
   * name is returned.
   *
   * @param rowData Data in the cell of the selected category
   */
  openEditModal(rowData: Category) {
    const category = rowData;
    this.select(category.docId);
    const dialogRef = this.dialog.open(EditCategoryModalComponent, {
      width: '50%',
      data: {
        name: category.name,
        description: category.description,
        categoryRules: category.rules,
        hasRules: rowData.rules.length > 0,
        isDrgV: category.isDrgV,
        isChargeCapture: category.isChargeCapture,
        resolverDays: category.resolverDays,
        versionEditLog: null
      }
    });
    // Handle the user submitting info in the modal
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.editCategory(data);
      }
    });
  }

  /**
   * Handler for delete category confirmation modal.
   *
   * @description When the delete button for a category is clicked
   * the modal opens and a message is passed into it.  If the user clicks
   * 'delete' in the modal, that information is returned and the call to
   * delete the category is made.
   */
  openDeleteModal() {
    const dialogRef = this.dialog.open(DeleteCategoryModalComponent, {
      width: '50%',
      data: {
        message: `Are you sure you want to delete ${this.categoryToDelete.name}?`
      }
    });
    // Handle the user submitting info in the modal
    this.subs.sink = dialogRef.afterClosed().subscribe(deleteCategory => {
      if (deleteCategory) {
        this.delete();
      }
    });
  }

  showRules(rowData: Category) {
    let temp: string = ``;
    rowData.rules.forEach(rule => {
      temp += `<li>${rule}</li>`;
    });

    return `<ul class='popover-rule-list'>${temp}</ul>`;
  }

  /**
   * Sets the selected category
   * @param docId The docId of the category to select.
   */
  private select(docId: string) {
    this.facade.selectCategory(docId).then(() => {
      const { chargeCatId } = this.facade.getCategoryDictionary()[docId];
      if (chargeCatId) {
        this.facade.fetchSelectedCategoryVersions(chargeCatId);
      }
    });
  }

  /**
   * Select the newly added category.
   *
   * @description When a category is added an action gets dispatched (in the facade) to set it
   * as the selected category in the store. This method subscribes to that event
   * and calls select() which will make the call to the server to retrieve the rest of the
   * category data.
   */
  private selectCategoryAfterAdding() {
    this.subs.sink = this.selectedCategory$.subscribe(selectedCategory => {
      if (this.categoryGrid && Utils.hasValue(selectedCategory.docId)) {
        this.highlightNewCategory(selectedCategory);
      }
    });
  }

  /**
   * This fixes an issue where filtered items don't display unless the height of the grid is adjusted
   */
  private adjustGridHeight() {
    this.categoryGrid.calcHeight = this.categoryGrid.calcHeight + 5;
  }

  /**
   * Highlights the newly added category in the grid.
   */
  private highlightNewCategory(selectedCategory: Category) {
    this.categoryGrid.selectRows([selectedCategory.docId], true);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
