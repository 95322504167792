<h1 mat-dialog-title>Edit Category Name</h1>
<form #newCategoryForm="ngForm" novalidate>
  <div mat-dialog-content class="overflow-hidden">
    <div *ngIf="data.hasRules">
      <span>The following rules are currently associated with {{ data.name }}:</span>
      <ul class="mt-3 rule-list">
        <li *ngFor="let rule of data.categoryRules">{{ rule }}</li>
      </ul>
      If you would still like to update the category name, click <span> rename</span>.
    </div>
    <hr />
    <button class="btn btn-sm btn-info mb-3" *ngIf="data.hasRules" (click)="enableEditInput()">Rename</button>
    <div class="form-group">
      <label for="name" *ngIf="!data.hasRules">Name:</label>
      <input
        type="text"
        class="form-control form-control-sm"
        #categoryName
        name="name"
        [(ngModel)]="data.name"
        required
        (input)="isDirty = true"
        [disabled]="disableEditing"
      />
    </div>
    <div class="form-group">
      <label for="description">Description:</label>
      <textarea
        class="form-control"
        name="description"
        id="description"
        rows="3"
        (input)="isDirty = true"
        [(ngModel)]="data.description"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="verNotes">Version Notes:</label>
      <textarea
        class="form-control"
        name="verNotes"
        id="verNotes"
        rows="2"
        (input)="isDirty = true"
        [(ngModel)]="data.versionEditLog"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="timeDesignation">Time Designation:</label>
      <input
        type="number"
        class="form-control form-control-sm"
        #categoryName
        (input)="isDirty = true"
        name="timeDesignation"
        [(ngModel)]="data.resolverDays"
      />
    </div>
    <h1 mat-dialog-title class="mb-1 mt-4">Service Lines</h1>
    <div class="sl-check-box">
      <p-checkbox
        name="isDrgV"
        [binary]="true"
        [(ngModel)]="data.isDrgV"
        (onChange)="onServiceLineCheck($event.checked, 'isDrgV')"
      >
      </p-checkbox>
      <label class="sl-check-box-label" for="isDrgV">DrgV</label>
    </div>
    <div class="sl-check-box">
      <p-checkbox
        name="isChargeCapture"
        [binary]="true"
        [(ngModel)]="data.isChargeCapture"
        (onChange)="onServiceLineCheck($event.checked, 'isChargeCapture')"
      >
      </p-checkbox>
      <label class="sl-check-box-label" for="isChargeCapture">Charge Capture</label>
    </div>
    <div *ngIf="!this.data.isDrgV && !this.data.isChargeCapture" class="mt-2 error-text">
      At least one service line must be selected
    </div>
  </div>
  <div mat-dialog-actions class="float-right">
    <button type="button" class="btn btn-sm btn-secondary mr-2" (click)="cancel()">Cancel</button>
    <button type="submit" class="btn btn-sm btn-primary" (click)="updateName()" [disabled]="!formIsValid() || !isDirty">
      Update
    </button>
  </div>
</form>
