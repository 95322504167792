import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-charge-description-grid',
  templateUrl: './charge-description-grid.component.html',
  styleUrls: ['./charge-description-grid.component.scss']
})
export class ChargeDescriptionGridComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
