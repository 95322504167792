import { Injectable, OnDestroy } from '@angular/core';
import {
  ApplicationInsights,
  IEventTelemetry,
  IExceptionTelemetry,
  ITraceTelemetry
} from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {
  subs = new SubSink();

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: environment.config.APPINSIGHTS_INSTRUMENTATIONKEY,
      namePrefix: 'ChargeCatV2 : ANGULAR',
      loggingLevelTelemetry: 2
    }
  });

  constructor(private router: Router) {
    this.appInsights.loadAppInsights();
    this.subs.sink = this.router.events
      .pipe(filter(event => event instanceof ResolveEnd))
      .subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
          this.trackPageView(
            `${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`,
            event.urlAfterRedirects
          );
        }
      });
  }

  setUserId(userId: string) {
    this.appInsights.setAuthenticatedUserContext(userId);
  }

  clearUserId() {
    this.appInsights.clearAuthenticatedUserContext();
  }

  trackPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({ name, uri });
  }

  trackException(exception: IExceptionTelemetry) {
    this.appInsights.trackException(exception);
  }

  trackTrace(trace: ITraceTelemetry, customProperties?: any) {
    this.appInsights.trackTrace(trace, customProperties);
  }

  trackEvent(event: IEventTelemetry, customProperties?: any) {
    this.appInsights.trackEvent(event, customProperties);
  }

  private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
      return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
      path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
      return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
