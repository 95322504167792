export const environment = {
  appVersion: '2023-04-03-v.75533',
  production: false,
  config: {
    APPINSIGHTS_INSTRUMENTATIONKEY: '04b2e1a8-e847-4bcc-8115-f0f187b0dc6c',
    dataApiUrl: 'https://api.dev.onerevint.com/', // data api
    functionAppUrl: 'https://riedevassurancescalafunctionapp.azurewebsites.net',
    functionAppKey: 'EOtnQ3iIP4pkU0naSQt3Y9abp03TMNHqG5USHa8PbLqZvw9RNHpRog==',
    dataApiVersion: 'v1.13',
    settingsUrl: 'https://dev.onerevint.com/wd/coded_ui/settings',
    auth0: {
      domain: 'auth.dev.cloudmed.com',
      clientId: 'Gr85So1hLY6OZ9K40DrHSZpH4dp7WIcn',
      audience: 'https://api.dev.onerevint.com/',
    }
  }
};
