<mat-sidenav-container class="wrapper">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    class="sidenav"
    [fixedInViewport]="sidenavOptions.fixed"
    [fixedTopGap]="sidenavOptions.top"
    [fixedBottomGap]="sidenavOptions.bottom"
  >
    <ng-content select=".sidebar-list"></ng-content>
    <button
      class="float-right close-sidenav-btn"
      pTooltip="Hide category list"
      tooltipPosition="top"
      (click)="sidenav.toggle()"
      *ngIf="sidenav.opened"
    >
      <i class="fa fa-play rotate-180" aria-hidden="true"></i>
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="card card-body query-container">
      <ng-content select=".main-content"></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<button
  class="show-sidenav-btn"
  pTooltip="Show category list"
  tooltipPosition="right"
  (click)="sidenav.toggle()"
  *ngIf="!sidenav.opened"
>
  <i class="fa fa-play" aria-hidden="true"></i>
</button>
