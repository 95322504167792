import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Select } from '@ngxs/store';
import { forkJoin, Observable } from 'rxjs';
import { SubSink } from 'subsink';

import { UserStoreFacade } from '../../../_core/store/user/user.store.facade';
import { Category } from 'src/app/_shared/models/category';
import { EventBusService } from '../../services/event-bus.service';
import { ChargeCatSelectors } from '../../store/app/app.selectors';
import { ChargeCatStoreFacade } from '../../store/charge-cat-store.facade';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { RevertConfirmationModalComponent } from '../revert-confirmation-modal/revert-confirmation-modal.component';
import { UserProfileReduced } from 'src/app/_shared/models/user-profile-reduced';
import { ChargeDescriptionListEdit } from '../../shared/models';
import { NotificationService } from 'src/app/_core/services/notification.service';
import { NotificationType } from '../../shared/enums';
import { SelectItem } from 'primeng/api';
import clonedeep from 'lodash.clonedeep';
import { CptMappingModalComponent } from '../cpt-mapping-modal/cpt-mapping-modal.component';
import { filter, switchMap } from 'rxjs/operators';
import { Utils } from 'src/app/_shared/utils';

@Component({
  selector: 'cm-category-query',
  templateUrl: './category-query.component.html',
  styleUrls: ['./category-query.component.scss']
})
export class CategoryQueryComponent implements OnDestroy, OnInit {
  @ViewChild('editor', { static: false }) editor: ElementRef;
  showHoverDescription = false;
  modifiedByUser: UserProfileReduced;

  @Select(ChargeCatSelectors.selectedCategory) selectedCategory$: Observable<Category>;
  @Select(ChargeCatSelectors.getTempCategory) tempCategory$: Observable<Category>;

  includesIds: string[] = [];
  excludesIds: string[] = [];
  disabledSaveN1QL = false;
  selectedCategory = new Category('', '');
  selectedVersion = null;
  tempCategory = null;
  versionSelected = {};
  subs = new SubSink();
  lastVersions: string;
  versions: SelectItem[];
  versionsModifiedByDictionary = {};

  constructor(
    public dialog: MatDialog,
    private eventBus: EventBusService,
    private facade: ChargeCatStoreFacade,
    private userFacade: UserStoreFacade,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.subs.sink = this.tempCategory$.subscribe(category => {
      this.tempCategory = category;
    });
    this.subs.sink = this.selectedCategory$
      .pipe(
        switchMap(category => {
          this.selectedCategory = category;
          this.fetchUserByUserId();
          this.setIncludesAndExcludes(category);
          this.checkForManualExceptions(category);
          return this.eventBus.categoryVersions$();
        })
      )
      .subscribe(versions => {
        //This check is to determine if the user is clicking on a version and whether or not the
        //versions dropdown needs to be recreated.
        if (this.lastVersions !== JSON.stringify(versions)) {
          this.handleCategoryVersions(versions);
        }
        this.lastVersions = JSON.stringify(versions);
      });

    this.subs.sink = this.eventBus
      .usersModifiedByVersionDictionary$()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.facade.setManualIncludes();
        this.facade.setManualExcludes();
      });
    this.subs.sink = this.eventBus.savingN1QL$().subscribe(saving => (this.disabledSaveN1QL = saving));
  }

  private handleCategoryVersions(versions: Category[]) {
    if (versions && versions.length) {
      let versionsModfiedByRequests = this.createVersionsArray(versions);
      this.getUserProfilesForCategoryVersions(versionsModfiedByRequests);
    } else {
      this.handleNoVersionsOnCat();
    }
  }

  private handleNoVersionsOnCat() {
    this.versions = [];
    this.versions.push({ label: 'Latest', value: { category: this.selectedCategory, isLatest: true } });
    if (Utils.hasValue(this.selectedCategory.modifiedBy)) {
      this.userFacade.fetchUserByUserId(this.selectedCategory.modifiedBy).subscribe((up: UserProfileReduced) => {
        this.addUserProfileToModifiedByDictionary(up);
      });
    }
  }

  private addUserProfileToModifiedByDictionary(up: UserProfileReduced) {
    if (up && !this.versionsModifiedByDictionary.hasOwnProperty(up.userId)) {
      this.versionsModifiedByDictionary[up.userId] = up;
      this.eventBus.setUsersModifiedByVersionDictionary(this.versionsModifiedByDictionary);
    }
  }

  private createVersionsArray(versions: Category[]) {
    this.versions = [];
    const cat = clonedeep(this.selectedCategory);
    this.versions.push({ label: 'Latest', value: { category: cat, isLatest: true } });
    let versionsModfiedByRequests = [];
    let versionModfiedByIds = [];
    versions.forEach((version, i) => {
      //sometimes the elastic versions index contains the current version and this if statement will prevent dupes.
      if (JSON.stringify(cat) == JSON.stringify(version)) {
        return;
      }
      let label = versions.length - i;
      this.versions.push({ label: label.toString(), value: { category: version, isLatest: false } });
      this.createVersionModfiedByReqs(versionModfiedByIds, version, versionsModfiedByRequests);
    });
    return versionsModfiedByRequests;
  }

  private getUserProfilesForCategoryVersions(versionsModfiedByRequests: Observable<UserProfileReduced>[]) {
    if (this.selectedCategory.modifiedBy) {
      versionsModfiedByRequests.push(this.userFacade.fetchUserByUserId(this.selectedCategory.modifiedBy));
    }
    return forkJoin(versionsModfiedByRequests)
      .pipe(filter(profile => !!profile))
      .subscribe((userProfiles: UserProfileReduced[]) => {
        if (userProfiles && userProfiles.length) {
          userProfiles.forEach(up => {
            this.addUserProfileToModifiedByDictionary(up);
          });
          this.eventBus.setUsersModifiedByVersionDictionary(this.versionsModifiedByDictionary);
        }
      });
  }

  private createVersionModfiedByReqs(versionModfiedByIds: any[], version: Category, versionsModfiedByRequests: any[]) {
    if (version.modifiedBy && !versionModfiedByIds.includes(version.modifiedBy)) {
      versionModfiedByIds.push(version.modifiedBy);
      versionsModfiedByRequests.push(this.userFacade.fetchUserByUserId(version.modifiedBy));
    }
  }

  setSelectedVersion() {
    this.facade.selectCategoryVersion(this.selectedVersion);
  }

  fetchUserByUserId() {
    const { modifiedBy } = this.selectedCategory;
    if (modifiedBy !== '') {
      this.userFacade.fetchUserByUserId(modifiedBy).subscribe((result: UserProfileReduced) => {
        this.modifiedByUser = result;
      });
    }
  }

  refreshCategory() {
    if (this.selectedCategory.docId) {
      this.facade.refreshCategory(this.selectedCategory.docId).then(() => {
        this.facade.fetchSelectedCategoryVersions(this.selectedCategory.chargeCatId);
      });
    }
  }

  hasIncludes() {
    return this.includesIds.length > 0;
  }

  hasExcludes() {
    return this.excludesIds.length > 0;
  }

  toggleTopSlider() {
    this.eventBus.toggleTopSlider();
  }

  sortN1QLCodes() {
    this.eventBus.sortN1QLCodes();
  }

  formatN1QL() {
    this.eventBus.formatN1QL();
  }

  /**
   * Handler for the revert button.
   *
   * @description It notifies the event bus which tiggers the actual
   * revert logic in the N1QL editor
   */
  revert() {
    this.eventBus.revertN1QLStatement();
  }

  setIncludesAndExcludes(category: Category) {
    this.includesIds = category.chargeDescriptions || [];
    this.excludesIds = category.chargeDescriptionsExclusions || [];
  }

  checkForManualExceptions(category: Category) {
    this.fetchManualIncludes(category);
    this.fetchManualExcludes(category);
  }

  fetchManualIncludes(category: Category) {
    this.facade.fetchManualIncludes(category);
  }

  fetchManualExcludes(category: Category) {
    this.facade.fetchManualExcludes(category);
  }

  removeRow(event) {
    if (
      this.selectedVersion &&
      this.versions[0].value &&
      this.selectedVersion.lastModified !== this.versions[0].value.lastModified
    ) {
      this.notificationService.notify(
        'Please select the latest version to remove manual includes and excludes.',
        NotificationType.Info
      );
      return;
    }
    let chargeDescriptionsEdit: ChargeDescriptionListEdit = {
      chargeDescriptionIds: [event.docId],
      categoryIdsForEdit: [this.selectedCategory.docId],
      relationshipType: event.gridId,
      modifiedBy: null
    };
    event.gridId === 'inclusion'
      ? this.facade.deleteManualInclude(chargeDescriptionsEdit)
      : this.facade.deleteManualExclude(chargeDescriptionsEdit);
  }

  hasSelectedCategory(): boolean {
    return this.selectedCategory && this.selectedCategory.docId !== '';
  }

  categoryHasN1QL(): boolean {
    if (this.selectedCategory && this.tempCategory) {
      return (
        this.tempCategory.chargeDescriptionSelectorN1QL !== null &&
        this.tempCategory.chargeDescriptionSelectorN1QL.trim() !== ''
      );
    }
  }

  openPreviewModal() {
    let isJobRunningForSelectedCat = this.checkIfJobRunningForCurrentCat();
    if (isJobRunningForSelectedCat) {
      this.notificationService.notify(
        'Cannot preview a category that has a job currently running. Please wait until the job has been completed.',
        NotificationType.Info
      );
      return;
    }
    if (this.selectedVersion && !this.selectedVersion.isLatest) {
      this.notificationService.notify(
        'Saving this older version of the category will overwrite the latest version.',
        NotificationType.Warning
      );
    }
    const dialogRef = this.dialog.open(PreviewModalComponent, {
      disableClose: true,
      width: '80%',
      data: {},
      id: 'preview-grid-container'
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {});
  }

  openCptMappingModal() {
    const dialogRef = this.dialog.open(CptMappingModalComponent, {
      disableClose: true,
      width: '875px',
      data: this.tempCategory ? this.tempCategory : this.selectedCategory
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {});
  }

  openRevertConfirmationModal() {
    const dialogRef = this.dialog.open(RevertConfirmationModalComponent, {
      width: '30%'
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.revert();
      }
    });
  }

  private checkIfJobRunningForCurrentCat() {
    let result = false;
    const jobs = this.facade.jobsSnapshot();
    jobs.forEach(job => {
      if (job.category.chargeCatId == this.selectedCategory.chargeCatId) {
        result = true;
      }
    });
    return result;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
