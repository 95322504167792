import { FunctionAppJob } from './function-app-job-state.model';

export class FetchFunctionAppJobs {
  static readonly type = 'Fetch Jobs';
  constructor() {}
}

export class AddJob {
  static readonly type = 'Add Job';
  constructor(public job: FunctionAppJob) {}
}

export class DeleteJob {
  static readonly type = 'Delete Job';
  constructor(public jobId: string) {}
}

export class EditJob {
  static readonly type = 'Edit Job';
  constructor(public job: FunctionAppJob) {}
}
