<div class="container">
  <div mat-dialog-content class="overflow-hidden modal-container-height">
    <div class="row">
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            CPTs to add to Category
            <button type="button" class="btn btn-sm btn-success pull-right" (click)="add()">Add</button>
          </div>
          <div class="card-body no-padding">
            <div class="form-group mb-0">
              <textarea
                class="form-control cpt-text-area"
                placeholder="Enter or paste in CPT values to add to category"
                [(ngModel)]="cptTextArea"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            CPTs in Category
            <input
              pInputText
              type="text"
              [(ngModel)]="filterText"
              (input)="searchCptList()"
              placeholder="Search"
              class="p-column-filter pull-right"
            />
          </div>
          <div class="card-body no-padding">
            <p-table
              #cpttable
              [value]="filteredCptList ? filteredCptList : tempCategory.cptList"
              [scrollable]="true"
              scrollHeight="300px"
              scrollDirection="both"
            >
              <ng-template pTemplate="body" let-cpt class="overflow-auto">
                <tr>
                  <td>
                    {{ cpt }}
                    <button
                      pTooltip="Remove"
                      tooltipPosition="bottom"
                      (click)="removeCpt(cpt)"
                      class="btn btn-sm btn-light pull-right"
                    >
                      <i class="pi pi-times"></i>
                    </button>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="d-flex align-items-center justify-content-between cpt-actions">
      <div class="text-danger invalid-cpt-warning">
        <span *ngIf="invalidCptsViewValue">Invalid CPTs: {{ invalidCptsViewValue }}</span>
      </div>
      <button type="button" class="btn btn-sm btn-secondary mr-2 ml-2" (click)="cancel()">Cancel</button>
      <button type="submit" class="btn btn-sm btn-success" (click)="addToCategory()">Add to Category</button>
    </div>
  </div>
</div>
