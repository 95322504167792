import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { IdleEventBusService } from '../idle-event-bus.service';

@Component({
  selector: 'cm-idle-timeout-modal',
  templateUrl: './idle-timeout-modal.component.html',
  styleUrls: ['./idle-timeout-modal.component.scss']
})
export class IdleTimeoutModalComponent implements OnInit {
  countdown$: Observable<number>;

  constructor(
    public dialogRef: MatDialogRef<IdleTimeoutModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { logout: boolean },
    private eventBusService: IdleEventBusService
  ) {}

  ngOnInit() {
    this.countdown$ = this.eventBusService.idleCountDown$();
  }

  logout() {
    this.data.logout = true;
    this.dialogRef.close(this.data);
  }
}
