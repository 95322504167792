// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// 3rd Party
import { NgxSpinnerModule } from 'ngx-spinner';
import { MonacoEditorModule } from 'ngx-monaco-editor';

// App Modules
import { AppMaterialModule } from 'src/app/app-material.module';

// App Components
import { AddCategoryModalComponent } from './add-category-modal/add-category-modal.component';
import { CategoryDefinitionComponent } from './category-definition.component';
import { CategoryDeltaGridComponent } from './category-delta-grid/category-delta-grid.component';
import { CategoryQueryComponent } from './category-query/category-query.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { DeleteCategoryModalComponent } from './delete-category-modal/delete-category-modal.component';
import { EditCategoryModalComponent } from './edit-category-modal/edit-category-modal.component';
import { N1QLEditorComponent } from './n1ql-editor/n1ql-editor.component';
import { ManualExceptionsComponent } from './manual-exceptions/manual-exceptions.component';
import { ManualExceptionsGridComponent } from './manual-exceptions-grid/manual-exceptions-grid.component';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';
import { SharedModule } from 'src/app/_shared/shared.module';
import { RevertConfirmationModalComponent } from './revert-confirmation-modal/revert-confirmation-modal.component';
import { CanDeactivateGuard } from 'src/app/_core/guards/can-deactivate.guard';
import { SideCollapseComponent } from './side-collapse/side-collapse.component';
import { PrimeNgModule } from 'src/app/primeng.module';
import { CdmTableModule } from '../cdm-table/cdm-table.module';
import { DropdownModule } from 'primeng/dropdown';
import { CptMappingModalComponent } from './cpt-mapping-modal/cpt-mapping-modal.component';

@NgModule({
  declarations: [
    AddCategoryModalComponent,
    CategoryDefinitionComponent,
    CategoryListComponent,
    CategoryQueryComponent,
    DeleteCategoryModalComponent,
    N1QLEditorComponent,
    PreviewModalComponent,
    ManualExceptionsComponent,
    EditCategoryModalComponent,
    ManualExceptionsGridComponent,
    CategoryDeltaGridComponent,
    RevertConfirmationModalComponent,
    SideCollapseComponent,
    CptMappingModalComponent
  ],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    MonacoEditorModule.forRoot(),
    NgxSpinnerModule,
    SharedModule,
    PrimeNgModule,
    CdmTableModule,
    DropdownModule
  ],
  providers: [CanDeactivateGuard],
  exports: [CategoryDefinitionComponent, N1QLEditorComponent]
})
export class CategoryDefinitionModule {}
