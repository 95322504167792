import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { distinctUntilChanged } from 'rxjs/operators';
import { SessionIdleService } from './_core/services/session-idle/session-idle.service';
import { UserStoreFacade } from './_core/store/user/user.store.facade';

@Component({
  selector: 'cm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isUserAuthenticated = false;

  constructor(
    private readonly authService: AuthService,
    private readonly facade: UserStoreFacade,
    private readonly sessionIdleService: SessionIdleService
  ) {}

  ngOnInit() {
    this.authService.user$.pipe(distinctUntilChanged()).subscribe(user => {
      if (user !== null) {
        this.isUserAuthenticated = true;
        this.sessionIdleService.startSessionIdle();
        this.facade.fetchUserInfo();
      }
    });

    console.log('Version:', environment.appVersion);
  }
}
