import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingStateService {
  /*-------------------------------------------
   *          Categories
   *------------------------------------------*/
  private loadingCategoriesSubject = new BehaviorSubject<boolean>(false);
  getLoadingCategories$() {
    return this.loadingCategoriesSubject.asObservable();
  }

  setLoadingCategories(isLoading: boolean) {
    this.loadingCategoriesSubject.next(isLoading);
  }

  /*-------------------------------------------
   *           Category Delta
   *------------------------------------------*/
  private loadingCategoryDeltaSubject = new BehaviorSubject<boolean>(false);
  getLoadingCategoryDelta$() {
    return this.loadingCategoryDeltaSubject.asObservable();
  }

  getLoadingCategoryDeltaValue() {
    return this.loadingCategoryDeltaSubject.getValue();
  }

  setLoadingCategoryDelta(isLoading: boolean) {
    this.loadingCategoryDeltaSubject.next(isLoading);
  }

  private savingCategoryDeltaSubject = new BehaviorSubject<boolean>(false);
  isSavingCategoryDelta$() {
    return this.savingCategoryDeltaSubject.asObservable();
  }

  setIsSavingCategoryDelta(isSaving: boolean) {
    this.savingCategoryDeltaSubject.next(isSaving);
  }
}
