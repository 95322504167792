import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { FetchUserInfo } from './user.actions';
import { UserInfo } from 'src/app/_shared/models/userInfo';

export class UserStateModel {
  userInfo: UserInfo;
}

@State<UserStateModel>({
  name: 'UserState',
  defaults: {
    userInfo: null
  }
})
@Injectable()
export class UserState {
  constructor() {}

  //
  // SELECTORS
  //

  @Selector()
  static userInfo(state: UserStateModel): UserInfo {
    return state.userInfo;
  }

  //
  // ACTIONS
  //

  @Action(FetchUserInfo)
  fetchUserInfo({ patchState }: StateContext<UserStateModel>, { userInfo }: FetchUserInfo) {
    patchState({ userInfo });
  }
}
