<h1 mat-dialog-title>Session Idle Timeout</h1>

<div mat-dialog-content>You will be logged out in {{ countdown$ | async }} seconds due to inactivity.</div>

<div mat-dialog-actions class="float-right">
  <button type="button" class="btn btn-sm btn-secondary mr-2" mat-dialog-close>Stay Connected</button>
  <button type="submit" class="btn btn-sm btn-danger" (click)="logout()">
    Logout
  </button>
</div>
