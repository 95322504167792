import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Form } from '@angular/forms';

@Component({
  selector: 'cm-edit-category-modal',
  templateUrl: './edit-category-modal.component.html',
  styleUrls: ['./edit-category-modal.component.scss']
})
export class EditCategoryModalComponent {
  @ViewChild('newCategoryForm', { static: false }) newCategoryForm: Form;
  disableEditing = false;
  isDirty = false;

  constructor(public dialogRef: MatDialogRef<EditCategoryModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    if (data.hasRules) {
      this.disableEditing = true;
    }
  }

  cancel(): void {
    this.data.name = '';
    this.dialogRef.close();
  }

  onServiceLineCheck(isChecked: boolean, serviceLine: any) {
    this.data[serviceLine] = isChecked;
    this.isDirty = true;
  }

  formIsValid(): boolean {
    return this.data.name.trim() !== '' && (this.data.isDrgV || this.data.isChargeCapture);
  }

  enableEditInput() {
    this.disableEditing = false;
  }

  updateName() {
    this.data.name = this.data.name.trim();
    this.dialogRef.close(this.data);
  }
}
