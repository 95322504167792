import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { EditorComponent } from 'ngx-monaco-editor';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Category } from 'src/app/_shared/models/category';
import { SubSink } from 'subsink';

import { EventBusService } from '../../services/event-bus.service';
import { EDITOR_OPTIONS } from './n1ql-editor-options';
import { ChargeCatSelectors } from '../../store/app/app.selectors';
import * as Actions from '../../store/app/app.actions';
import { N1qlFormatterService } from '../../services/n1ql-formatter.service';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { UserState } from 'src/app/_core/store/user/user.state';
import { UserInfo } from 'src/app/_shared/models/userInfo';
import { Utils } from 'src/app/_shared/utils';

@Component({
  selector: 'cm-n1ql-editor',
  templateUrl: './n1ql-editor.component.html',
  styleUrls: ['./n1ql-editor.component.scss']
})
export class N1QLEditorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedCategory: Category;
  @ViewChild('n1qlEditor', { static: false }) n1qlEditor: EditorComponent;

  @Select(ChargeCatSelectors.getTempCategory) tempCategory$: Observable<Category>;
  @SelectSnapshot(UserState.userInfo) userInfo: UserInfo;

  /**
   * Subject used for N1QL statement debouncing
   */
  updateN1QLSubject: Subject<string> = new Subject<string>();

  editorOptions = EDITOR_OPTIONS;
  originalCategory: Category;
  tempCategory: Category;
  displayN1QLViewForView = '';
  subs = new SubSink();

  constructor(private eventBus: EventBusService, private n1qlFormatter: N1qlFormatterService) {}

  ngOnInit() {
    // revert subscription
    this.subs.sink = this.eventBus.revertN1QLStatement$().subscribe(() => {
      if (this.selectedCategory) {
        this.revert();
      }
    });

    // Sort N1QL codes subscription
    this.subs.sink = this.eventBus.sortN1QLCodes$().subscribe(() => {
      this.sortN1QLCodes();
    });

    // Format N1QL subscription
    this.subs.sink = this.eventBus.formatN1QL$().subscribe(() => {
      const formattedN1QL = this.n1qlFormatter.formatN1QL(this.tempCategory.chargeDescriptionSelectorN1QL);
      this.updateTempCategory(formattedN1QL);
      this.tempCategory.chargeDescriptionSelectorN1QL = formattedN1QL;
    });

    // temp category subscription
    this.subs.sink = this.tempCategory$.subscribe(tempCategory => {
      if (tempCategory) {
        this.tempCategory = { ...tempCategory };
      }
    });
    this.setupTempCategoryDebounce();
  }

  ngOnChanges() {
    this.cloneSelectedCategory();
    this.setCPTdisplayValue();
  }

  /**
   * Triggers the debounce subject for updating the N1QL in the tempCategory
   * @param n1ql The user entered N1QL
   */
  updateTempCategory(n1ql: string) {
    this.updateN1QLSubject.next(n1ql);
  }

  /**
   * Sets the selected category back to its original state.
   */
  revert() {
    this.tempCategory = { ...this.originalCategory };
    this.clearTempCategory();
    this.n1qlEditorPendingChange(false);
    this.setCPTdisplayValue();
  }

  /**
   * Dispatches and action to clear the temporary category in the store.
   */
  @Dispatch()
  clearTempCategory = () => new Actions.ClearTempCategory();

  /**
   * Dispatches an action to set the boolean flag for the N1QL editor having pending changes
   */
  @Dispatch()
  n1qlEditorPendingChange = (pending: boolean) => new Actions.N1QLEditorPendingChanges(pending);

  @Dispatch()
  setTempCategoryForN1QLStatement = (tempCategory: Category) =>
    new Actions.SetTempCategoryForN1QLStatement(tempCategory);

  /**
   * Clones (immutably) the selected category to be used for setting it
   * back to its original state when performing a revert action and for
   * a temp object used for N1QL statement previewing.
   */
  cloneSelectedCategory() {
    this.originalCategory = { ...this.selectedCategory };
    this.tempCategory = { ...this.selectedCategory };
  }

  private setCPTdisplayValue() {
    if (this.tempCategory.chargeDescriptionSelectorN1QL) {
      const cptListStringToMatch = JSON.stringify(this.tempCategory.cptList).replace(/"/g, "'");
      while (this.tempCategory.chargeDescriptionSelectorN1QL.includes(cptListStringToMatch)) {
        this.tempCategory.chargeDescriptionSelectorN1QL = this.tempCategory.chargeDescriptionSelectorN1QL.replace(
          cptListStringToMatch,
          Utils.cptListKeyword
        );
      }
    }
  }

  /**
   * Sets up a subject for handling the debounceTime when typing in the N1QL editor.
   *
   * @description Once the set amount of time has elapsed, it will make a call to update
   * the tempCategory in the store with the updated N1QL as well as an editor dirty checking.
   * If there is no selected category (user is just typing the in the field on page load),
   * don't bother doing the dirty check when selecting a category.
   */
  private setupTempCategoryDebounce() {
    this.subs.sink = this.updateN1QLSubject.pipe(debounceTime(300), distinctUntilChanged()).subscribe(() => {
      if (this.selectedCategory.docId) {
        this.n1qlEditorPendingChange(this.pendingChanges());
        this.tempCategory.modifiedBy = this.userInfo.userId;
        this.setTempCategoryForN1QLStatement(this.tempCategory);
      }
    });
  }

  /**
   * Checks if there are any unsaved, pending changes made to the N1QL statement.
   *
   * @description The default value for a blank N1QL statement is null.  However, when typing into
   * a blank one and then backspacing or deleting the text out, it will change it
   * from null to an empty string. This is checking for an empty string and a null,
   * which should indicate not dirty.
   */
  private pendingChanges(): boolean {
    const { chargeDescriptionSelectorN1QL: tempN1QL } = this.tempCategory;
    const { chargeDescriptionSelectorN1QL: originalN1QL } = this.originalCategory;

    if (tempN1QL === '' && originalN1QL === null) {
      return false;
    }
    return JSON.stringify(tempN1QL) !== JSON.stringify(originalN1QL);
  }

  /**
   * Takes highlighted text, sorts and removes duplicates from it, then replaces
   * it with the result
   */
  sortN1QLCodes() {
    const { chargeDescriptionSelectorN1QL: n1ql } = this.tempCategory;
    const selectedText = window.getSelection().toString();

    if (selectedText !== '') {
      // Clone the N1QL for a later comparison
      const original = n1ql.slice();
      const formatted = this.prepareSelectedText(selectedText);
      const uniqueSorted = this.RemoveDuplicatesAndSort(formatted);

      // Update the model and replace the selected text
      this.tempCategory.chargeDescriptionSelectorN1QL = n1ql.replace(selectedText, uniqueSorted);

      // Update the category if the N1QL was modified
      if (JSON.stringify(n1ql) !== JSON.stringify(original)) {
        this.updateTempCategory(n1ql);
      }
    }
  }

  /**
   *  Trim, convert to array, uppercase, and remove double quotes from selected text
   */
  private prepareSelectedText(selectedText: string): string[] {
    return selectedText
      .replace(/["]/g, '')
      .split(',')
      .map(s => s.trim().toUpperCase());
  }

  private RemoveDuplicatesAndSort(formatted: string[]): string {
    return [...new Set(formatted)]
      .map(s => s.replace(/\s/g, ''))
      .sort()
      .toString();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
