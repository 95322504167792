import { ChargeCategorySource } from '../../charge-cat/shared/enums';

export class ChargeDescription {
  amaDescription = '';
  avgDollar = 0;
  avgQuant = 0;
  chargeCategoryIds: string[] = [];
  chargeCategoryLastModifieds: string[] = [];
  chargeCategorySources: ChargeCategorySource[] = [];
  chargeCode: string;
  chargeCount = 0;
  conflictingCategoryNames = '';
  cpt = '';
  createdDate = '';
  description = '';
  descriptionType = '';
  docId = '';
  hcpcs = '';
  lastModified = '';
  maxPostDate = '';
  metaId = '';
  paymentRate = '';
  revenueCode = '';
  si = '';
  tenantName = '';
  totalDollar = 0;
  totalQuant = 0;
  type = '';

  categoryNames?: string;
  modifiedBy? = '';
  tenantDisplayName? = '';
}

export class CdmTableCategoryField {
  name = '';
  docId = '';
}

export class CdmChargeDescription extends ChargeDescription {
  chargeCategoryNames: string[];
  chargeCategoryCommaList: string;
  // hcpcsCptAddons: HcpcsCptAddons;
  revenueCodeAddons: RevenueCodeAddons;
  tenantDisplayName: string;
  tokens: string[];
  tokenCommaList: string;
}

export interface RevenueCodeAddons {
  code: string;
  description: string;
  category: string;
}

export interface CdmTableData {
  cdmData: CdmChargeDescription[];
  offset: number;
}
