import { Selector } from '@ngxs/store';

import { FunctionAppJobState } from './function-app-job.state';
import { FunctionAppJobStateModel, FunctionAppJob } from './function-app-job-state.model';

export class FunctionAppJobSelectors {
  /**
   * @Selector Gets all jobs from the store.
   */
  @Selector([FunctionAppJobState])
  static allJobs(state: FunctionAppJobStateModel): FunctionAppJob[] {
    return state.functionAppJobs;
  }
}
