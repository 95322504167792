import { RuleType } from 'src/app/charge-cat/shared/enums';

export class Category {
  cas = 0;
  chargeCatId = '';
  chargeDescriptions: string[] = [];
  chargeDescriptionSelectorN1QL = '';
  chargeDescriptionSelectorType: RuleType;
  chargeDescriptionsExclusions: string[] = [];
  cptList: string[] = [];
  createdBy = '';
  createdDate = '';
  description = '';
  displayName? = '';
  docId = '';
  hcpcsCpt = [];
  isDrgV = false;
  isChargeCapture = false;
  lastModified = '';
  modifiedBy = '';
  name = '';
  resolverDays = 0;
  rules: string[] = [];
  schemaVer: '';
  type: '';
  versionEditLog: string = '';

  constructor(name: string, description: string) {
    this.name = name;
    this.description = description;
  }
}

export function createChargeCategory(name = '', description = '') {
  return new Category(name, description);
}
