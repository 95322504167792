import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleEventBusService {
  private idelTimeoutCountdownSubject = new BehaviorSubject<number>(null);

  constructor() {}

  idleCountdown(countdown: number) {
    this.idelTimeoutCountdownSubject.next(countdown);
  }

  idleCountDown$() {
    return this.idelTimeoutCountdownSubject.asObservable();
  }
}
