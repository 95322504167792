import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { Utils } from '../../utils';

@Component({
  selector: 'cm-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent implements OnChanges {
  /**
   * User object passed in to get initials, imgURL, and other metadata from.
   */
  @Input() user;

  /**
   * Size of the avatar.
   * @description Valid values: 'small' | 'large'
   */
  @Input() size: string;

  /**
   * Shows additional user information, such as name and title, next to the avatar.
   * @description This is only useful when applied to the avatar in the category-query
   * N1QL statement component, and should not be used with the header avatar.
   */
  @Input() showUserInfo = false;

  @Input() showUserTitle = true;

  /**
   * Property that holds the computed initials used in the template.
   */
  initials = '';

  /**
   * Background color of the avatar, based on the user's initials, when
   * there is no imgURL.
   */
  backgroundColor = 'transparent';

  /**
   * Colors by first intial of the user's name.
   * @example 'First names that start with A or B will get the value' #5a8770
   */
  colors = {
    AB: '#5a8770',
    CD: '#005ea0',
    EF: '#5c96fa',
    GH: '#fa163f',
    IJ: '#FE7D7D',
    KL: '#6c7b95',
    MN: '#20b8cc',
    OP: '#ff8b61',
    QR: '#52bc89',
    ST: '#8bc43d',
    UV: '#9672cf',
    WX: '#f25e90',
    YZ: '#f8b400'
  };

  constructor() {}

  ngOnChanges() {
    if (this.user) {
      this.getAvatarInitials();
    }
  }

  /**
   * Gets the user's initials if the user object does not contain an imgURL.
   */
  getAvatarInitials() {
    if (!this.user.imgUrl && this.user.name) {
      this.initials = Utils.getInitials(this.user.name);
      this.setAvatarColors();
    }
  }

  /**
   * Sets the background color of the avatars with initials.
   *
   * @description After the user's initials have been determined, the first initial
   * is taken and compared to the 'colors' object looking for the key that contains
   * the same letter. Once found, the background color is set to that key's value.
   *
   * @example
   * colors = {ab: 'red', cd: 'blue}
   * initials = 'BT'
   * First initial 'B' is the 2nd character in the colors 'ab' key, so the background color will be red.
   */
  setAvatarColors() {
    const firstLetter = this.initials.charAt(0);
    const keys = Object.keys(this.colors);
    for (const key of keys) {
      if (key.includes(firstLetter)) {
        this.backgroundColor = this.colors[key];
      }
    }
  }
}
