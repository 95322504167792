import { Category } from 'src/app/_shared/models/category';
import { Tenant, ChargeDescriptionListEdit } from 'src/app/charge-cat/shared/models';
import { CdmChargeDescription, CdmTableData, ChargeDescription } from 'src/app/_shared/models';
import { CptHcpcsCodeDescription, RevenueCodeDescription } from './app-state.model';

/*-------------------------------------------
 *         Category List Actions
 *------------------------------------------*/

export class FetchCategories {
  static readonly type = '[Category List] Fetch Categories';
  constructor() {}
}

export class FetchCategoryRules {
  static readonly type = '[Category List] Fetch Category Rules';
  constructor() {}
}
export class SetCategories {
  static readonly type = '[Category List] Set Categories';
  constructor(public categoriesWithRules: Category[]) {}
}

export class SetCategoriesWithRules {
  static readonly type = '[Category List] Set Categories With Rules';
  constructor(public categoriesWithRules: Category[]) {}
}

export class AddCategory {
  static readonly type = '[Category List] Add Category';
  constructor(public sortedCategories: Category[], public category: Category) {}
}

/**
 * Deletes an existing category.
 * @param chargeCatId - The ID of the category being deleted.
 */
export class DeleteCategory {
  static readonly type = '[Category List] Delete Category';
  constructor(public chargeCatId: string) {}
}
export class EditCategory {
  static readonly type = '[Category List] Edit Category';
  constructor(public category: Category) {}
}

export class SelectCategory {
  static readonly type = '[Category List] Select Category';
  constructor(public category: Category) {}
}

export class FilterCategories {
  static readonly type = '[Category List] Filter Categories';
  constructor(public searchFilterText: string) {}
}

/**
 * If the selected category is being deleted, set it
 * to a blank category to reset any use of the selected category
 * elsewhere in the app.
 * @param chargeCatId - The ID of the category being deleted.
 */
export class ResetSelectedCategoryIfDeleted {
  static readonly type = '[Category List] Reset Selected Category If Deleted';
  constructor(public chargeCatId: string) {}
}

export class ClearSelectedCategory {
  static readonly type = '[Category List] Clear Selected Category';
  constructor() {}
}

export class ClearCategories {
  static readonly type = '[Category List] Clear Categories';
  constructor() {}
}

// export class UpdateSelectedCategory {
//   static readonly type = '[Category List] Update Selected Category';
//   constructor(public category: Category) {}
// }

/*-------------------------------------------
 *         Category Query Actions
 *------------------------------------------*/

export class SetManualIncludes {
  static readonly type = '[Category Query] Set Manual Includes';
  constructor(public manualIncludes: ChargeDescription[]) {}
}
export class SetManualExcludes {
  static readonly type = '[Category Query] Set Manual Excludes';
  constructor(public manualExcludes: ChargeDescription[]) {}
}
export class DeleteManualExclude {
  static readonly type = '[Category Query] Delete Manual Exclude';
  constructor(public itemToRemove: ChargeDescriptionListEdit) {}
}
export class DeleteManualInclude {
  static readonly type = '[Category Query] Delete Manual Include';
  constructor(public itemToRemove: ChargeDescriptionListEdit) {}
}
export class SetModifiedByUserToSelectedCategory {
  static readonly type = '[Category Preview] Set Modified By User To Selected Category';
  constructor() {}
}
export class UpdateCategoryAfterPreview {
  static readonly type = '[Category Preview] Save Category N1QL Statement';
  constructor(public category: Category) {}
}
export class UpdateCategoryAfterSave {
  static readonly type = '[Category Query] Save Category N1QL Statement';
  constructor(public category: Category) {}
}
export class SetTempCategoryForN1QLStatement {
  static readonly type = '[Category Preview] Set temp category for N1QL statement';
  constructor(public category: Category) {}
}
export class ClearTempCategory {
  static readonly type = '[Category Preview] Clear temporary category';
  constructor() {}
}
export class N1QLEditorPendingChanges {
  static readonly type = '[Category Query] N1QL editor pending changes check';
  constructor(public isDirty: boolean) {}
}

export class SetTempCategoryToSelectedCategory {
  static readonly type = '[Category Preview] Set Temp Category To Selected Category';
  constructor() {}
}

/**
 * sets the list of active running list of tenants in UI
 */
export class setActiveRunningTenants {
  static readonly type = '[Active Tenants] Set tenants with active jobs ';
  constructor(public tenants: String[]) {}
}

/*-------------------------------------------
 *           CDM Table Actions
 *------------------------------------------*/

export class SetTenantList {
  static readonly type = '[CDM Table] Set Tenant List';
  constructor(public tenantList: Tenant[]) {}
}
export class SetCptList {
  static readonly type = '[CDM Table] Set CPT List';
  constructor(public cptList: CptHcpcsCodeDescription[]) {}
}
export class SetHcpcsList {
  static readonly type = '[CDM Table] Set HCPCS List';
  constructor(public hcpcsList: CptHcpcsCodeDescription[]) {}
}
export class SetRevenueCodeList {
  static readonly type = '[CDM Table] Set Revenue Code List';
  constructor(public revenueCodeList: RevenueCodeDescription[]) {}
}
export class SetRevenueCodeDictionary {
  static readonly type = '[CDM Table] Set Revenue Code Dictionary';
  constructor(public revenueCodeDictionary: any) {}
}
export class SetCategoryDictionary {
  static readonly type = 'Set Category Dictionary';
  constructor(public categoryDictionary: any) {}
}
export class SetCategoryDictionaryHB {
  static readonly type = 'Set Category Dictionary HB';
  constructor(public categoryDictionaryHB: any) {}
}
export class SetCategoryDictionaryPB {
  static readonly type = 'Set Category Dictionary PB';
  constructor(public categoryDictionaryPB: any) {}
}
export class SetCategoryDictionaryDRGV {
  static readonly type = 'Set Category Dictionary DRGV';
  constructor(public categoryDictionaryDRGV: any) {}
}
export class SetCategoryDictionaryCC {
  static readonly type = 'Set Category Dictionary CC';
  constructor(public categoryDictionaryCC: any) {}
}
export class ApplyCdmTableCategoryUpdate {
  static readonly type = '[CDM Table] Apply CDM Table Cateogry Updates';
  constructor(public categoriesToAdd: Category[], public chargeDescriptionsToModify: ChargeDescription[]) {}
}
// export class FetchCdmData {
//   static readonly type = '[CDM Table] Fetch CDM Data';
//   constructor(public queryParameters: QueryParameters) {}
// }

export class ClearCdmChargeDescriptions {
  static readonly type = '[CDM Table] Clear CDM Charge Descriptions';
  constructor() {}
}

export class ClearCdmTableData {
  static readonly type = '[CDM Table] Clear CDM Table Data';
  constructor() {}
}

export class SetCdmChargeDescriptions {
  static readonly type = '[CDM Table] Set CDM Charge Descriptions';
  constructor(public cdmData: CdmChargeDescription[]) {}
}

export class SetCdmTableData {
  static readonly type = '[CDM Table] Set CDM Table Data';
  constructor(public cdmTableData: CdmTableData) {}
}

export class SetCdmDataPageCount {
  static readonly type = '[CDM Table] Set CDM Data Page Count';
  constructor(public count: number) {}
}

export class SetCptDictionary {
  static readonly type = '[CDM Table] Set CPT Dictionary';
  constructor(public cptDictionary: any[]) {}
}

export class SetHcpcsDictionary {
  static readonly type = '[CDM Table] Set HCPCS Dictionary';
  constructor(public hcpcsDictionary: any[]) {}
}
export class SetSiDictionary {
  static readonly type = '[CDM Table] Set Si Dictionary';
  constructor(public siDictionary: any) {}
}

export class SetAmaDescrDictionary {
  static readonly type = '[CDM Table] Set AMA Desc Dictionary';
  constructor(public amaDescrDictionary: any) {}
}

export class SetApcDictionary {
  static readonly type = '[CDM Table] Set APC Dictionary';
  constructor(public apcDictionary: any) {}
}

/*-------------------------------------------
 *           Loading Actions
 *------------------------------------------*/
export class ToggleLoadingCptList {
  static readonly type = '[CDM Table] Toggle Loading CPT List';
  constructor() {}
}

export class ToggleLoadingRevenueCodeList {
  static readonly type = '[CDM Table] Toggle Loading Revenue Code List';
  constructor() {}
}
export class ToggleLoadingHcpcsList {
  static readonly type = '[CDM Table] Toggle Loading HCPCS List';
  constructor() {}
}
export class ToggleLoadingTenantList {
  static readonly type = '[CDM Table] Toggle Loading tenant list';
  constructor() {}
}
export class ToggleLoadingHcpcsAndCpt {
  static readonly type = 'Toggle Loading HcpcsAndCpt';
  constructor() {}
}

/*-------------------------------------------
 *           Error Actions
 *------------------------------------------*/
export class SetCategoryDeltaError {
  static readonly type = '[Category Preview] Set Category Delta Error';
  constructor(public error: string) {}
}
export class SetCdmTableMaxCountError {
  static readonly type = '[CDM Table] Set CDM Table Max Count Error';
  constructor() {}
}
