import { Category } from 'src/app/_shared/models';

export class FunctionAppJobStateModel {
  functionAppJobs: FunctionAppJob[];
  static getDefaults() {
    return {
      functionAppJobs: []
    };
  }
}

export class FunctionAppJob {
  id: string;
  delId: string;
  category: Category;
  status: string;
  startDate: Date;
  isPaused: boolean;
  updatedTimestamp: string;
}
