import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';

import { NotificationService } from 'src/app/_core/services/notification.service';
import { UserState } from 'src/app/_core/store/user/user.state';
import { Category, createChargeCategory } from 'src/app/_shared/models/category';
import { ChargeDescription } from 'src/app/_shared/models/charge-description';
import { ActiveRunningTenantsModel, ChargeCatStateModel } from './app-state.model';
import * as Actions from './app.actions';

@State<ChargeCatStateModel>({
  name: 'ChargeCatStore',
  defaults: ChargeCatStateModel.getDefaults()
})
@Injectable()
export class ChargeCatAppState {
  constructor(public notificationService: NotificationService, private store: Store) {}

  @Action(Actions.SetManualIncludes)
  setManualIncludes(ctx: StateContext<ChargeCatStateModel>, { manualIncludes }: Actions.SetManualIncludes) {
    ctx.patchState({ selectedCategoryIncludes: manualIncludes });
  }

  @Action(Actions.SetManualExcludes)
  setManualExcludes(ctx: StateContext<ChargeCatStateModel>, { manualExcludes }: Actions.SetManualExcludes) {
    ctx.patchState({ selectedCategoryExcludes: manualExcludes });
  }

  // @Action(Actions.ClearCdmTableData)
  // clearCdmTableData(ctx: StateContext<ChargeCatStateModel>) {
  //   ctx.patchState({ cdmChargeDescriptions: null });
  // }

  @Action(Actions.SetTenantList)
  fetchTenantList(ctx: StateContext<ChargeCatStateModel>, { tenantList }: Actions.SetTenantList) {
    ctx.patchState({ tenantList });
    ctx.dispatch(new Actions.ToggleLoadingTenantList());
  }

  @Action(Actions.SetHcpcsList)
  fetchHcpcsList(ctx: StateContext<ChargeCatStateModel>, { hcpcsList }: Actions.SetHcpcsList) {
    ctx.patchState({ hcpcsList });
    ctx.dispatch(new Actions.ToggleLoadingHcpcsList());
  }

  @Action(Actions.SetCptList)
  fetchCptList(ctx: StateContext<ChargeCatStateModel>, { cptList }: Actions.SetCptList) {
    ctx.patchState({ cptList });
    ctx.dispatch(new Actions.ToggleLoadingCptList());
  }

  @Action(Actions.SetRevenueCodeList)
  fetchRevenueCodeList(ctx: StateContext<ChargeCatStateModel>, { revenueCodeList }: Actions.SetRevenueCodeList) {
    ctx.patchState({ revenueCodeList });
    ctx.dispatch(new Actions.ToggleLoadingRevenueCodeList());
  }

  @Action(Actions.SetRevenueCodeDictionary)
  fetchRevenueCodeDictionary(
    ctx: StateContext<ChargeCatStateModel>,
    { revenueCodeDictionary }: Actions.SetRevenueCodeDictionary
  ) {
    ctx.patchState({ revenueCodeDictionary });
  }
  @Action(Actions.SetCategoryDictionary)
  fetchCategoryDictionary(
    ctx: StateContext<ChargeCatStateModel>,
    { categoryDictionary }: Actions.SetCategoryDictionary
  ) {
    ctx.patchState({ categoryDictionary });
  }
  @Action(Actions.SetCategoryDictionaryHB)
  fetchCategoryDictionaryHB(
    ctx: StateContext<ChargeCatStateModel>,
    { categoryDictionaryHB }: Actions.SetCategoryDictionaryHB
  ) {
    ctx.patchState({ categoryDictionaryHB });
  }
  @Action(Actions.SetCategoryDictionaryPB)
  fetchCategoryDictionaryPB(
    ctx: StateContext<ChargeCatStateModel>,
    { categoryDictionaryPB }: Actions.SetCategoryDictionaryPB
  ) {
    ctx.patchState({ categoryDictionaryPB });
  }
  @Action(Actions.SetCategoryDictionaryDRGV)
  fetchCategoryDictionaryDRGV(
    ctx: StateContext<ChargeCatStateModel>,
    { categoryDictionaryDRGV }: Actions.SetCategoryDictionaryDRGV
  ) {
    ctx.patchState({ categoryDictionaryDRGV });
  }
  @Action(Actions.SetCategoryDictionaryCC)
  fetchCategoryDictionaryCC(
    ctx: StateContext<ChargeCatStateModel>,
    { categoryDictionaryCC }: Actions.SetCategoryDictionaryCC
  ) {
    ctx.patchState({ categoryDictionaryCC });
  }

  @Action(Actions.SelectCategory)
  selectCategory(ctx: StateContext<ChargeCatStateModel>, { category }: Actions.SelectCategory) {
    ctx.patchState({ selectedCategory: category });
  }

  @Action(Actions.DeleteCategory)
  deleteCategory(ctx: StateContext<ChargeCatStateModel>, { chargeCatId }: Actions.DeleteCategory) {
    ctx.setState(
      patch({
        categories: removeItem<Category>(c => c.chargeCatId === chargeCatId)
      })
    );
  }

  @Action(Actions.DeleteManualExclude)
  deleteManualExclude(ctx: StateContext<ChargeCatStateModel>, { itemToRemove }: Actions.DeleteManualExclude) {
    ctx.setState(
      patch({
        selectedCategoryExcludes: removeItem<ChargeDescription>(c => c.docId === itemToRemove.chargeDescriptionIds[0])
      })
    );
  }

  @Action(Actions.DeleteManualInclude)
  deleteManualInclude(ctx: StateContext<ChargeCatStateModel>, { itemToRemove }: Actions.DeleteManualInclude) {
    ctx.setState(
      patch({
        selectedCategoryIncludes: removeItem<ChargeDescription>(c => c.docId === itemToRemove.chargeDescriptionIds[0])
      })
    );
  }

  /**
   * @Action Updates categories with the new category added to a sorted list
   */
  @Action(Actions.AddCategory)
  addCategory(ctx: StateContext<ChargeCatStateModel>, { sortedCategories, category }: Actions.AddCategory) {
    ctx.patchState({ categories: sortedCategories });
    ctx.dispatch(new Actions.SelectCategory(category));
  }

  @Action(Actions.ResetSelectedCategoryIfDeleted)
  resetSelectedCategoryIfDeleted(
    ctx: StateContext<ChargeCatStateModel>,
    { chargeCatId }: Actions.ResetSelectedCategoryIfDeleted
  ) {
    if (this.isDeletingSelectedCategory(ctx, chargeCatId)) {
      ctx.dispatch(new Actions.ClearSelectedCategory());
    }
  }

  @Action(Actions.ClearSelectedCategory)
  clearSelectedCategory(ctx: StateContext<ChargeCatStateModel>) {
    ctx.setState(
      patch({
        selectedCategory: createChargeCategory()
      })
    );
  }

  /**
   * @Action Checks to see if the currently selected category is the one being deleted.
   */
  private isDeletingSelectedCategory({ getState }: StateContext<ChargeCatStateModel>, categoryId: string) {
    return categoryId === getState().selectedCategory.chargeCatId;
  }

  @Action(Actions.ApplyCdmTableCategoryUpdate)
  applyCdmTableCategoryUpdate(
    ctx: StateContext<ChargeCatStateModel>,
    { categoriesToAdd, chargeDescriptionsToModify }: Actions.ApplyCdmTableCategoryUpdate
  ) {
    chargeDescriptionsToModify.forEach(item => {
      ctx.setState(
        patch({
          cdmChargeDescriptions: updateItem<ChargeDescription>(
            c => c.docId === item.docId,
            patch({
              chargeCategoryIds: categoriesToAdd.map(c => c.docId),
              categoryNames: categoriesToAdd.map(c => c.name).join(', ')
            })
          )
        })
      );
    });
  }

  /**
   * @Action Creates a temporary category to be used for previewing a N1QL statement.
   * @param category - The temp category created in the component that will get stored.
   */
  @Action(Actions.SetTempCategoryForN1QLStatement)
  setTempCategoryForN1QLStatement(
    ctx: StateContext<ChargeCatStateModel>,
    { category }: Actions.SetTempCategoryForN1QLStatement
  ) {
    ctx.patchState({ tempCategory: category });
  }

  /**
   * @Action Clears out the temporary category in the store.
   */
  @Action(Actions.ClearTempCategory)
  clearTemporaryCategory(ctx: StateContext<ChargeCatStateModel>) {
    ctx.patchState({ tempCategory: null });
  }

  /**
   * @Action Sets the property indicating whether or not the N1QL editor has an unsaved or un-reverted change.
   */
  @Action(Actions.N1QLEditorPendingChanges)
  n1qlEditorPendingChanges(ctx: StateContext<ChargeCatStateModel>, { isDirty }: Actions.N1QLEditorPendingChanges) {
    ctx.patchState({ isN1QLEditorDirty: isDirty });
  }

  /**
   * @Action Updates a category's name
   */
  @Action(Actions.EditCategory)
  editCategory(ctx: StateContext<ChargeCatStateModel>, { category }: Actions.EditCategory) {
    ctx.setState(
      patch({
        categories: updateItem<Category>(
          c => c.docId === category.docId,
          patch({
            name: category.name,
            description: category.description,
            isChargeCapture: category.isChargeCapture,
            isDrgV: category.isDrgV,
            resolverDays: category.resolverDays
          })
        )
      })
    );
  }

  /**
   *  Updates the selected category to the temporary category.
   */
  @Action(Actions.SetTempCategoryToSelectedCategory)
  setTempCategoryToSelectedCategory(ctx: StateContext<ChargeCatStateModel>) {
    ctx.patchState({ selectedCategory: ctx.getState().tempCategory });
  }

  /**
   * @Action Updates a category after previewing
   */
  @Action(Actions.UpdateCategoryAfterPreview)
  updateCategoryAfterPreview(ctx: StateContext<ChargeCatStateModel>, { category }: Actions.UpdateCategoryAfterPreview) {
    ctx.setState(
      patch({
        categories: updateItem<Category>(c => c.docId === category.docId, patch<Category>(category))
      })
    );
  }

  @Action(Actions.UpdateCategoryAfterSave)
  updateCategoryAfterSave(ctx: StateContext<ChargeCatStateModel>, { category }: Actions.UpdateCategoryAfterSave) {
    ctx.setState(
      patch({
        categories: updateItem<Category>(c => c.docId === category.docId, patch<Category>(category))
      })
    );
  }

  @Action(Actions.SetModifiedByUserToSelectedCategory)
  setModifiedByUserToSelectedCategory(ctx: StateContext<ChargeCatStateModel>) {
    const user = this.store.selectSnapshot(UserState.userInfo);
    if (user) {
      ctx.setState(
        patch({
          selectedCategory: patch({ modifiedBy: user.userId })
        })
      );
    }
  }

  @Action(Actions.ClearCdmChargeDescriptions)
  clearCdmChargeDescriptions(ctx: StateContext<ChargeCatStateModel>) {
    ctx.patchState({ cdmChargeDescriptions: [] });
  }

  @Action(Actions.ClearCdmTableData)
  clearCdmTableData(ctx: StateContext<ChargeCatStateModel>) {
    ctx.patchState({ cdmTableData: { cdmData: null, offset: 0 } });
  }

  @Action(Actions.SetCdmTableData)
  setCdmTableData(ctx: StateContext<ChargeCatStateModel>, cdmTableData: Actions.SetCdmTableData) {
    ctx.patchState(cdmTableData);
  }

  @Action(Actions.SetCdmChargeDescriptions)
  setCdmChargeDescriptions(ctx: StateContext<ChargeCatStateModel>, { cdmData }: Actions.SetCdmChargeDescriptions) {
    ctx.patchState({ cdmChargeDescriptions: cdmData });
  }

  @Action(Actions.SetCdmDataPageCount)
  setCdmDataPageCount(ctx: StateContext<ChargeCatStateModel>, { count }: Actions.SetCdmDataPageCount) {
    ctx.patchState({ cdmDataPageCount: count });
  }

  @Action(Actions.SetCptDictionary)
  setCptDictionary(ctx: StateContext<ChargeCatStateModel>, { cptDictionary }: Actions.SetCptDictionary) {
    ctx.patchState({ cptDictionary });
  }

  @Action(Actions.SetHcpcsDictionary)
  setHcpcsDictionary(ctx: StateContext<ChargeCatStateModel>, { hcpcsDictionary }: Actions.SetHcpcsDictionary) {
    ctx.patchState({ hcpcsDictionary });
  }

  @Action(Actions.SetSiDictionary)
  setSiDictionary(ctx: StateContext<ChargeCatStateModel>, { siDictionary }: Actions.SetSiDictionary) {
    ctx.patchState({ siDictionary });
  }

  @Action(Actions.SetAmaDescrDictionary)
  setAmaDescrDictionary(ctx: StateContext<ChargeCatStateModel>, { amaDescrDictionary }: Actions.SetAmaDescrDictionary) {
    ctx.patchState({ amaDescrDictionary });
  }

  @Action(Actions.SetApcDictionary)
  setApcDictionary(ctx: StateContext<ChargeCatStateModel>, { apcDictionary }: Actions.SetApcDictionary) {
    ctx.patchState({ apcDictionary });
  }

  @Action(Actions.SetCategories)
  setCategories(ctx: StateContext<ChargeCatStateModel>, { categoriesWithRules }: Actions.SetCategories) {
    ctx.patchState({ categories: categoriesWithRules });
  }

  @Action(Actions.SetCategoriesWithRules)
  setCategoriesWithRules(
    ctx: StateContext<ChargeCatStateModel>,
    { categoriesWithRules }: Actions.SetCategoriesWithRules
  ) {
    ctx.patchState({ categoriesWithRules });
  }

  @Action(Actions.ClearCategories)
  clearCategories(ctx: StateContext<ChargeCatStateModel>) {
    ctx.patchState({ categories: null });
  }

  @Action(Actions.setActiveRunningTenants)
  setActiveTenants(ctx: StateContext<ActiveRunningTenantsModel>, { tenants }: Actions.setActiveRunningTenants) {
    ctx.patchState({ tenants: tenants });
  }
}
