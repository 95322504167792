import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ChargeCatLoadingStateModel } from './loading-state.model';
import * as Actions from '../app/app.actions';

@State<ChargeCatLoadingStateModel>({
  name: 'ChargeCatLoadingState',
  defaults: ChargeCatLoadingStateModel.getDefaults()
})
@Injectable()
export class ChargeCatLoadingState {
  constructor() {}

  /**
   * @Action Sets a property that indicates whether or not hcpcs cpt list is sill loading.
   */
  @Action(Actions.ToggleLoadingHcpcsAndCpt)
  loadingHcpcsAndCpt({ setState }: StateContext<ChargeCatLoadingStateModel>) {
    setState(store => ({
      ...store,
      loadingHcpcsCpt: !store.loadingHcpcsCpt
    }));
  }
  /**
   * @Action Sets a property that indicates whether or not hcpcs cpt list is sill loading.
   */
  @Action(Actions.ToggleLoadingRevenueCodeList)
  loadingRevCode({ setState }: StateContext<ChargeCatLoadingStateModel>) {
    setState(store => ({
      ...store,
      loadingRevCode: !store.loadingRevCode
    }));
  }
}
