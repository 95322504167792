import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';
import { FunctionAppJobStateModel, FunctionAppJob } from './function-app-job-state.model';
import * as Actions from './function-app-job.actions';

@State<FunctionAppJobStateModel>({
  name: 'FuctionAppJobState',
  defaults: FunctionAppJobStateModel.getDefaults()
})
@Injectable()
export class FunctionAppJobState {
  constructor() {}

  /**
   * @Action Updates jobs with the new job
   */
  @Action(Actions.AddJob)
  addJob(ctx: StateContext<FunctionAppJobStateModel>, { job }: Actions.AddJob) {
    const jobs = Object.assign([], ctx.getState().functionAppJobs);
    jobs.push(job);
    ctx.patchState({ functionAppJobs: jobs });
  }

  @Action(Actions.DeleteJob)
  deleteJob(ctx: StateContext<FunctionAppJobStateModel>, { jobId }: Actions.DeleteJob) {
    ctx.setState(
      patch({
        functionAppJobs: removeItem<FunctionAppJob>(c => c.id === jobId)
      })
    );
  }

  @Action(Actions.EditJob)
  editJob(ctx: StateContext<FunctionAppJobStateModel>, { job }: Actions.EditJob) {
    ctx.setState(
      patch({
        functionAppJobs: updateItem<FunctionAppJob>(c => c.id === job.id, patch(job))
      })
    );
  }
}
