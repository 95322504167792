<!-- Manually Included CDMs -->
<div class="loading-position-includes" *ngIf="deletingInclude === true">
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<div class="row" *ngIf="(includes$ | async)?.length > 0">
  <div class="col">
    <cm-manual-exceptions-grid
      [data]="includes$ | async"
      [toolbarTitle]="'Included CDMs'"
      [gridId]="'inclusion'"
      (removeRow)="removeSelectedRow($event)"
    ></cm-manual-exceptions-grid>
  </div>
</div>

<!-- Manually Excluded CDMs -->
<div
  class="loading-position-excludes"
  *ngIf="deletingExclude === true && (excludes$ | async)?.length > 0 && (includes$ | async)?.length > 0"
>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<div
  class="loading-position-excludes-only"
  *ngIf="deletingExclude === true && (excludes$ | async)?.length > 0 && (includes$ | async)?.length == 0"
>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<div class="row" [ngClass]="{ 'mt-4': hasIncludes && hasExcludes }" *ngIf="(excludes$ | async)?.length > 0">
  <div class="col">
    <cm-manual-exceptions-grid
      [data]="excludes$ | async"
      [toolbarTitle]="'Excluded CDMs'"
      [gridId]="'exclusion'"
      (removeRow)="removeSelectedRow($event)"
    ></cm-manual-exceptions-grid>
  </div>
</div>
