import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cm-delete-category-modal',
  templateUrl: 'delete-category-modal.component.html',
  styleUrls: ['./delete-category-modal.component.scss']
})
export class DeleteCategoryModalComponent {
  message: string;

  constructor(public dialogRef: MatDialogRef<DeleteCategoryModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.message = data.message;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  delete() {
    this.dialogRef.close(this.data);
  }
}
