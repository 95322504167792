import { Selector } from '@ngxs/store';
import { ChargeCatErrorStateModel } from './error-state.model';
import { ChargeCatErrorState } from './error.state';

export class ChargeCatErrorSelectors {
  @Selector([ChargeCatErrorState])
  static getCategoryDeltaError(state: ChargeCatErrorStateModel) {
    return state.categoryDeltaError;
  }
}
