// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// 3rd party
import { NgxSpinnerModule } from 'ngx-spinner';

// App Modules
import { AppMaterialModule } from '../app-material.module';
import { CdmTableModule } from './cdm-table/cdm-table.module';
import { ChargeCatRoutingModule } from './charge-cat-routing.module';

// App Components
import { CategoryDefinitionModule } from './category-definition/category-definition.module';
import { ChargeCatComponent } from './charge-cat.component';
import { ChargeDescriptionGridComponent } from './shared/charge-description-grid/charge-description-grid.component';

@NgModule({
  declarations: [ChargeCatComponent, ChargeDescriptionGridComponent],
  imports: [
    AppMaterialModule,
    CommonModule,
    CategoryDefinitionModule,
    CdmTableModule,
    ChargeCatRoutingModule,
    NgxSpinnerModule
  ],
  exports: [ChargeCatComponent]
})
export class ChargeCatModule {}
