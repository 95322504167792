<p-overlayPanel styleClass="grid-menu-overlay" #addCdmGridMenu appendTo="body">
  <ng-template pTemplate>
    <ng-template [ngTemplateOutlet]="deltaGridMenu"></ng-template>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel styleClass="grid-menu-overlay" #removeCdmGridMenu appendTo="body">
  <ng-template pTemplate>
    <ng-template [ngTemplateOutlet]="deltaGridMenu"></ng-template>
  </ng-template>
</p-overlayPanel>

<div
  [ngClass]="toolbarTitle === 'Descriptions that will have this category added' ? 'alert-success' : 'alert-danger'"
  class="alert alert-success mb-0 no-border-radius pt-1 pb-1"
  role="alert"
>
  <div class="d-flex justify-content-between">
    <div class="mt-1">{{ toolbarTitle }}</div>
    <div class="d-flex justify-content-between">
      <div class="d-inline-block mt-1">Total: {{ data.length | number }}</div>
      <div class="d-inline-block ml-2">
        <i
          *ngIf="toolbarTitle === 'Descriptions that will have this category added'"
          class="fa fa-cog fa-2x table-gear-icon"
          aria-hidden="true"
          (click)="addCdmGridMenu.show($event)"
        ></i>
        <i
          *ngIf="toolbarTitle !== 'Descriptions that will have this category added'"
          class="fa fa-cog fa-2x table-gear-icon"
          aria-hidden="true"
          (click)="removeCdmGridMenu.show($event)"
        ></i>
      </div>
    </div>
  </div>
</div>
<p-table
  #deltagrid
  [columns]="selectedColumns"
  [value]="data"
  [scrollable]="true"
  scrollHeight="400px"
  scrollDirection="both"
  [virtualScroll]="true"
  [virtualRowHeight]="34"
  styleClass="p-datatable-sm"
  [rows]="10000"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th class="row-col">Row</th>
      <th
        [pSortableColumn]="col.field"
        *ngFor="let col of columns"
        [ngStyle]="{ width: col.width }"
        class="d-flex justify-content-between"
      >
        {{ col.header }} <p-sortIcon field="{{ col.field }}"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th class="row-col"></th>
      <th *ngFor="let col of columns" [ngStyle]="{ width: col.width }">
        <div class="w-100">
          <p-multiSelect
            appendTo="body"
            [options]="col.uniqueData"
            placeholder="All"
            (onChange)="onMultiSelectFilter($event, col.field)"
            styleClass="p-column-filter table-filter-multiselect-with-options"
            [virtualScroll]="true"
            itemSize="30"
            [resetFilterOnHide]="true"
          >
            <ng-template let-option pTemplate="item">
              <div class="">
                <span class="p-ml-1">{{ option.label }}</span>
              </div>
            </ng-template>
          </p-multiSelect>
        </div>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
    <tr class="charge-desc-list-row">
      <td class="row-col">{{ i + 1 }}</td>
      <td *ngFor="let col of columns" [ngStyle]="{ width: col.width }" [ngClass]="'text-' + col.cellAlignment">
        <span [ngClass]="'text-' + col.cellAlignment" class="w-100">{{ rowData[col.field] }}</span>
      </td>
    </tr>
  </ng-template>
</p-table>

<ng-template #deltaGridMenu>
  <p-listbox
    [options]="cols"
    [metaKeySelection]="false"
    [checkbox]="true"
    [filter]="true"
    [multiple]="true"
    optionLabel="header"
    [listStyle]="{ 'max-height': '250px' }"
    [style]="{ width: '15rem' }"
    [(ngModel)]="selectedColumns"
    showToggleAll="true"
    styleClass="chargeDescTableGearOverlay"
  >
  </p-listbox>
</ng-template>
