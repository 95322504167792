export class ChargeCatLoadingStateModel {
  loaded: boolean;
  loading: boolean;
  loadingHcpcsCpt: boolean;
  loadingRevCode: boolean;

  static getDefaults() {
    return {
      loaded: false,
      loading: false,
      loadingHcpcsCpt: false,
      loadingRevCode: false
    };
  }
}
