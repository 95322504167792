import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'cm-top-slider',
  templateUrl: './top-slider.component.html',
  styleUrls: ['./top-slider.component.scss']
})
export class TopSliderComponent implements OnInit {
  @Input() visible: boolean;
  @Output() toggleTopSlider = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  toggleSlider() {
    this.toggleTopSlider.emit();
  }
}
