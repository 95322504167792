import { Component, OnInit } from '@angular/core';
import { SideNavOptions } from '../../shared/models';

@Component({
  selector: 'cm-side-collapse',
  templateUrl: './side-collapse.component.html',
  styleUrls: ['./side-collapse.component.scss']
})
export class SideCollapseComponent implements OnInit {
  sidenavOptions: SideNavOptions;

  constructor() {}

  ngOnInit() {
    this.sidenavOptions = this.setSideNavOptions();
  }

  setSideNavOptions(): SideNavOptions {
    return {
      bottom: 0,
      fixed: false,
      top: 111
    };
  }
}
