<!-- INITIALS -->
<section class="d-inline-flex" *ngIf="user?.name && !user?.imgUrl">
  <div class="avatar" [ngClass]="size + '-avatar'" [ngStyle]="{ 'background-color': backgroundColor }">
    <div class="initials">
      {{ initials }}
    </div>
  </div>
  <div *ngIf="showUserInfo" class="d-inline ml-1 mt-n1">
    {{ user?.name }} <span *ngIf="showUserTitle && user?.title">| {{ user?.title }}</span>
  </div>
  <span msg></span>
</section>

<!-- IMAGE-->
<section class="d-inline" *ngIf="user?.imgUrl">
  <img class="avatar" [ngClass]="size + '-avatar'" [src]="user?.imgUrl" alt="" />
  <div *ngIf="showUserInfo" class="d-inline ml-1 mt-n1 f16">
    {{ user?.name }} <span *ngIf="user?.title">| {{ user?.title }}</span>
  </div>
</section>
