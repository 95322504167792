<aside class="card mt-4">
  <div class="card-header category-list-header">
    <div class="row">
      <div class="category-search-input-wrapper col-sm-9">
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              pButton
              pRipple
              type="button"
              icon="fa fa-refresh"
              class="p-button-rounded p-button-text p-button-plain cat-list-header-btn"
              (click)="refreshCategoryList()"
            ></button>
            <button
              #filterTypesBtn
              type="button"
              pButton
              icon="pi pi-ellipsis-v"
              class="p-button-rounded p-button-text p-button-plain cat-list-header-btn"
              (click)="catlistmenu.toggle($event)"
            ></button>
            <p-menu #catlistmenu [popup]="true" [model]="filterTypes" appendTo="body"></p-menu>
          </div>
          <ng-container *ngIf="filterBy === FilterTypes.CategorySearch">
            <input
              #categoryFilter
              type="search"
              class="form-control form-control-sm"
              id="categoryFilter"
              name="categoryFilter"
              placeholder="Search categories"
              [(ngModel)]="filterText"
              (search)="clearFilter()"
              (input)="filterByCategory($event.target.value)"
            />
          </ng-container>
          <ng-container *ngIf="filterBy === FilterTypes.N1QLSearch">
            <input
              #n1qlFilter
              type="search"
              class="form-control form-control-sm"
              id="n1qlFilter"
              name="n1qlFilter"
              [(ngModel)]="filterText"
              placeholder="Search N1QL"
              (search)="clearFilter()"
              (input)="filterByN1QL($event.target.value)"
            />
          </ng-container>
        </div>
      </div>
      <div class="col-sm-1">
        <button
          type="button"
          class="d-flex ml-n1 btn clear-filter-btn"
          (click)="model.categories.values = []"
          pTooltip="Clear search filter"
          tooltipPosition="bottom"
          *ngIf="isFiltered"
          (click)="clearFilter()"
        >
          <img src="../../../../assets/images/Data-Clear-Filters-icon.png" class="clear-filters-icon" />
        </button>
      </div>
      <div class="col-sm-1">
        <button class="btn btn-success" (click)="openAddModal()">Add</button>
      </div>
    </div>
  </div>

  <div
    class="card-body category-list-container"
    [ngClass]="{
      'overflow-hidden': isLoadingCategories$ | async,
      'overflow-auto': (isLoadingCategories$ | async) === false
    }"
  >
    <ngx-spinner
      *ngIf="isLoadingCategories$ | async"
      [fullScreen]="false"
      bdColor="rgba(240,240,240,0.3)"
      size="large"
      color="#ddd"
      type="line-scale"
      class="spinner"
      ><span class="loading-message">Loading...</span>
    </ngx-spinner>
    <div class="category-list mt-n2" *ngIf="(isLoadingCategories$ | async) === false">
      <p-table
        #catListGrid
        [value]="categories$ | async"
        [scrollable]="true"
        scrollDirection="vertical"
        scrollHeight="flex"
        [virtualScroll]="true"
         responsiveLayout="scroll"
        styleClass="p-datatable-sm"
        [rows]="10000" 
        selectionMode="single"
        (onRowSelect)="onRowSelectionChange($event)"
        [(selection)]="selectedRow"
      >
        <ng-template pTemplate="body" let-rowData let-columns="columns" let-i="rowIndex">
          <tr [pSelectableRow]="rowData" class="cat-list-row">
            <td class="rule-col">
              <span
                class="rule-icon d-inline-block"
                *ngIf="rowData.rules?.length"
                pTooltip="Has Rules"
                tooltipPosition="right"
                >R</span
              >
            </td>
            <td class="cat-col">
              <div
                *ngIf="rowData.rules?.length"
                class="cursor-pointer rule-hover cat-cell"
                [escape]="false"
                [pTooltip]="showRules(rowData)"
              >
                {{ rowData['name'] }}
              </div>
              <div *ngIf="rowData.rules?.length == 0">
                {{ rowData['name'] }}
              </div>
            </td>
            <td class="edit-col">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-window-maximize"
                class="p-button-rounded p-button-text p-button-sm edit-category-icon cat-btn"
                pTooltip="Edit"
                tooltipPosition="left"
                (click)="openEditModal(rowData)"
              ></button>
            </td>
            <td class="delete-col">
              <button
                pButton
                type="button"
                icon="pi pi-trash"
                class="p-button-rounded p-button-text p-button-sm delete-manual-cd cat-btn"
                (click)="deleteConfirm($event, rowData)"
              ></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <small
      class="float-right category-count"
      pTooltip="Total with rules: {{ (categoriesWithRules$ | async)?.length | number }}"
      tooltipPosition="top"
      *ngIf="(isLoadingCategories$ | async) === false"
    >
      Total: {{ (categories$ | async)?.length | number }}
    </small>
  </div>
</aside>
