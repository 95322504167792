import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import clonedeep from 'lodash.clonedeep';

import { NotificationService } from 'src/app/_core/services/notification.service';
import { Category, CdmChargeDescription, CdmTableData, ChargeDescription } from 'src/app/_shared/models';
import { Utils } from 'src/app/_shared/utils';
import { NotificationType, TenantStandingList } from 'src/app/charge-cat/shared/enums';
import { messages } from 'src/app/charge-cat/shared/messages';
import {
  ChargeDescriptionListEdit,
  Tenant,
  CdmPageFilter,
  CategoryNameWithRuleTitlesList,
  CategoryDeltaRequest,
  CdmTableResponse,
  ChargeDescriptionSingleUpdateEdit
} from 'src/app/charge-cat/shared/models';
import { UserState } from '../../_core/store/user/user.state';

import { Select } from '@ngxs/store';
import { DataApiService } from 'src/app/charge-cat/services/data-api.service';
import { FunctionAppService } from 'src/app/charge-cat/services/function-app.service';
import { ChargeCatSelectors } from './app/app.selectors';
import { CptHcpcsCodeDescription, RevenueCodeDescription } from './app/app-state.model';
import * as Actions from './app/app.actions';
import * as JobActions from './function-app-job/function-app-job.actions';
import { SubSink } from 'subsink';
import { forkJoin, interval, Subscription, combineLatest, Observable } from 'rxjs';
import { EventBusService } from '../services/event-bus.service';
import { FunctionAppJob } from './function-app-job/function-app-job-state.model';
import { FunctionAppJobSelectors } from './function-app-job/function-app-job.selectors';
import { ColumnFilter } from 'src/app/_shared/models/column-filter';
import { LoadingStateService } from './loading-state.service';
import { ApplicationInsightsService } from 'src/app/_core/services/app-insights-service.service';
import { CategoryExt, ManualInExDeltaService } from '../services/manual-in-ex-delta.service';
import { tap } from 'rxjs/operators';

export interface CdmDict {
  [cdmItemDocId: string]: any;
}
export interface CatVersionDict {
  [versionDocId: string]: CategoryExt;
}

/**
 * **Charge Cat Facade**
 *
 * @description This facade is responsible for orchestrating
 * all NGXS store updates in Charge Cat.
 */
@Injectable({
  providedIn: 'root'
})
export class ChargeCatStoreFacade implements OnDestroy {
  subs = new SubSink();
  checkJobStatusInterval: Subscription;
  hbCategoryIds = [];
  pbCategoryIds = [];
  drgvCategoryIds = [];
  ccCategoryIds = [];
  @Select(ChargeCatSelectors.selectedCategory) selectedCategory$: Observable<Category>;
  includesDict: CdmDict = {};
  excludesDict: CdmDict = {};
  versionsDict: CatVersionDict = {};

  constructor(
    private store: Store,
    private dataApiService: DataApiService,
    private notificationService: NotificationService,
    private eventBus: EventBusService,
    private functionAppService: FunctionAppService,
    private loadingStateService: LoadingStateService,
    private appInsightsService: ApplicationInsightsService,
    private manualInExDeltaService: ManualInExDeltaService
  ) {
    this.checkJobStatusInterval = interval(10000).subscribe(x => {
      this.handleJobs();
    });
    this.subscribeToCdmDictionaryCreate();
  }

  private handleJobs() {
    const jobs = this.jobsSnapshot().filter(job => !job.isPaused);
    const jobStatusReqs = [];
    const jobsReqs: FunctionAppJob[] = [];
    if (jobs.length > 0) {
      // filter out expired jobs and maintain list of running jobs
      jobs.forEach(job => {
        if (!this.hasJobRunTimeExpired(job)) {
          jobStatusReqs.push(this.checkSaveJobStatus([job.id, job.delId]));
          jobsReqs.push(job);
        }
      });
      // make job status requests
      if (jobStatusReqs.length > 0) {
        this.subs.sink = forkJoin(jobStatusReqs).subscribe(res => {
          res.forEach((r: string, i) => {
            if (!r) {
              this.notificationService.notify(`Job Failed for ${jobsReqs[i].category.name}`, NotificationType.Error);
              this.removeJobFromStore(jobsReqs[i].id);
            } else if (r === 'Processed') {
              this.handleProcessedJob(jobsReqs[i]);
            } else if (r === 'Failed') {
              this.notificationService.notify(`Job Failed for ${jobsReqs[i].category.name}`, NotificationType.Error);
              this.removeJobFromStore(jobsReqs[i].id);
            } else if (r === 'VersionConflicts') {
              this.handleVersionConflicts(jobsReqs[i]);
            }
          });
        });
      }
    }
  }

  private handleVersionConflicts(jobReq: FunctionAppJob) {
    console.log(`version conflicts on task for category: ${jobReq.category.name}`);
    let vcJob = clonedeep(jobReq);
    vcJob.isPaused = true;
    this.updateJobInStore(vcJob);
    setTimeout(() => {
      this.removeJobFromStore(jobReq.id);
      this.dataApiService.cancelElasticTask(jobReq.delId);
      this.dataApiService.cancelElasticTask(jobReq.id);
      return this.updateCdmDataInElasticOnSuccess(jobReq.category);
    }, 60000);
  }

  private handleProcessedJob(jobReq: FunctionAppJob) {
    this.notificationService.notify(`Job completed for ${jobReq.category.name}`, NotificationType.Success);
    this.removeJobFromStore(jobReq.id);
    this.subs.sink = this.functionAppService
      .couchbaseCopyAdfTrigger({ updatedTimestamp: jobReq.updatedTimestamp })
      .subscribe(res => {
        console.log('couchbaseCopyAdfTrigger response: ', res);
      });
  }

  /**
   * Checks the full list of categories to see if a newly added category already exists.
   * @param categoryName - The name of the new category.
   * @returns boolean indicating if a duplicate was found.
   */
  isDuplicateCategoryName(categoryName: string): boolean {
    const categories = this.categoriesSnapshot();
    return categories.some((category: Category) => {
      if (category.name) {
        return category.name.toLowerCase() === categoryName.toLowerCase();
      }
    });
  }

  /**
   * Gets manual includes by the chargeCatId of the selected category.
   * @param chargeCatId - Id of the selected category.
   */
  async fetchManualIncludes(category: Category) {
    const docIds = category.chargeDescriptions || [];
    if (!docIds.length) {
      this.store.dispatch(new Actions.SetManualIncludes([]));
      return;
    }
    this.subs.sink = this.dataApiService.fetchChargeDescriptionsByIds(docIds).subscribe(
      result => {
        if (result) {
          this.setManualIncludes(result);
        }
      },
      err => this.notificationService.notify(messages.error.fetchManualIncludes, NotificationType.Error, err)
    );
  }

  setManualIncludes(result?: ChargeDescription[]) {
    if (!result) {
      result = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.manualIncludes));
    }
    const includes: ChargeDescription[] = clonedeep(result);
    if (includes) {
      includes.forEach(cdmItem => this.mapAdditionalGridColumns(cdmItem, true));
    }
    this.store.dispatch(new Actions.SetManualIncludes(includes));
  }

  private createVersionsArrayForDelta(versions: CategoryExt[], selectedCategory: CategoryExt) {
    let versionsAsc = versions.slice().reverse();

    const isLatest = this.isLatestCategory(versions, selectedCategory);
    //if latest add selectedCategory to versions array.
    if (isLatest) {
      versionsAsc.push(selectedCategory);
    }

    //find first version that has includes and exlcudes and chop array to start there
    const indexOfFirstManual = versionsAsc.findIndex(
      ver =>
        (ver.chargeDescriptions && ver.chargeDescriptions.length > 0) ||
        (ver.chargeDescriptionsExclusions && ver.chargeDescriptionsExclusions.length > 0)
    );
    versionsAsc = versionsAsc.slice(indexOfFirstManual);

    //Find current version and cut the array down to there to stop at the right place.
    const selectedVersionIdx = versionsAsc.findIndex(version => version.lastModified == selectedCategory.lastModified);
    if (selectedVersionIdx != -1 && selectedVersionIdx + 1 != versionsAsc.length) {
      versionsAsc = versionsAsc.slice(0, selectedVersionIdx + 1);
    }
    return versionsAsc;
  }

  /**
   * check if selected category latest version if not found assumption is you are on latest version
   * because the latest is not in the versions array
   * @param versions
   * @param selectedCategory
   * @returns
   */
  private isLatestCategory(versions: CategoryExt[], selectedCategory: CategoryExt): boolean {
    return !versions.some(ver => {
      JSON.stringify(ver) === JSON.stringify(selectedCategory);
    });
  }

  /**
   * Gets manual excludes by the chargeCatId of the selected category.
   * @param chargeCatId - Id of the selected category.
   */
  async fetchManualExcludes(category: Category) {
    const docIds = category.chargeDescriptionsExclusions || [];
    if (!docIds.length) {
      this.store.dispatch(new Actions.SetManualExcludes([]));
      return;
    }
    this.subs.sink = this.dataApiService.fetchChargeDescriptionsByIds(docIds).subscribe(
      result => {
        if (result) {
          this.setManualExcludes(result);
        }
      },
      err => this.notificationService.notify(messages.error.fetchManualExcludes, NotificationType.Error, err)
    );
  }

  setManualExcludes(result?: ChargeDescription[]) {
    if (!result) {
      result = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.manualExcludes));
    }
    const excludes: ChargeDescription[] = clonedeep(result);
    if (excludes) {
      excludes.forEach(cdmItem => this.mapAdditionalGridColumns(cdmItem, true));
    }
    this.store.dispatch(new Actions.SetManualExcludes(excludes));
  }

  /**
   * Deletes a manual exclude by the docId.
   */
  deleteManualExclude(itemToRemove: ChargeDescriptionListEdit) {
    this.eventBus.setRemovingManualExclude(true);
    let selectedCategory = this.getLastestSelectedCat();
    let user = this.store.selectSnapshot(UserState.userInfo);
    selectedCategory.modifiedBy = user.userId;
    itemToRemove.modifiedBy = user.userId;
    let idx = selectedCategory.chargeDescriptionsExclusions.findIndex(
      desc => desc === itemToRemove.chargeDescriptionIds[0]
    );
    selectedCategory.chargeDescriptionsExclusions.splice(idx, 1);
    this.appInsightsService.trackEvent({
      name: 'delete manual exclude',
      properties: {
        chargeDescriptionListEdit: itemToRemove,
        categoryName: selectedCategory.name,
        category: selectedCategory,
        user: { name: user.name, userid: user.userId }
      }
    });
    this.subs.sink = this.functionAppService.categoryDescriptionsManualRemove(itemToRemove).subscribe(
      (res: any) => {
        if (res) {
          setTimeout(() => {
            if (res && res[itemToRemove.chargeDescriptionIds[0]] && res[itemToRemove.chargeDescriptionIds[0]].Success) {
              selectedCategory.lastModified = res[itemToRemove.chargeDescriptionIds[0]].Success;
            }
            this.handleElasticDeleteManualExclude(selectedCategory, itemToRemove);
          }, 1000);
        }
      },
      err => {
        this.notificationService.notify(messages.error.deleteManualExclude, NotificationType.Error, err);
        this.eventBus.setRemovingManualExclude(false);
        console.log('Error deleting manual exclude: ', err);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(
            `Error deleting manual exclude. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
          )
        });
      }
    );
  }

  private getLastestSelectedCat() {
    let sc: Category = clonedeep(this.getSelectedCategory());
    const catDic = clonedeep(this.getCategoryDictionary());
    let selectedCategoryFromDictionary = catDic[sc.docId];
    let selectedCategory = null;
    if (sc.lastModified && selectedCategoryFromDictionary.lastModified) {
      selectedCategory =
        sc.lastModified > selectedCategoryFromDictionary.lastModified ? sc : selectedCategoryFromDictionary;
    } else {
      selectedCategory = selectedCategoryFromDictionary;
    }
    return selectedCategory;
  }

  private handleElasticDeleteManualExclude(selectedCategory: Category, itemToRemove: ChargeDescriptionListEdit) {
    this.subs.sink = this.functionAppService
      .copyCategoryES2CB({ chargeCatId: [selectedCategory.docId], action: 'update' })
      .subscribe(
        res => {
          const newCatDictionary = clonedeep(this.getCategoryDictionary());
          newCatDictionary[selectedCategory.docId] = selectedCategory;
          this.store.dispatch([
            new Actions.UpdateCategoryAfterSave(selectedCategory),
            new Actions.SetCategoryDictionary(newCatDictionary),
            new Actions.SelectCategory(selectedCategory)
          ]);
          this.refreshCategoryAndVersions(selectedCategory);
          this.updateElasticCdmAfterDeleteManualExclude(res, itemToRemove, selectedCategory);
        },
        err => {
          this.notificationService.notify(messages.error.deleteManualExclude, NotificationType.Error, err);
          this.eventBus.setRemovingManualExclude(false);
          console.log('Error updating cdm category for delete manual exclude: ', err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(
              `Error updating cdm category for delete manual exclude. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
            )
          });
        }
      );
  }

  private updateElasticCdmAfterDeleteManualExclude(
    res: any,
    itemToRemove: ChargeDescriptionListEdit,
    selectedCategory: Category
  ) {
    if (res.Status === 'Failed') {
      this.notificationService.notify(messages.error.deleteManualExclude, NotificationType.Error);
      console.log("Failed to update category in elastic: response status == 'Failed'");
      this.eventBus.setRemovingManualExclude(false);
    } else {
      setTimeout(() => {
        this.store.dispatch(new Actions.DeleteManualExclude(itemToRemove));
        this.eventBus.setRemovingManualExclude(false);
        this.notificationService.notify(messages.success.deleteManualException, NotificationType.Success);
        let updatedTimestamp = new Date().toISOString();
        this.subs.sink = this.functionAppService
          .couchbaseCopyAdfTrigger({ updatedTimestamp: updatedTimestamp })
          .subscribe(res => {
            console.log('couchbaseCopyAdfTrigger response: ', res);
          });
      }, 1000);
    }
  }

  /**
   * Deletes a manual include by the docId.
   */
  deleteManualInclude(itemToRemove: ChargeDescriptionListEdit) {
    this.eventBus.setRemovingManualInclude(true);
    let selectedCategory = this.getLastestSelectedCat();
    let user = this.store.selectSnapshot(UserState.userInfo);
    selectedCategory.modifiedBy = user.userId;
    itemToRemove.modifiedBy = user.userId;
    let idx = selectedCategory.chargeDescriptions.findIndex(desc => desc === itemToRemove.chargeDescriptionIds[0]);
    selectedCategory.chargeDescriptions.splice(idx, 1);
    this.appInsightsService.trackEvent({
      name: 'delete manual include',
      properties: {
        chargeDescriptionListEdit: itemToRemove,
        categoryName: selectedCategory.name,
        category: selectedCategory,
        user: { name: user.name, userid: user.userId }
      }
    });
    this.subs.sink = this.functionAppService.categoryDescriptionsManualRemove(itemToRemove).subscribe(
      (res: any) => {
        if (res) {
          setTimeout(() => {
            if (res && res[itemToRemove.chargeDescriptionIds[0]] && res[itemToRemove.chargeDescriptionIds[0]].Success) {
              selectedCategory.lastModified = res[itemToRemove.chargeDescriptionIds[0]].Success;
            }
            this.handleElasticDeleteManualInclude(selectedCategory, itemToRemove);
          }, 1000);
        }
      },
      err => {
        this.notificationService.notify(messages.error.deleteManualInclude, NotificationType.Error, err);
        this.eventBus.setRemovingManualInclude(false);
      }
    );
  }

  private handleElasticDeleteManualInclude(selectedCategory: Category, itemToRemove: ChargeDescriptionListEdit) {
    this.subs.sink = this.functionAppService
      .copyCategoryES2CB({ chargeCatId: [selectedCategory.docId], action: 'update' })
      .subscribe(
        res => {
          const newCatDictionary = clonedeep(this.getCategoryDictionary());
          newCatDictionary[selectedCategory.docId] = selectedCategory;
          this.store.dispatch([
            new Actions.UpdateCategoryAfterSave(selectedCategory),
            new Actions.SetCategoryDictionary(newCatDictionary),
            new Actions.SelectCategory(selectedCategory)
          ]);
          this.refreshCategoryAndVersions(selectedCategory);
          this.updateElasticCdmAfterDeleteManualInclude(res, itemToRemove, selectedCategory);
        },
        err => {
          this.notificationService.notify(messages.error.deleteManualInclude, NotificationType.Error, err);
          this.eventBus.setRemovingManualInclude(false);
          console.log('Error updating cdm category for delete manual include: ', err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(
              `Error updating cdm category for delete manual include. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
            )
          });
        }
      );
  }

  private updateElasticCdmAfterDeleteManualInclude(
    res: any,
    itemToRemove: ChargeDescriptionListEdit,
    selectedCategory: Category
  ) {
    if (res.Status === 'Failed') {
      this.notificationService.notify(messages.error.deleteManualInclude, NotificationType.Error);
      console.log("Failed to update category in elastic: response status == 'Failed'");
      this.eventBus.setRemovingManualInclude(false);
    } else {
      setTimeout(() => {
        this.store.dispatch(new Actions.DeleteManualInclude(itemToRemove));
        this.eventBus.setRemovingManualInclude(false);
        this.notificationService.notify(messages.success.deleteManualException, NotificationType.Success);
        let updatedTimestamp = new Date().toISOString();
        this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp: updatedTimestamp }).subscribe(res => {
          console.log('couchbaseCopyAdfTrigger response: ', res);
        });
      }, 1000);
    }
  }

  // /**
  //  * Gets the selected category by docId.
  //  * @param docId - DocId of the category selected in the category list.
  //  */
  async selectCategory(docId: string) {
    if (!this.n1qlEditorHasPendingChanges()) {
      const cat = await this.dataApiService.getChargeCategoryById(docId);
      this.appInsightsService.trackEvent({
        name: 'category selected',
        properties: {
          docId: docId,
          name: cat.name,
          category: cat
        }
      });
      if (cat) {
        this.store.dispatch(new Actions.SelectCategory(cat));
      }
    }
  }

  async refreshCategory(docId: string) {
    const result = await this.dataApiService.getChargeCategoryById(docId);
    if (result) {
      this.store.dispatch(new Actions.SelectCategory(result));
    }
  }

  // /**
  //  * Gets the selected category by docId.
  //  * @param docId - DocId of the category selected in the category list.
  //  */
  async selectCategoryVersion(catVersion: { category: Category; isLatest: boolean }) {
    //remove display only value for saving

    if (catVersion) {
      this.store.dispatch(new Actions.SelectCategory(catVersion.category));
    }
  }

  fetchSelectedCategoryVersions(chargeCatId: string) {
    return this.dataApiService.fetchCategoryVersions(chargeCatId).subscribe(categoryVersions => {
      this.eventBus.setCategoryVersions(categoryVersions);
    });
  }

  /**
   * Deletes a category by chargeCatId.
   * @param chargeCatId - chargeCatId of the category being deleted.
   */
  deleteCategory(chargeCatId: string) {
    this.subs.sink = this.functionAppService.deleteCategory({ categoryId: chargeCatId }).subscribe(
      () => {
        // On success, delete it from the store and check if the category being
        // deleted was the selected category.
        this.subs.sink = this.functionAppService
          .copyCategoryES2CB({ chargeCatId: [chargeCatId], action: 'delete' })
          .subscribe(
            () => {
              this.store.dispatch([
                new Actions.DeleteCategory(chargeCatId),
                new Actions.ResetSelectedCategoryIfDeleted(chargeCatId)
              ]);
              // Success message
              this.notificationService.notify(messages.success.deleteCategory, NotificationType.Success);
            },
            err => {
              this.notificationService.notify(messages.error.deleteCategory, NotificationType.Error, err);
              console.log('Error removing category in elastic: ', err);
            }
          );
      },
      // Error message
      err => this.notificationService.notify(messages.error.deleteCategory, NotificationType.Error, err)
    );
  }

  /**
   * @Action Adds a new category.
   *
   * @description Adds the new category then re-sorts the list.
   * @note The DataAPI doesn't have an 'Add Category' controller action but instead uses 'Update Category' and inserts
   * a new record if there is no chargeCatId on the category passed to it. It then returns the new object.
   */
  addCategory(category: Category) {
    this.setModifiedByUser(category);
    category.versionEditLog = 'Category added';
    this.subs.sink = this.functionAppService.addCategory(category).subscribe(
      (response: any) => {
        this.subs.sink = this.functionAppService
          .copyCategoryES2CB({
            chargeCatId: [response[category.name].success.docId],
            action: 'update'
          })
          .subscribe(
            res => {
              // Initialize the rules on the new category to an empty array
              response[category.name].success.rules = [];
              const newCategory = response[category.name].success;
              // Add new category to the list of categories, then sort
              const categoriesWithNewCategory = [newCategory, ...this.categoriesSnapshot()];
              const sortedCategories = this.sortCategories(categoriesWithNewCategory);

              // Update the categories in the store with the sorted list that includes the newly added one
              const { catDic, catDicHb, catDicPb, catDicDrgv, catDicCC } = this.getAllCategoryDictionaries();
              this.updateCategoryDictionaryies(newCategory, catDic, catDicHb, catDicPb, catDicDrgv, catDicCC);
              this.store.dispatch(new Actions.AddCategory(sortedCategories, newCategory));
              this.notificationService.notify(messages.success.addCategory, NotificationType.Success);
              this.refreshCategoryAndVersions(newCategory);
            },
            err => {
              this.notificationService.notify(messages.error.addCategory, NotificationType.Error, err);
              console.log('Error adding category in elastic: ', err);
            }
          );
      },
      err => this.notificationService.notify(messages.error.addCategory, NotificationType.Error, err)
    );
  }

  /**
   * Updates a category name.
   * TODO - Look into the category property passed in and see of the below comment is accurate.
   * @param category - This property is misleading, as the object being sent in is {name: 'newname'} and
   * is being coerced into a category object.
   */
  editCategory(category: Category) {
    category = Utils.omitDeep(category, '__typename');
    this.setModifiedByUser(category);

    this.subs.sink = this.functionAppService.validateAndUpdateCategory(category).subscribe(
      () => {
        this.subs.sink = this.functionAppService
          .copyCategoryES2CB({
            chargeCatId: [category.docId],
            action: 'update'
          })
          .subscribe(
            () => {
              this.store.dispatch(new Actions.EditCategory(category));
              this.refreshCategoryAndVersions(category);
              this.notificationService.notify(messages.success.editCategory, NotificationType.Success);
            },
            err => {
              this.notificationService.notify(messages.error.editCategory, NotificationType.Error, err);
              console.log('Error updating cat elastic: ', err);
            }
          );
      },
      err => this.notificationService.notify(messages.error.editCategory, NotificationType.Error, err)
    );
  }

  translateN1qlToElastic() {
    let category = clonedeep(this.getCategoryForDeltaQuery());
    this.checkForCptListKeywordInN1QL(category);
    return this.functionAppService.translateN1QL(category.chargeDescriptionSelectorN1QL);
  }

  private checkForCptListKeywordInN1QL(category: Category) {
    if (
      category &&
      category.chargeDescriptionSelectorN1QL &&
      category.chargeDescriptionSelectorN1QL.includes(Utils.cptListKeyword) &&
      category.cptList &&
      category.cptList.length > 0
    ) {
      while (category.chargeDescriptionSelectorN1QL.includes(Utils.cptListKeyword)) {
        category.chargeDescriptionSelectorN1QL = category.chargeDescriptionSelectorN1QL.replace(
          Utils.cptListKeyword,
          JSON.stringify(category.cptList).replace(/"/g, "'")
        );
      }
    }
  }

  fetchElasticDelta(elasticQuery: CategoryDeltaRequest) {
    return this.dataApiService.fetchChargeCategoryDelta(elasticQuery);
  }

  saveCategoryQuerySfJob(category: Category) {
    return this.saveN1QL();
  }

  private addJobToStore(jobId: string, delId: string, category: Category, updatedTimestamp) {
    const newJob = new FunctionAppJob();
    newJob.category = category;
    newJob.id = jobId;
    newJob.delId = delId;
    newJob.startDate = new Date();
    newJob.status = null;
    newJob.isPaused = false;
    newJob.updatedTimestamp = updatedTimestamp;
    this.store.dispatch(new JobActions.AddJob(newJob));
  }

  private updateJobInStore(job: FunctionAppJob) {
    this.store.dispatch(new JobActions.EditJob(job));
  }

  private removeJobFromStore(jobId: string) {
    this.store.dispatch(new JobActions.DeleteJob(jobId));
  }

  checkSaveJobStatus(jobIds: string[]) {
    return this.dataApiService.getElasticTaskStatus(jobIds);
  }

  checkActiveTenants(): void {
    this.dataApiService.fetchActiveRunningTenants().subscribe(t => {
      if (t.length > 0) {
        const tenantList = this.getTenantListSnapShot();
        const tenantFilter = tenantList.filter(tl => t.includes(tl.tenantName));
        const tenantDisplayNames = tenantFilter.map(t => t.displayName);
        this.store.dispatch(new Actions.setActiveRunningTenants(tenantDisplayNames));
      } else {
        this.store.dispatch(new Actions.setActiveRunningTenants(new Array()));
      }
    });
  }

  private hasJobRunTimeExpired(job: FunctionAppJob) {
    let now = new Date();
    const diffTime = Math.abs((now as any) - (job.startDate as any));
    const diffMinutes = Math.ceil(diffTime / (1000 * 60));
    console.log(job.category.name + 'Time Running Minutes: ' + diffMinutes.toString());
    if (diffMinutes >= 20) {
      this.removeJobFromStore(job.id);
      this.notificationService.notify(
        `Charge descriptions not updated for ${job.category.name}. Job did not complete in allotted time.`,
        NotificationType.Error
      );
      return true;
    }
    return false;
  }

  private saveN1QL() {
    this.loadingStateService.setIsSavingCategoryDelta(true);

    if (this.n1qlEditorHasPendingChanges()) {
      this.store.dispatch(new Actions.SetTempCategoryToSelectedCategory());
      this.store.dispatch(new Actions.SetModifiedByUserToSelectedCategory());
    }
    const user = this.store.selectSnapshot(UserState.userInfo);

    let selectedCategory = clonedeep(Utils.omitDeep(this.getSelectedCategory(), '__typename'));
    selectedCategory.versionEditLog = this.eventBus.versionEditLogSnapShot();
    this.checkForCptListKeywordInN1QL(selectedCategory);

    this.appInsightsService.trackEvent({
      name: 'Save N1QL from Category Definition Tab',
      properties: {
        userName: user.name,
        userId: user.userId,
        category: selectedCategory
      }
    });
    this.eventBus.setSavingN1QL(true);
    return (this.subs.sink = this.functionAppService
      .validateAndUpdateCategory(selectedCategory)
      .subscribe(
        (category: Category) => {
          const { catDic, catDicHb, catDicPb, catDicDrgv, catDicCC } = this.getAllCategoryDictionaries();
          this.updateCategoryDictionaryies(category, catDic, catDicHb, catDicPb, catDicDrgv, catDicCC);
          this.store.dispatch([
            new Actions.SelectCategory(category),
            new Actions.UpdateCategoryAfterSave(category),
            new Actions.N1QLEditorPendingChanges(false),
            new Actions.ClearTempCategory()
          ]);

          this.updateCatInElasticOnSuccess(selectedCategory);
        },
        err => {
          this.loadingStateService.setIsSavingCategoryDelta(false);
          this.notificationService.notify(messages.error.saveN1QLStatement, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error saving category N1QL. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`)
          });
        }
      )
      .add(() => {
        this.loadingStateService.setIsSavingCategoryDelta(false);
        this.eventBus.setSavingN1QL(false);
      }));
  }

  private updateCatInElasticOnSuccess(selectedCategory: any): any {
    return (this.subs.sink = this.functionAppService
      .copyCategoryES2CB({
        chargeCatId: [selectedCategory.docId],
        action: 'update'
      })
      .subscribe(
        res => {
          if (res.Status === 'Failed') {
            this.notificationService.notify(messages.error.saveN1QLStatement, NotificationType.Error);
            console.log("Failed to update category in elastic: response status == 'Failed'");
          } else {
            this.notificationService.notify(messages.success.saveN1QLStatement, NotificationType.Success);
            setTimeout(() => {
              this.updateCdmDataInElasticOnSuccess(selectedCategory);
            }, 1000);
          }
        },
        err => {
          this.notificationService.notify(messages.error.saveN1QLStatement, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(
              `Error updating category in elastic. Name: ${selectedCategory.name} Id: ${selectedCategory.docId}`
            )
          });
          console.log('Failed to update category in elastic', err);
        }
      ));
  }

  private updateCdmDataInElasticOnSuccess(category: any) {
    this.subs.sink = this.functionAppService
      .updateCDMElastic({ chargeCatId: [category.chargeCatId], action: 'refresh' })
      .subscribe(
        res => {
          if (res.Status === 'Failed') {
            console.log("Failed to update CDM in elastic: response status == 'Failed'");
          }
          let taskId = res[0][`chargeCat-${category.chargeCatId}`].addTaskId;
          let delTaskId = res[0][`chargeCat-${category.chargeCatId}`].delTaskId;
          let updatedTimestamp = res[0][`chargeCat-${category.chargeCatId}`].updatedTimestamp;
          this.refreshCategoryAndVersions(category);
          this.addJobToStore(taskId, delTaskId, category, updatedTimestamp);
          setTimeout(() => {
            this.subs.sink = this.functionAppService
              .couchbaseCopyAdfTrigger({ updatedTimestamp: updatedTimestamp })
              .subscribe(res => {
                console.log('couchbaseCopyAdfTrigger response: ', res);
              });
          }, 1000);
        },
        err => {
          this.notificationService.notify(messages.error.updateCDMElastic, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error updating cdm table in elastic. Name: ${category.name} Id: ${category.docId}`)
          });
          console.log('Failed to update CDM in elastic', err);
        }
      );
  }

  private refreshCategoryAndVersions(category: any) {
    this.refreshCategory(category.docId).then(() => {
      this.fetchSelectedCategoryVersions(category.chargeCatId);
    });
  }

  /**
   * Retrieves a sorted list of all categories with their associated rules.
   */
  async fetchCategories(refresh = false) {
    if (refresh || this.store.selectSnapshot(ChargeCatSelectors.allCategories).length === 0) {
      this.loadingStateService.setLoadingCategories(true);
      try {
        const categoriesWithRules = await this.getCategoriesAndRules();
        const categoriesWithRulesOnly = categoriesWithRules.filter((cat: Category) => cat.rules.length > 0);
        this.store.dispatch(new Actions.SetCategoriesWithRules(categoriesWithRulesOnly));
        this.store.dispatch(new Actions.SetCategories(categoriesWithRules));
      } catch (err) {
        this.notificationService.notify(messages.error.fetchCategories, NotificationType.Error, err);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(`Error fetching categories`)
        });
      } finally {
        this.loadingStateService.setLoadingCategories(false);
      }
    }
  }

  /**
   * Helper function for getting a snapsot of the SF jobs.
   */
  public jobsSnapshot(): FunctionAppJob[] {
    return this.store.selectSnapshot(FunctionAppJobSelectors.allJobs);
  }

  private getCategoryNameWithRuleTitlesList(): Promise<CategoryNameWithRuleTitlesList[]> {
    return this.dataApiService.getCategoryNameWithRuleTitlesList().toPromise();
  }

  /**
   * Gets categories and rules and maps them together.
   */
  private async getCategoriesAndRules(): Promise<any> {
    let categories = [];
    let rules = [];
    return await Promise.all([this.getSortedCategories(), this.getCategoryNameWithRuleTitlesList()]).then(result => {
      categories = result[0];
      rules = result[1];
      this.mapRulesToCategories(categories, rules);
      return categories;
    });
  }

  /**
   * Gets and sorts all categories
   */
  private async getSortedCategories(): Promise<Category[]> {
    const result = await this.dataApiService.fetchCategories();
    if (result) {
      return result as any;
    }
  }

  /**
   * Maps rules associated with categories to each category.
   */
  private mapRulesToCategories(sortedCategories: Category[], categoryRules: CategoryNameWithRuleTitlesList[]) {
    const catRuleDictionary = {};
    categoryRules.forEach(cat => {
      catRuleDictionary[cat.categoryName] = cat.ruleTitles;
    });
    const categoryDictionary = {};
    const categoryDictionaryHB = {};
    const categoryDictionaryPB = {};
    const categoryDictionaryDRGV = {};
    const categoryDictionaryCC = {};

    sortedCategories.forEach((category: Category) => {
      category.rules = [];
      category.displayName = category.name;
      categoryDictionary[category.docId] = category;
      if (category.name.includes('HB')) {
        categoryDictionaryHB[category.docId] = category;
        categoryDictionaryCC[category.docId] = category;
        this.hbCategoryIds.push(category.docId);
        this.ccCategoryIds.push(category.docId);
      }
      if (category.name.includes('PO') && !category.name.includes('HB')) {
        categoryDictionaryPB[category.docId] = category;
        categoryDictionaryCC[category.docId] = category;
        this.pbCategoryIds.push(category.docId);
        this.ccCategoryIds.push(category.docId);
      }
      if (!category.name.includes('HB') && !category.name.includes('PO')) {
        categoryDictionaryDRGV[category.docId] = category;
        this.drgvCategoryIds.push(category.docId);
      }
      if (catRuleDictionary[category.name]) {
        category.rules = catRuleDictionary[category.name];
      }
    });
    this.store.dispatch(new Actions.SetCategoryDictionary(categoryDictionary));
    this.store.dispatch(new Actions.SetCategoryDictionaryHB(categoryDictionaryHB));
    this.store.dispatch(new Actions.SetCategoryDictionaryPB(categoryDictionaryPB));
    this.store.dispatch(new Actions.SetCategoryDictionaryDRGV(categoryDictionaryDRGV));
    this.store.dispatch(new Actions.SetCategoryDictionaryCC(categoryDictionaryCC));
  }

  private updateCategoryDictionaryies(
    category: Category,
    categoryDictionary: any,
    categoryDictionaryHB: any,
    categoryDictionaryPB: any,
    categoryDictionaryDRGV: any,
    categoryDictionaryCC: any
  ) {
    categoryDictionary[category.docId] = category;
    if (category.name.includes('HB')) {
      categoryDictionaryHB[category.docId] = category;
      categoryDictionaryCC[category.docId] = category;
      if (this.hbCategoryIds.indexOf(category.docId) == -1) {
        this.hbCategoryIds.push(category.docId);
      }
      if (this.ccCategoryIds.indexOf(category.docId) == -1) {
        this.ccCategoryIds.push(category.docId);
      }
    }
    if (category.name.includes('PO') && !category.name.includes('HB')) {
      categoryDictionaryPB[category.docId] = category;
      categoryDictionaryCC[category.docId] = category;
      if (this.pbCategoryIds.indexOf(category.docId) == -1) {
        this.pbCategoryIds.push(category.docId);
      }
      if (this.ccCategoryIds.indexOf(category.docId) == -1) {
        this.ccCategoryIds.push(category.docId);
      }
    }
    if (!category.name.includes('HB') && !category.name.includes('PO')) {
      categoryDictionaryDRGV[category.docId] = category;
      if (this.drgvCategoryIds.indexOf(category.docId) == -1) {
        this.drgvCategoryIds.push(category.docId);
      }
    }
    this.store.dispatch(new Actions.SetCategoryDictionary(categoryDictionary));
    this.store.dispatch(new Actions.SetCategoryDictionaryHB(categoryDictionaryHB));
    this.store.dispatch(new Actions.SetCategoryDictionaryPB(categoryDictionaryPB));
    this.store.dispatch(new Actions.SetCategoryDictionaryDRGV(categoryDictionaryDRGV));
    this.store.dispatch(new Actions.SetCategoryDictionaryCC(categoryDictionaryCC));
  }

  /**
   * Adds the 'modified by' user property to a modified category using the userState.
   * TODO: Find a way to get rid of this and to replace with setModifiedByUserToSelectedCategory action
   */
  private setModifiedByUser(category: Category) {
    const user = this.store.selectSnapshot(UserState.userInfo);
    if (user) {
      category.modifiedBy = user.userId;
    }
  }

  /**
   * If there is a tempCategory, which contains the new temporary N1QL statement
   * that is to be run prior to actually saving it, use it, otherwise use the selectedCategory.
   */
  private getCategoryForDeltaQuery(): Category {
    const tempCategory = this.store.selectSnapshot(ChargeCatSelectors.getTempCategory);
    const category = tempCategory ? tempCategory : this.getSelectedCategory();
    return category;
  }

  /**
   * Sorts the list of categories by name.
   * @param categories - Categories to sort.
   */
  private sortCategories(categories: Category[]): Category[] {
    return categories.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0;
      }
    });
  }

  /**
   * Helper function for getting a snapsot of the categories.
   */
  private categoriesSnapshot(): Category[] {
    return this.store.selectSnapshot(ChargeCatSelectors.allCategories);
  }

  /**
   * Helper function for getting a snapsot of the isN1QLEditorDirty flag.
   */
  private n1qlEditorHasPendingChanges(): boolean {
    return this.store.selectSnapshot(ChargeCatSelectors.isN1QLEditorDirty);
  }

  /**
   * Helper function for getting a snapsot of the selected category.
   */
  private getSelectedCategory() {
    return this.store.selectSnapshot(ChargeCatSelectors.selectedCategory);
  }

  /**
   * Retrieves a list of tenants used in the CDM table for filtering.
   */
  fetchTenantList() {
    const tenants = this.store.selectSnapshot(ChargeCatSelectors.tenantList);
    if (tenants.length === 0) {
      this.store.dispatch(new Actions.ToggleLoadingTenantList());
      this.subs.sink = this.dataApiService.fetchAllTenants().subscribe(
        tenantList => {
          const filteredTenants = this.filterValidTenantStandings(tenantList).sort((a, b) =>
            a.displayName > b.displayName ? 1 : -1
          );
          this.store.dispatch(new Actions.SetTenantList(filteredTenants));
        },
        err => {
          this.notificationService.notify(messages.error.fetchTenantList, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error getting tenant list.`)
          });
        }
      );
    }
  }

  /**
   * Checks each tenant record's standing property against the TenantStandingList enum.
   * @returns A list of tenants with valid standings.
   * @param tenants - The list of tenants to check.
   */
  public filterValidTenantStandings(tenants: Tenant[]): Tenant[] {
    return tenants.filter(tenant => Object.values(TenantStandingList).includes(tenant.standing));
  }

  /**
   * Retrieves a list of unique CPT used in the CDM table for filtering and on the Category Difinition for CPT mapping
   * to a category.
   */
  fetchHcpcsCptList() {
    const cptList = this.store.selectSnapshot(ChargeCatSelectors.cptList);
    if (cptList.length === 0) {
      this.store.dispatch(new Actions.ToggleLoadingCptList());
      return (this.subs.sink = this.dataApiService.fetchHcpcsStandard().subscribe(
        data => {
          const hcpcsCptList = clonedeep(data);
          hcpcsCptList.forEach(item => {
            this.mapCptHcpsDisplayName(item);
          });
          const regEx = '[A-Za-z]';
          let filterCpt = hcpcsCptList.filter(item => !item.code.match(regEx));
          this.store.dispatch(new Actions.SetCptList(filterCpt));
          let filterHcpcs = hcpcsCptList.filter(item => item.code.match(regEx));
          this.store.dispatch(new Actions.SetHcpcsList(filterHcpcs));
          this.createAndStoreHcpcsDictionary(filterHcpcs);
          this.createAndStoreCptDictionary(filterCpt);
          this.createAndSetSiDictionary(hcpcsCptList);
          this.createAndSetAmaDescrDictionary(hcpcsCptList);
          this.createAndSetApcDictionary(hcpcsCptList);
        },
        err => {
          this.notificationService.notify(messages.error.fetchCptList, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error getting HCPCS CPT list`)
          });
        }
      ));
    }
  }

  private createAndStoreHcpcsDictionary(data: any) {
    const hcpcsDictionary = this.store.selectSnapshot(ChargeCatSelectors.getHcpcsDictionary);
    if (Utils.isEmptyObject(hcpcsDictionary)) {
      const dictionary = Utils.createDictionaryFromArrayByKey(data, 'code');
      this.store.dispatch(new Actions.SetHcpcsDictionary(dictionary));
    }
  }

  private createAndStoreCptDictionary(data: any) {
    const cptDictionary = this.store.selectSnapshot(ChargeCatSelectors.getCptDictionary);
    if (Utils.isEmptyObject(cptDictionary)) {
      const dictionary = Utils.createDictionaryFromArrayByKey(data, 'code');
      this.store.dispatch(new Actions.SetCptDictionary(dictionary));
    }
  }

  /**
   * Sets SI values for SI column filter list
   */
  private createAndSetSiDictionary(hcpcsCptList: any) {
    let siDictionary = {};
    hcpcsCptList.forEach(item => {
      if (Utils.hasValue(item.si)) {
        if (siDictionary[item.si]) {
          siDictionary[item.si].add(item.code);
        } else {
          siDictionary[item.si] = new Set();
          siDictionary[item.si].add(item.code);
        }
      }
    });
    for (let key in siDictionary) {
      siDictionary[key] = [...siDictionary[key]];
    }
    this.store.dispatch(new Actions.SetSiDictionary(siDictionary));
  }

  /**
   * Sets AMA Descriptions values for AMA Descriptions column filter list
   */
  private createAndSetAmaDescrDictionary(hcpcsCptList: any) {
    let amaDictionary = {};
    hcpcsCptList.forEach(item => {
      if (Utils.hasValue(item.description)) {
        if (amaDictionary[item.description]) {
          amaDictionary[item.description].add(item.code);
        } else {
          amaDictionary[item.description] = new Set();
          amaDictionary[item.description].add(item.code);
        }
      }
    });
    for (let key in amaDictionary) {
      amaDictionary[key] = [...amaDictionary[key]];
    }
    this.store.dispatch(new Actions.SetAmaDescrDictionary(amaDictionary));
  }

  /**
   * Sets Apc payment rate values for AMA Descriptions column filter list
   */
  private createAndSetApcDictionary(hcpcsCptList: any) {
    let apcDictionary = {};
    hcpcsCptList.forEach(item => {
      if (Utils.hasValue(item.paymentRate)) {
        if (apcDictionary[item.paymentRate]) {
          apcDictionary[item.paymentRate].add(item.code);
        } else {
          apcDictionary[item.paymentRate] = new Set();
          apcDictionary[item.paymentRate].add(item.code);
        }
      }
    });
    for (let key in apcDictionary) {
      apcDictionary[key] = [...apcDictionary[key]];
    }
    this.store.dispatch(new Actions.SetApcDictionary(apcDictionary));
  }

  /**
   * Retrieves a list of unique CPT used in the CDM table for filtering.
   *
   */
  fetchRevenueCodeList() {
    const revenueCodeList = this.store.selectSnapshot(ChargeCatSelectors.revenueCodeList);
    if (revenueCodeList.length === 0) {
      this.store.dispatch(new Actions.ToggleLoadingRevenueCodeList());
      this.subs.sink = this.dataApiService.fetchRevCodes().subscribe(
        revenueCodeList => {
          // const result = clonedeep(data);
          revenueCodeList.forEach(this.createRevenueCodeDisplayName());
          const revCodeDictionary = this.createRevCodeDictionary(revenueCodeList);
          this.store.dispatch(new Actions.SetRevenueCodeDictionary(revCodeDictionary));
          this.store.dispatch(new Actions.SetRevenueCodeList(revenueCodeList));
        },
        err => {
          this.notificationService.notify(messages.error.fetchRevenueCodeList, NotificationType.Error, err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error getting revenue code list.`)
          });
        }
      );
    }
  }

  /**
   * Retrieves a list of unique CPT used in the CDM table for filtering.
   *
   */
  fetchColumnUniqueFilterList(col: ColumnFilter, cdmPageFilter: CdmPageFilter) {
    return this.dataApiService.fetchCdmUniqueColumnData(cdmPageFilter, col.field, col.cbFilterField);
  }

  createRevCodeDictionary(revenueCodeList: RevenueCodeDescription[]) {
    let dictionary = {};
    revenueCodeList.forEach(rev => {
      dictionary[rev.code] = rev;
    });
    return dictionary;
  }

  applyCdmTableCategoryUpdate(updates: ChargeDescriptionListEdit) {
    this.eventBus.setCDMTableUpdatesLoading(true);
    const { cdmChargeDescriptions, categories } = this.getCategoriesAndDescFromStore();
    let chargeDescriptionsToModify = updates.chargeDescriptionIds
      .map(docId => cdmChargeDescriptions.find(c => c.docId === docId))
      .filter(docId => docId);
    let categoriesToModify = updates.categoryIdsForEdit
      .map(docId => categories.find(c => c.docId === docId))
      .filter(docId => docId);
    const user = this.store.selectSnapshot(UserState.userInfo);
    updates.modifiedBy = user.userId;

    this.subs.sink = this.functionAppService.updateCategoryDescriptionsManualApply(updates).subscribe(
      res => {
        this.onSuccessOfManualApply(categoriesToModify, chargeDescriptionsToModify, updates);
      },
      err => {
        this.onErrorOfManualApply(err, updates);
      }
    );
  }

  applyCdmTableCategorySingleUpdate(updates: ChargeDescriptionSingleUpdateEdit) {
    this.eventBus.setCDMTableUpdatesLoading(true);
    const { cdmChargeDescriptions, categories } = this.getCategoriesAndDescFromStore();
    let chargeDescriptionsToModify = updates.chargeDescriptionIds
      .map(docId => cdmChargeDescriptions.find(c => c.docId === docId))
      .filter(docId => docId);
    let categoriesToModify = [...updates.categoryIdsToAdd, ...updates.categoryIdsToDelete]
      .map(docId => categories.find(c => c.docId === docId))
      .filter(docId => docId);
    const user = this.store.selectSnapshot(UserState.userInfo);
    updates.modifiedBy = user.userId;

    this.subs.sink = this.functionAppService.updateCategoryDescriptionsManualApplySingle(updates).subscribe(
      res => {
        this.onSuccessOfManualApply(categoriesToModify, chargeDescriptionsToModify, updates);
      },
      err => {
        this.onErrorOfManualApply(err, updates);
      }
    );
  }

  private onErrorOfManualApply(err: any, updates: ChargeDescriptionSingleUpdateEdit | ChargeDescriptionListEdit) {
    this.notificationService.notify(
      `${messages.error.applyCdmTableCategoryUpdate}: ${err.error}`,
      NotificationType.Error,
      err
    );
    this.eventBus.setCDMTableUpdatesLoading(false);
    console.log('Error applying cdm table category updates: ', err.error);
    this.appInsightsService.trackException({
      exception: err,
      error: new Error(`Error applying cdm table category updates. updates: ${JSON.stringify(updates)}`)
    });
  }

  private onSuccessOfManualApply(
    categoriesToModify: Category[],
    chargeDescriptionsToModify: CdmChargeDescription[],
    updates: ChargeDescriptionSingleUpdateEdit | ChargeDescriptionListEdit
  ) {
    this.store.dispatch(new Actions.ApplyCdmTableCategoryUpdate(categoriesToModify, chargeDescriptionsToModify));
    this.updateCatRequestChainFromCdmTable(categoriesToModify, updates.chargeDescriptionIds);
  }

  private updateCatRequestChainFromCdmTable(updateCategoryElasticRequests: any[], updateCdmDataElasticRequests: any[]) {
    this.subs.sink = this.functionAppService
      .copyCategoryES2CB({ chargeCatId: updateCategoryElasticRequests, action: 'update' })
      .subscribe(
        res => {
          setTimeout(() => {
            let updatedTimestamp = new Date().toISOString();
            this.functionAppService.couchbaseCopyAdfTrigger({ updatedTimestamp: updatedTimestamp }).subscribe(res => {
              console.log('couchbaseCopyAdfTrigger response: ', res);
              this.eventBus.setCDMTableUpdatesLoading(false);
            });
          }, 1000);
        },
        err => {
          console.log('Error updating category to elastic from cdm table: ', err);
          this.appInsightsService.trackException({
            exception: err,
            error: new Error(`Error updating category to elastic from cdm table.`)
          });
          this.eventBus.setCDMTableUpdatesLoading(false);
        }
      );
  }

  private getCategoriesAndDescFromStore() {
    const categories = this.categoriesSnapshot();
    const cdmChargeDescriptions = this.store.selectSnapshot<CdmTableData>(ChargeCatSelectors.cdmTableData).cdmData;
    return { cdmChargeDescriptions, categories };
  }

  fetchCdmDataPaged(pageFilterParams: CdmPageFilter) {
    if (this.hasFilterTermsExceededLimit(pageFilterParams)) {
      return;
    }

    this.subs.sink = this.dataApiService.fetchCdmDataPaged(pageFilterParams).subscribe(
      (res: CdmTableResponse) => {
        this.store.dispatch(new Actions.ClearCdmTableData());
        this.store.dispatch(new Actions.SetCdmTableData({ cdmData: res.chargeDescriptions, offset: 0 }));
        this.store.dispatch(new Actions.SetCdmDataPageCount({ cdmDataPagedCount: res.totalCount } as any)); //TODO: This shouldn't be necessary but subscriptions are watching "cdmDataPagedCount"
        this.eventBus.setCDMTableLoading(false);
      },
      err => {
        this.notificationService.notify(messages.error.fetchCdmPagedData, NotificationType.Error, err);
        this.eventBus.setCDMTableLoading(false);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(`Error fetching cdm data page. Params: ${JSON.stringify(pageFilterParams)}`)
        });
      }
    );
  }

  fetchCdmDataPagedExport(pageFilterParams: CdmPageFilter) {
    return (this.subs.sink = this.dataApiService.fetchCdmDataPagedExport(pageFilterParams).subscribe(
      (data: CdmChargeDescription[]) => {
        this.store.dispatch(new Actions.ClearCdmTableData());
        this.store.dispatch(new Actions.SetCdmTableData({ cdmData: data, offset: 0 }));
      },
      err => {
        this.notificationService.notify(messages.error.fetchCdmPagedData, NotificationType.Error, err);
        this.eventBus.setCDMTableLoading(false);
        this.appInsightsService.trackException({
          exception: err,
          error: new Error(`Error fetching cdm data page. Params: ${JSON.stringify(pageFilterParams)}`)
        });
      }
    ));
  }

  private hasFilterTermsExceededLimit(pageFilterParams: CdmPageFilter) {
    let filterTermsCount = 0;
    pageFilterParams.filters.forEach(filter => {
      filterTermsCount += filter.selectedItems.length;
    });
    if (pageFilterParams.doNotIncludeForServiceLine) {
      filterTermsCount += pageFilterParams.doNotIncludeForServiceLine.length;
    }
    if (filterTermsCount >= 65536) {
      this.notificationService.notify(messages.error.maxTermsExceeded, NotificationType.Error);
      return true;
    }
    return false;
  }

  private subscribeToCdmDictionaryCreate() {
    this.subs.sink = combineLatest([this.selectedCategory$, this.eventBus.categoryVersions$()])
      .pipe(
        tap(([selectCategory, versions]) => {
          this.createDictionaries(selectCategory, versions);
          console.log({ includes: this.includesDict, excludes: this.excludesDict });
        })
      )
      .subscribe();
  }

  private createDictionaries(selectedCategory: Category, versions: Category[]): CategoryExt[] {
    console.time('creatDictionaries');
    let versionsToMutate = JSON.parse(JSON.stringify(versions));
    let versionsAsc = this.createVersionsArrayForDelta(versionsToMutate, selectedCategory);
    // make a dictionary of kv => key: cdmItem.docId, value: [...version.chargeDescriptions]
    // make a dictionary of kv => key: cdmItem.docId, value: [...version.chargeDescriptionsExclusions]
    const includesDict: CdmDict = {};
    const excludesDict: CdmDict = {};
    const versionsDict: CatVersionDict = {};

    // populate include / exclude dictionaries
    for (let i = 0; i < versionsAsc.length; i++) {
      const version = versionsAsc[i];
      // make versions dict
      versionsDict[i] = version;
      if(version.chargeDescriptions?.length) {
        for (const cdmDocId of version.chargeDescriptions) {
          if(!includesDict[cdmDocId]) {
            includesDict[cdmDocId] = { lastModified: version.lastModified, modifiedBy: version.modifiedBy }
          }
          
          
        }
      }
     
      if(version.chargeDescriptionsExclusions?.length) {
        for (const cdmDocId of version.chargeDescriptionsExclusions) {
          if(!excludesDict[cdmDocId]) {
            excludesDict[cdmDocId] = { lastModified: version.lastModified, modifiedBy: version.modifiedBy }
          }
         
        }
      }
      
    }
    this.includesDict = includesDict;
    this.excludesDict = excludesDict;
    this.versionsDict = versionsDict;
    return versionsAsc;
  }

  /**
   * Modifies CDM charge description tenant and category names for display.
   * @param chargeDescriptions A list of charge descriptions to modify.
   */
  public modifyCdmDataForDisplay(chargeDescriptions: CdmChargeDescription[], serviceLine: string) {
    chargeDescriptions.map(cdmItem => {
      this.mapCategoriesToCdmData(cdmItem, serviceLine);
      this.mapAdditionalGridColumns(cdmItem);
      this.mapTokenCommaList(cdmItem);
    });
  }

  mapTokenCommaList(cdmItem: CdmChargeDescription) {
    if (cdmItem.tokens && cdmItem.tokens.length > 0) {
      cdmItem.tokenCommaList = cdmItem.tokens.join(', ');
    }
  }

  getCategoryDictionary() {
    return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionary);
  }

  getAllCategoryDictionaries(): { catDic: any; catDicHb: any; catDicPb: any; catDicDrgv: any; catDicCC: any } {
    const catDic = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionary));
    const catDicHb = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryHB));
    const catDicPb = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryPB));
    const catDicDrgv = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryDRGV));
    const catDicCC = clonedeep(this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryCC));
    return { catDic, catDicHb, catDicPb, catDicDrgv, catDicCC };
  }

  /**
   * Sets the values for the SI, APC (payment rate), and AMA Description columns.
   * @description If the charge description has a CPT or HCPCS value, it finds it in the
   * corresponding dictionary and grabs the needed values off of that (SI, APC, AMA Description),
   * then assigns them to the charge description.
   *
   * @param cdmItem - Current charge description
   * @param cptDictionary - Lookup dictionary for the current charge description's CPT value
   * @param hcpcsDictionary - Lookup dictionary for the current charge description's HCPCS value
   */
  mapAdditionalGridColumns(cdmItem: ChargeDescription, addUserData?: boolean) {
    const cptDictionary = this.store.selectSnapshot(ChargeCatSelectors.getCptDictionary);
    const hcpcsDictionary = this.store.selectSnapshot(ChargeCatSelectors.getHcpcsDictionary);
    let info: CptHcpcsCodeDescription = null;

    if (Utils.hasValue(cdmItem.cpt)) {
      info = cptDictionary[cdmItem.cpt];
    }
    if (Utils.hasValue(cdmItem.hcpcs)) {
      info = hcpcsDictionary[cdmItem.hcpcs];
    }
    if (info) {
      cdmItem.si = info.si;
      cdmItem.paymentRate = info.paymentRate;
      cdmItem.amaDescription = info.description;
    }
    if (addUserData) {
      this.handleUserManualInExDelta(cdmItem, this.includesDict, this.excludesDict, this.versionsDict);
    }
    this.mapTenantDisplayName(cdmItem);
  }

  private handleUserManualInExDelta(
    cdmItem: ChargeDescription,
    includesDict: CdmDict,
    excludesDict: CdmDict,
    versionsDict: CatVersionDict
  ) {
    const modifiedByDictionary = this.eventBus.usersModifiedByVerDicSnapShot();
    const selectedCategory = this.getSelectedCategory();
    if (modifiedByDictionary && modifiedByDictionary[selectedCategory?.modifiedBy]) {
      const versions = this.eventBus.categoryVersionsSnapShot();
      if (versions.length == 0) {
        cdmItem.modifiedBy = modifiedByDictionary[selectedCategory.modifiedBy].name;
        cdmItem.lastModified = selectedCategory.lastModified;
      } else {
       
        cdmItem.modifiedBy =  modifiedByDictionary[this.includesDict[cdmItem.docId]?.modifiedBy]?.name
         || 
         modifiedByDictionary[this.excludesDict[cdmItem.docId]?.modifiedBy]?.name;
        cdmItem.lastModified = this.includesDict[cdmItem.docId]?.lastModified ||
        this.excludesDict[cdmItem.docId]?.lastModified;
      }
    } else {
      cdmItem.lastModified = selectedCategory.lastModified;
    }
  }

  /**
   * If the chargeDescription has a tenant, show the tenant's diplayname by mapping it to the item.
   * @param cdmItem - The selected item in the CDM grid.
   */
  private mapTenantDisplayName(cdmItem: ChargeDescription | CdmChargeDescription) {
    const tenantList = this.store.selectSnapshot(ChargeCatSelectors.tenantList);
    const tenant = tenantList.find(t => t.tenantName === cdmItem.tenantName);
    if (tenant) {
      if (!cdmItem.tenantDisplayName) {
        cdmItem.tenantDisplayName = tenant.displayName;
      }
    }
  }

  public getTenantListSnapShot() {
    return this.store.selectSnapshot(ChargeCatSelectors.tenantList);
  }

  /**
   * Maps associated categories to chargeDescriptions for displaying
   * in the CDM table Category column.
   *
   * @description Loops over the collection of chargeCategoryIds on a chargeDescription and
   * for each one, finds the category object.  The catgory name is then updated
   * to append the associated charge source to it.
   *
   * @param cdmItem - The current chargeDescription in the loop.
   * @param categories - The full list of categories.
   */
  private mapCategoriesToCdmData(cdmItem: CdmChargeDescription, serviceLine: string) {
    if (cdmItem.categoryNames === undefined) {
      cdmItem.categoryNames = '';
    }
    if (cdmItem.chargeCategoryIds) {
      let categoryDictionary = this.getServiceLineSpecificCategories(serviceLine);
      cdmItem.chargeCategoryIds.forEach((id: string, index: number) => {
        const category = categoryDictionary[id];
        if (category) {
          this.addCommasToCdmTableCategoryNames(id, cdmItem, category.name);
        }
      });
    }
  }

  public getServiceLineSpecificCategories(serviceLine: string) {
    if (serviceLine === 'all') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionary);
    }
    if (serviceLine === 'hb') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryHB);
    }
    if (serviceLine === 'pb') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryPB);
    }
    if (serviceLine === 'drgv') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryDRGV);
    }
    if (serviceLine === 'cc') {
      return this.store.selectSnapshot(ChargeCatSelectors.categoryDictionaryCC);
    }
  }

  /**
   * Adds a comma separator to multiple category names.
   *
   * @param id - The current chargeCategoryId of the selected charge description.
   * @param cdmItem - The selected item in the CDM grid.
   * @param modifiedCategoryName - The modifided category name.
   */
  private addCommasToCdmTableCategoryNames(id: string, cdmItem: CdmChargeDescription, modifiedCategoryName: string) {
    let separator = ', ';
    const isLastChargeCategoryId = id === cdmItem.chargeCategoryIds[cdmItem.chargeCategoryIds.length - 1];
    if (isLastChargeCategoryId) {
      separator = '';
    }
    if (!cdmItem.chargeCategoryCommaList) {
      cdmItem.chargeCategoryCommaList = '';
    }
    cdmItem.chargeCategoryCommaList += modifiedCategoryName + separator;
  }

  /**
   * Maps CPT and HCPCS codes and descriptions to a display name which combines both.
   * @example displayName = '1234 - Description - SI - Payment Rate'
   */
  private mapCptHcpsDisplayName(item): any {
    // Default the display name to `code - description`
    let displayName = `${item.code} - ${item.description}`;

    // If there is an SI but no Payment Rate, append the SI in parenthesis
    if (item.si !== '' && item.paymentRate === '') {
      displayName = `${displayName}  (${item.si})`;
    }

    // If there is an Payment Rate but no SI, append the Payment Rate in brackets
    if (item.si === '' && item.paymentRate !== '') {
      displayName = `${displayName} [${item.paymentRate}]`;
    }

    // If there is an Payment Rate and SI, append them both
    if (item.si !== '' && item.paymentRate !== '') {
      displayName = `${displayName} (${item.si}) [${item.paymentRate}]`;
    }
    item.displayName = displayName;
  }

  /**
   * Creates a display name for showing revenue codes in the CDM Table.
   */
  private createRevenueCodeDisplayName() {
    return (item: RevenueCodeDescription) =>
      (item.displayName = item.code + ' - ' + item.category + ' - ' + item.description);
  }

  /**
   * Gets list of tenants with active chargeCat  web job
   */
  private async getTenantList(): Promise<String[]> {
    const result = await this.dataApiService.fetchActiveRunningTenants();
    if (result) {
      return result as any;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
