import { CptHcpcsCodeDescription } from '../charge-cat/store/app/app-state.model';

export class Utils {
  static cptListKeyword = '$CptList';
  /**
   * Returns an empty array of *n* length
   * @example
   *  myArray = Utils.emptyArrayOf(15);
   * @param len Desired length of the array
   */
  static emptyArrayOf(len: number): number[] {
    return Array.from(Array(len).keys());
  }

  /**
   * Converts a string to title case
   * @example
   *  Utils.toTitleCase(myString) // MyString
   * @param str String to convert to titleCase
   */
  static toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  /**
   * Removes all whitespace from a string
   * @example
   * Utils.removeWhiteSpace(This Is The String); // ThisIsTheString
   *
   * @param str String to remove the whitespace from
   */
  static removeWhiteSpace(str: string): string {
    return str.replace(/\s+/g, '');
  }

  /**
   * Recursivley interates through an object (and removes a property) or an array (and removes an item).
   * @param value Object or array to remove items from
   * @param key Item to remove
   */
  static omitDeep(value, key) {
    if (Array.isArray(value)) {
      return value.map(i => this.omitDeep(i, key));
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).reduce((newObject, k) => {
        if (k === key) {
          return newObject;
        }
        return Object.assign({ [k]: this.omitDeep(value[k], key) }, newObject);
      }, {});
    }
    return value;
  }

  /**
   * Converts an array of objects into a dictionary object where the given key is used
   * as the new object's key, with the rest of the object as that key's value.
   *
   * @example
   * key = 'code'
   * [{code: 1, name: 'one'}, {code: 2, name: 'two'}]
   * becomes
   * {1: {code: 1, name: 'one'}, 2: {code: 2, name: 'two'}}
   * @param arr - Array to convert.
   * @param key - String value of the property on the objects in the array to use as the key.
   */
  static createDictionaryFromArrayByKey(arr: CptHcpcsCodeDescription[], key: string) {
    return Object.assign({}, ...arr.map(item => ({ [item[key]]: item })));
  }

  /**
   * Takes an array of strings and converts it to an array of objects with a value property
   * @example ['one', 'two'] => [{ value: 'one' }, { value: 'two' }]
   * @param arr Array of strings to iterate through
   */
  static stringArrayToObjectArray(arr: string[]) {
    return arr.map((value: string) => ({ value }));
  }

  /**
   * Returns the minutes and seconds that have elapsed since the timer started.
   * @note Be sure to use clearInterval() in the caller of this method.
   * @param timerStart performance.now() value to be used for starting the timer
   */
  static elapsedTime(timerStart: number) {
    const usedTicks = performance.now() - timerStart;
    const minutes = ((usedTicks / 60000) | 0) % 60;
    const seconds = ((usedTicks / 1000) | 0) % 60;
    return (minutes < 10 ? '0' : '') + minutes.toString() + ':' + (seconds < 10 ? '0' : '') + seconds.toString();
  }

  static isAlphaKeyCode(event: KeyboardEvent) {
    return event.keyCode >= 65 && event.keyCode <= 90;
  }

  static isPastedTextNumeric(event: ClipboardEvent) {
    const pastedText = event.clipboardData.getData('text');
    const isnum = /^\d+$/.test(pastedText);
    return isnum;
  }

  /**
   * Checks if a string has a value by looking for undefined, null, or empty.
   * @param str - The string to evaluate.
   */
  static hasValue(str: string): boolean {
    return str && str !== '' && str !== null;
  }

  /**
   * Checks if an object is empty by looking at the length of its keys.
   * @param obj - The object to evaluate.
   */
  static isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0;
  }

  /**
   * Converts a name into initials.
   * @description This will acommodate one and two word names.  If the
   * name contains more than two words, it will truncate it to just the first and last.
   *
   * @example
   * 'Tommy Lee Jones' => 'TJ'
   * 'Tom Jones' => 'TJ'
   * @returns User's first and last name intials
   */
  static getInitials(name: string): string {
    return Utils.checkNameLength(name)
      .replace(/[^a-zA-Z- ]/g, '')
      .match(/\b\w/g)
      .join('')
      .toUpperCase();
  }

  /**
   * If a name is more than two words long, set it to just
   * the first and last names.
   *
   * @param name - Name to check the length of
   * @returns First and last name
   */
  private static checkNameLength(name: string): string {
    const names = name.split(' ');
    if (names.length > 2) {
      name = names[0] + ' ' + names[names.length - 1];
    }
    return name;
  }
}
