import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { NotificationType } from 'src/app/charge-cat/shared/enums';
import { ApplicationInsightsService } from './app-insights-service.service';

/**
 * A service that is used for displaying global messaging.  It currently only displays toastr messages.
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private toastr: ToastrService, private appInsightsService: ApplicationInsightsService) {}

  /**
   * Triggers toastrs
   *
   * @param message The message to show in the alert
   * @param type The type of message. NotificationType enum (error | info | success | warning )
   * @param error Used for error types.  Holds the actual error object for logging.
   */
  notify(message: string, type: NotificationType, error?: any) {
    if (message) {
      switch (type) {
        case NotificationType.Success:
          this.toastr.success(message, 'Success', {
            timeOut: 2000
          });
          break;
        case NotificationType.Warning:
          this.toastr.warning(message, '', {
            timeOut: 300000
          });
          break;
        case NotificationType.Info:
          this.toastr.info(message, 'Info', {
            timeOut: 3000
          });
          break;
        case NotificationType.Error:
          this.toastr.error(message, 'Error', {
            timeOut: 0,
            tapToDismiss: true,
            closeButton: true,
            disableTimeOut: true
          });
          if (error) {
            error.networkError ? console.error(error.networkError) : console.error(error);
            this.appInsightsService.trackException({ error });
          }
          break;
      }
    }
  }
}
