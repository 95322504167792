import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ChargeDescriptionListEdit, ChargeDescriptionSingleUpdateEdit } from '../shared/models';
import { Category } from 'src/app/_shared/models';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class FunctionAppService {
  headers = new HttpHeaders({
    'x-functions-key': environment.config.functionAppKey
  });

  constructor(private readonly http: HttpClient, private readonly authService: AuthService) {
    this.authService.getAccessTokenSilently({ audience: environment.config.auth0.clientId }).subscribe(token => {
      this.headers = this.headers.append('Authorization', `Bearer ${token}`);
    });
  }

  /**
   * Creates elastic query from category's n1ql.
   * @param n1ql - charge category n1ql string.
   */
  translateN1QL(n1ql: string): Observable<any> {
    let body = {
      chargeCatN1QL: n1ql
    };
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/translaten1qltoelastic`, body, {
      headers: this.headers
    });
  }

  /**
   * Updates a category.
   * @param category - the category to update.
   */
  // updateCategoryElastic2(category: { chargeCatId: string[] }): Observable<any> {
  //   return this.http.post<any>(`${environment.config.functionAppUrl}/api/ChargeCatElasticUpdate`, category, {
  //     headers: this.headers
  //   });
  // }

  /**
   * Category description manual apply updates.
   * @param updates - The documents to update.
   */
  updateCategoryDescriptionsManualApply(updates: ChargeDescriptionListEdit): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/CategoryDescriptionsManualApply`, updates, {
      headers: this.headers
    });
  }

  /**
   * Category description manual apply updates single edit mode.
   * @param updates - The documents to update.
   */
  updateCategoryDescriptionsManualApplySingle(updates: ChargeDescriptionSingleUpdateEdit): Observable<any> {
    return this.http.post<any>(
      `${environment.config.functionAppUrl}/api/CategoryDescriptionsManualSingleEdit`,
      updates,
      {
        headers: this.headers
      }
    );
  }

  categoryDescriptionsManualRemove(updates: ChargeDescriptionListEdit): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/CategoryDescriptionsManualRemove`, updates, {
      headers: this.headers
    });
  }

  /**
   * Updates a category.
   * @param category - The category to update.
   * @param action - update or delete action.
   */
  copyCategoryES2CB(category: { chargeCatId: string[]; action?: string }): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/CopyCategoryES2CB`, category, {
      headers: this.headers
    });
  }

  /**
   * Updates charge descriptions from categories.
   * @param categoryAndAction - The updated categories ({ "chargeCatId": string[], "action": "refresh | manual" }).
   */
  updateCDMElastic(categoryAndAction: { chargeCatId: string[]; action: string }): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/UpdateCDMElastic`, categoryAndAction, {
      headers: this.headers
    });
  }

  /**
   * Charge cat Couchbase Copy ADF Trigger
   * @param timestamp - timestamp.
   */
  couchbaseCopyAdfTrigger(timestamp: { updatedTimestamp: string }): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/ChargeCatCBCopyADFTrigger`, timestamp, {
      headers: this.headers
    });
  }

  /**
   * Delete Category
   * @param id - category Id.
   */
  deleteCategory(id: { categoryId: string }): Observable<any> {
    return this.http.request<any>('delete', `${environment.config.functionAppUrl}/api/category`, {
      body: id,
      headers: this.headers
    });
  }

  /**
   * Add Category
   * @param category - Category.
   */
  addCategory(category: Category): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/category`, category, {
      headers: this.headers
    });
  }

  /**
   * Add Category
   * @param category - Category.
   */
  validateAndUpdateCategory(category: Category): Observable<any> {
    return this.http.post<any>(`${environment.config.functionAppUrl}/api/ValidateAndUpdateCategory`, category, {
      headers: this.headers
    });
  }
}
