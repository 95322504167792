export enum ChargeCategorySource {
  none = 0,
  N1QL = 1,
  Manual = 2
}

export enum NotificationType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

// export enum Columns {
//   Category = 'Category',
//   Tenant = 'Tenant',
//   RevCode = 'Rev Code',
//   HCPCS = 'HCPCS',
//   CPT = 'CPT',
//   ChargeCode = 'Charge Code',
//   Description = 'Description',
// }

export const enum RuleType {
  Unknown = 0,
  Linq = 1,
  HQL1 = 2,
  N1QL = 3
}

export enum TenantStandingList {
  Active = 'Active',
  Assessment = 'Assessment',
  Demo = 'Demo',
  TestCC = 'TestCC'
}

export enum CdmTypes {
  HIM = 'HIM',
  Hospital = 'Hospital',
  Physician = 'Physician'
}
