import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';

import { Category } from 'src/app/_shared/models/category';
import { ChargeDescription } from 'src/app/_shared/models/charge-description';
import { ClipboardOptions } from '../../shared/charge-description-grid/grid-clipboard-options';
import { ChargeCatSelectors } from '../../store/app/app.selectors';
import { Table } from 'primeng/table';
import { Utils } from 'src/app/_shared/utils';
import { CellAlignments } from 'src/app/_shared/models/column-filter';

@Component({
  selector: 'cm-category-delta-grid',
  templateUrl: './category-delta-grid.component.html',
  styleUrls: ['./category-delta-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoryDeltaGridComponent implements OnInit {
  @Input() data: ChargeDescription[] = [];
  @Input() dataCount = 0;
  @Input() toolbarTitle = '';
  @Input() gridId = '';
  @Input() selectedCategory: Category;
  @ViewChild('deltagrid') table: Table;

  @SelectSnapshot(ChargeCatSelectors.allCategories) categoriesSnapshot: Category[];

  categories = new Array<Category>();
  clipboardOptions = ClipboardOptions;
  _selectedColumns: any[];

  cols = [
    {
      field: 'tenantDisplayName',
      header: 'Tenant',
      width: '240px',
      uniqueData: [],
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'revenueCode',
      header: 'Rev Code',
      width: '130px',
      uniqueData: [],
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'hcpcs',
      header: 'HCPCS',
      width: '130px',
      uniqueData: [],
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'cpt',
      header: 'CPT',
      width: '100px',
      uniqueData: [],
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'si',
      header: 'SI',
      width: '100px',
      uniqueData: [],
      cellAlignment: CellAlignments.Center
    },
    {
      field: 'paymentRate',
      header: 'APC',
      width: '100px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'chargeCode',
      header: 'Charge Code',
      width: '150px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'description',
      header: 'Description',
      width: '425px',
      uniqueData: [],
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'amaDescription',
      header: 'AMA Description',
      width: '275px',
      uniqueData: [],
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'homegrownDescription',
      header: 'Homegrown Description',
      width: '275px',
      uniqueData: [],
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'descriptionType',
      header: 'Type',
      width: '135px',
      uniqueData: [],
      cellAlignment: CellAlignments.Left
    },
    {
      field: 'avgDollar',
      header: 'Average Dollar',
      width: '165px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'avgQuant',
      header: 'Average Quantity',
      width: '180px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'totalDollar',
      header: 'Total Dollar',
      width: '150px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'chargeCount',
      header: 'Charge Count',
      width: '165px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    },
    {
      field: 'totalQuant',
      header: 'Total Quantity',
      width: '165px',
      uniqueData: [],
      cellAlignment: CellAlignments.Right
    }
  ];

  ngOnInit(): void {
    this.selectedColumns = [...this.cols];
    this.cols.forEach(col => {
      this.setColumnListData(col, this.data);
    });
  }

  onMultiSelectFilter(event, field) {
    this.table.filter(event.value, field, 'in');
  }

  setColumnListData(col, gridData: any) {
    gridData.forEach(row => {
      if (!Utils.hasValue(row[col.field])) {
        row[col.field] = '';
      }
      if (row[col.field] !== '' && !col.uniqueData.some(existingRow => existingRow.value === row[col.field])) {
        col.uniqueData.push({ label: row[col.field], value: row[col.field] });
      }
    });
    col.uniqueData = col.uniqueData.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    col.uniqueData.unshift({ label: 'Filter On Blank', value: '' });
  }

  @Input() get selectedColumns(): any[] {
    return this._selectedColumns;
  }

  set selectedColumns(val: any[]) {
    this._selectedColumns = this.cols.filter(col => val.includes(col));
  }
}
