import { ChargeCatAppState } from '../../charge-cat/store/app/app.state';
import { ChargeCatErrorState } from '../../charge-cat/store/errors/error.state';
import { ChargeCatLoadingState } from '../../charge-cat/store/loading/loading.state';
import { FunctionAppJobState } from '../../charge-cat/store/function-app-job/function-app-job.state';

/**
 * For the moment, since Charge Cat is the only application in Auditing Tools, it isn't lazy loaded,
 * so all the the states can be placed in the NgxsModule.forRoot() import.  This class is
 * using the roll-up pattern, essentially putting multiple states into an array which can then
 * be placed into the forRoot() array in CoreModule.
 */
export const ChargeCatState = [ChargeCatAppState, ChargeCatErrorState, ChargeCatLoadingState, FunctionAppJobState];
