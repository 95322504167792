// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

// 3rd party

// App
import { AppMaterialModule } from 'src/app/app-material.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { HeaderComponent } from './header.component';
import { SharedModule } from 'src/app/_shared/shared.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [BrowserAnimationsModule, CommonModule, FormsModule, AppMaterialModule, AppRoutingModule, SharedModule],
  exports: [HeaderComponent]
})
export class HeaderModule {}
