import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ChargeCatComponent } from './charge-cat/charge-cat.component';
import { CategoryDefinitionComponent } from './charge-cat/category-definition/category-definition.component';
import { LoginComponent } from './_core/components/login/login.component';
import { CanDeactivateGuard } from './_core/guards/can-deactivate.guard';
import { NotFoundComponent } from './_core/components/not-found/not-found.component';
import { AuthGuard } from '@auth0/auth0-angular';

/**
 * Route definitions for the app.
 *
 * @description
 * The default loading route is Charge Cat and defaults to the Category Definition tab.
 * Rule Editor and Manual Targeting are then lazy loaded.
 */
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'charge-cat',
    component: ChargeCatComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: CategoryDefinitionComponent,
        canDeactivate: [CanDeactivateGuard]
      }
    ]
  },
  {
    path: 'rule-editor',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rule-editor/rule-editor.module').then(m => m.RuleEditorModule)
  },
  {
    path: 'manual-targeting',
    canActivate: [AuthGuard],
    loadChildren: () => import('./manual-targeting/manual-targeting.module').then(m => m.ManualTargetingModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
