import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cm-charge-cat',
  templateUrl: './charge-cat.component.html',
  styleUrls: ['./charge-cat.component.scss']
})
export class ChargeCatComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
