import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimeNgModule } from 'src/app/primeng.module';
import { UserAvatarComponent } from './components/user-avatar/user-avatar.component';
import { TopSliderComponent } from './components/top-slider/top-slider.component';

const components = [UserAvatarComponent, TopSliderComponent];
@NgModule({
  declarations: components,
  imports: [CommonModule, PrimeNgModule],
  exports: [components, PrimeNgModule]
})
export class SharedModule {}
