import { Injectable } from '@angular/core';
import { Utils } from 'src/app/_shared/utils';

@Injectable({
  providedIn: 'root'
})
export class N1qlFormatterService {
  formatParensbalanced = '';
  tokens = [
    ['(', ')'],
    ['{', '}'],
    ['[', ']']
  ];

  constructor() {}

  formatN1QL(n1ql: string): string {
    if (!Utils.hasValue(n1ql)) return;
    let balanced = this.isBalanced(n1ql);
    this.formatParensbalanced = balanced ? 'Yes' : 'No';
    setTimeout((): void => {
      this.formatParensbalanced = '';
    }, 5000);
    return this.formatN1QLWithSpaces(n1ql);
  }

  private formatN1QLWithSpaces(n1ql: string): string {
    //RegEx Do not add new lines for '(' when 'TOKENS' precedes the '(' otherwise put '(' on its own line
    n1ql = n1ql.replace(/\((?<!\bTOKENS\b\s*\()/gi, '\n(\n');
    //RegEx Do not add new lines for ')' when 'SATISFIES' precedes the '(' otherwise put '(' on its own line
    n1ql = n1ql.replace(/[\)](?!\s*SATISFIES)/gi, '\n)\n');

    let lines = n1ql.split('\n');
    const indent: string = '    ';
    let indentLevel = 0;
    let formattedString = '';

    for (let i = 0; i < lines.length; i++) {
      // Skip empty lines
      while (lines[i] && lines[i].trim() === '') {
        lines.splice(i, 1);
      }
      if (lines[i]) {
        if (lines[i].trim().substr(0, 1) == this.tokens[0][1]) {
          indentLevel--;
        }
        for (let level = 0; level < indentLevel; level++) {
          formattedString += indent;
        }
        if (lines[i].trim().substr(0, 1) == "'" || lines[i].trim().substr(0, 1) == '"') {
          formattedString += indent;
          formattedString += indent;
        }
        if (lines[i].trim().substr(0, 1) == this.tokens[0][0]) {
          indentLevel++;
        }
        formattedString += lines[i].trim() + '\r';
      }
    }
    if (formattedString) {
      return formattedString.trim();
    }
  }

  private isBalanced(inputStr: string): boolean {
    if (inputStr === null) {
      return true;
    }
    let expression = inputStr.split('');
    let stack: string[] = [];
    for (let cIndex = 0; cIndex < expression.length; cIndex++) {
      if (this.isParanthesis(expression[cIndex])) {
        if (this.isOpenParenthesis(expression[cIndex])) {
          stack.push(expression[cIndex]);
        } else {
          if (stack.length === 0) {
            return false;
          }
          let top = stack.pop(); // pop off the top element from stack
          if (!this.matches(top, expression[cIndex])) {
            return false;
          }
        }
      }
    }
    return stack.length === 0 ? true : false;
  }

  private isParanthesis(char: string): boolean {
    for (let tokItem = 0; tokItem < this.tokens.length; tokItem++) {
      return this.tokens[tokItem][0] === char || this.tokens[tokItem][1] === char;
    }
    return false;
  }

  private isOpenParenthesis(parenthesisChar: string): boolean {
    for (let tokIndex = 0; tokIndex < this.tokens.length; tokIndex++) {
      if (this.tokens[tokIndex][0] === parenthesisChar) {
        return true;
      }
    }
    return false;
  }

  private matches(topOfStack: string, closedParenthesis: string): boolean {
    for (let tokIdx = 0; tokIdx < this.tokens.length; tokIdx++) {
      if (this.tokens[tokIdx][0] === topOfStack && this.tokens[tokIdx][1] === closedParenthesis) {
        return true;
      }
    }
    return false;
  }
}
