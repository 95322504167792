import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ChargeDescription } from 'src/app/_shared/models';
import { SubSink } from 'subsink';
import { EventBusService } from '../../services/event-bus.service';
import { ChargeCatSelectors } from '../../store/app/app.selectors';

@Component({
  selector: 'cm-manual-exceptions',
  templateUrl: './manual-exceptions.component.html',
  styleUrls: ['./manual-exceptions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManualExceptionsComponent implements OnInit, OnDestroy {
  // These two inputs are booleans sent by the container component
  // to quickly show the tables if subscribed values are on the way
  // (see fields below).
  @Input() hasIncludes: boolean;
  @Input() hasExcludes: boolean;
  @Output() removeRow = new EventEmitter<{ docId: string; gridId: string }>();
  @Select(ChargeCatSelectors.manualIncludes) includes$: Observable<ChargeDescription[]>;
  @Select(ChargeCatSelectors.manualExcludes) excludes$: Observable<ChargeDescription[]>;
  subs = new SubSink();

  deletingInclude = false;
  deletingExclude = false;
  loadingIncludes: boolean;
  loadingExcludes: boolean;
  constructor(private eventBus: EventBusService) {}
  ngOnInit() {
    this.subs.sink = this.eventBus.getRemovingManualInclude$().subscribe(loading => {
      this.deletingInclude = loading;
    });
    this.subs.sink = this.eventBus.getRemovingManualExclude$().subscribe(loading => {
      this.deletingExclude = loading;
    });
  }
  removeSelectedRow(event) {
    this.removeRow.emit(event);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
