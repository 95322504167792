import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SubSink } from 'subsink';

import { IdleTimeoutModalComponent } from './idle-timeout-modal/idle-timeout-modal.component';
import { IdleEventBusService } from './idle-event-bus.service';
import { AuthService } from '@auth0/auth0-angular';

@Injectable({
  providedIn: 'root'
})
export class SessionIdleService implements OnDestroy {
  timedOut = false;
  lastPing?: Date = null;
  subs = new SubSink();

  constructor(
    private readonly authService: AuthService,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog,
    private eventBusService: IdleEventBusService
  ) {}

  startSessionIdle() {
    // sets an idle timeout of 30 minutes (1800 seconds)
    this.idle.setIdle(1800);

    // 10 seconds before the setTimeout happens, a countdown will display letting
    // the user know they will be timed out.
    this.idle.setTimeout(10);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // if no longer idle, reset
    this.subs.sink = this.idle.onIdleEnd.subscribe(() => {
      this.reset();
    });

    // once timed out, close the modal and logout
    this.subs.sink = this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;
      this.dialog.closeAll();
      this.authService.logout({
        returnTo: document.location.origin,
      });
    });

    // once idle for the given time, open the modal
    this.subs.sink = this.idle.onIdleStart.subscribe(() => {
      this.idle.clearInterrupts();
      this.dialog.closeAll();
      this.openIdleTimeoutModal();
    });

    // Send the countdown value to the modal
    this.subs.sink = this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      this.eventBusService.idleCountdown(countdown);
    });

    // sets the ping interval to 30 seconds
    this.keepalive.interval(30);
    this.subs.sink = this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  openIdleTimeoutModal() {
    const dialogRef = this.dialog.open(IdleTimeoutModalComponent, {
      disableClose: true,
      width: '30%',
      data: {
        logout: false
      }
    });
    this.subs.sink = dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.authService.logout({
          returnTo: document.location.origin,
        });
      } else {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.reset();
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
