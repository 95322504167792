import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import clonedeep from 'lodash.clonedeep';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import * as Actions from '../../store/app/app.actions';
import { Category } from 'src/app/_shared/models';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { Utils } from 'src/app/_shared/utils';

@Component({
  selector: 'cm-cpt-mapping-modal',
  templateUrl: './cpt-mapping-modal.component.html',
  styleUrls: ['./cpt-mapping-modal.component.scss']
})
export class CptMappingModalComponent implements OnInit {
  cptTextArea = '';
  cptList = null;
  cptListInCategory = [];
  filteredCptList = null;
  cptDictionary = {};
  tempCategory = null;
  filterText = '';
  invalidCptsViewValue = null;
  constructor(public dialogRef: MatDialogRef<PreviewModalComponent>, @Inject(MAT_DIALOG_DATA) public category) {}

  ngOnInit(): void {
    this.tempCategory = clonedeep(this.category);
    this.setCPTdisplayValue();
  }

  /**
   * Dispatches an action to set the boolean flag for the N1QL editor having pending changes
   */
  @Dispatch()
  n1qlEditorPendingChange = (pending: boolean) => new Actions.N1QLEditorPendingChanges(pending);

  @Dispatch()
  setTempCategoryForN1QLStatement = (tempCategory: Category) =>
    new Actions.SetTempCategoryForN1QLStatement(clonedeep(tempCategory));

  add() {
    let extractedCpts = this.extractCptsFromTextArea();
    const validCpts = this.validateCpts(extractedCpts);
    this.setValidCptsToTempCategory(validCpts);
  }

  addToCategory() {
    this.setTempCategoryForN1QLStatement(this.tempCategory);
    this.n1qlEditorPendingChange(true);
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  searchCptList() {
    if (!Utils.hasValue(this.filterText)) {
      this.filteredCptList = null;
    }
    this.filteredCptList = this.tempCategory.cptList.filter(cpt => cpt.includes(this.filterText));
  }

  removeCpt(cpt: string) {
    const index = this.tempCategory.cptList.indexOf(cpt);
    if (index > -1) {
      this.tempCategory.cptList.splice(index, 1);
    }
  }

  private extractCptsFromTextArea() {
    return this.cptTextArea
      .split(/[\n,]/)
      .filter(cpt => Utils.hasValue(cpt))
      .map(cpt => cpt.replace(/['"]+/g, '').trim())
      .sort((a: any, b: any) => a - b);
  }

  private setValidCptsToTempCategory(validCpts: string[]) {
    if (!this.tempCategory.cptList || (this.tempCategory.cptList && this.tempCategory.cptList.length == 0)) {
      this.tempCategory.cptList = validCpts;
    } else {
      const newToList = [];
      validCpts.forEach(cpt => {
        if (!this.tempCategory.cptList.includes(cpt)) {
          newToList.push(cpt);
        }
      });
      this.tempCategory.cptList = this.tempCategory.cptList.concat(newToList).sort((a, b) => a - b);
    }
  }

  private validateCpts(extractedCptsFromTextArea: string[]) {
    this.invalidCptsViewValue = null;
    const invalidCpts = [];
    const validCpts = [];
    extractedCptsFromTextArea.forEach(cpt => {
      if (!cpt.match(/^([0-9]|[a-z])+([0-9a-z]+)$/i) || cpt.length !== 5) {
        invalidCpts.push(cpt);
      } else {
        validCpts.push(cpt);
      }
    });
    invalidCpts && invalidCpts.length > 0
      ? (this.invalidCptsViewValue = invalidCpts.join(', '))
      : (this.invalidCptsViewValue = null);

    return validCpts;
  }

  private setCPTdisplayValue() {
    if (this.tempCategory.chargeDescriptionSelectorN1QL) {
      const cptListStringToMatch = JSON.stringify(this.tempCategory.cptList).replace(/"/g, "'");
      while (this.tempCategory.chargeDescriptionSelectorN1QL.includes(cptListStringToMatch)) {
        this.tempCategory.chargeDescriptionSelectorN1QL = this.tempCategory.chargeDescriptionSelectorN1QL.replace(
          cptListStringToMatch,
          Utils.cptListKeyword
        );
      }
    }
  }
}
